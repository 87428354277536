@charset "UTF-8";
:root { color-scheme: only light; }

*, *::before, *::after { box-sizing: border-box; }

html { font-family: sans-serif; line-height: 1.15; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport { width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

body { margin: 0; font-family: 'MontRat', sans-serif; font-size: 1rem; font-weight: 400; line-height: 1.5618; color: #404455; text-align: left; background-color: white; }

[tabindex="-1"]:focus { outline: 0 !important; }

hr { box-sizing: content-box; height: 0; overflow: visible; }

h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.39045rem; }

p { margin-top: 0; margin-bottom: 0.7809rem; }

abbr[title], abbr[data-original-title] { text-decoration: underline; text-decoration: underline dotted; cursor: help; border-bottom: 0; }

address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dt { font-weight: 700; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0 0 1rem; }

dfn { font-style: italic; }

b, strong { font-weight: bolder; }

small, .text-small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { color: #d5a574; text-decoration: none; background-color: transparent; -webkit-text-decoration-skip: objects; }

a:hover { color: #c27f3b; text-decoration: none; }

a:not([href]):not([tabindex]) { color: inherit; text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus { color: inherit; text-decoration: none; }

a:not([href]):not([tabindex]):focus { outline: 0; }

pre, code, kbd, samp { font-family: monospace, monospace; font-size: 1em; }

pre { margin-top: 0; margin-bottom: 1rem; overflow: auto; -ms-overflow-style: scrollbar; }

figure { margin: 0 0 1rem; }

img { vertical-align: middle; border-style: none; }

svg:not(:root) { overflow: hidden; }

table { border-collapse: collapse; }

caption { padding-top: 0.75rem; padding-bottom: 0.75rem; color: #6c757d; text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; margin-bottom: 0.5rem; }

button { border-radius: 0; }

button:focus { outline: 1px dotted; outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] { -webkit-appearance: listbox; }

textarea { overflow: auto; resize: vertical; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: .5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }

progress { vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { outline-offset: -2px; -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

[hidden] { display: none !important; }

h1, h2, h3, h4, h5, h6, .h1, #SliderWrapper .slider--header--title, .h2, .h3, .h4, .h5, #MyCart .jblcart--promo-title, .h6 { margin-bottom: 0.39045rem; font-family: 'TraPro-Bold', serif; font-weight: bold; line-height: 1.5618; color: #d5a574; }

h1, .h1, #SliderWrapper .slider--header--title { font-size: 2.125rem; }

h2, .h2 { font-size: 1.66558526rem; }

h3, .h3 { font-size: 1.4592295825rem; }

h4, .h4 { font-size: 1.2079857543rem; }

h5, .h5, #MyCart .jblcart--promo-title { font-size: 1rem; }

h6, .h6 { font-size: 0.8278243319rem; }

.lead { font-size: 1.25rem; font-weight: 300; }

.display-1 { font-size: 6rem; font-weight: 300; line-height: 1.5618; }

.display-2 { font-size: 5.5rem; font-weight: 300; line-height: 1.5618; }

.display-3 { font-size: 4.5rem; font-weight: 300; line-height: 1.5618; }

.display-4 { font-size: 3.5rem; font-weight: 300; line-height: 1.5618; }

hr { margin-top: 1.5618rem; margin-bottom: 1.5618rem; border: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); }

small, .text-small, .small { font-size: 80%; font-weight: 400; }

mark, .mark { padding: 0.2em; background-color: #fcf8e3; }

.list-unstyled { padding-left: 0; list-style: none; }

.list-inline { padding-left: 0; list-style: none; }

.list-inline-item { display: inline-block; }

.list-inline-item:not(:last-child) { margin-right: 0.5rem; }

.initialism { font-size: 90%; text-transform: uppercase; }

.blockquote { margin-bottom: 1.5618rem; font-size: 1.25rem; }

.blockquote-footer { display: block; font-size: 80%; color: #6c757d; }

.blockquote-footer::before { content: "\2014 \00A0"; }

.img-fluid { max-width: 100%; height: auto; }

.img-thumbnail { padding: 0.25rem; background-color: white; border: 1px solid #dee2e6; max-width: 100%; height: auto; }

.figure { display: inline-block; }

.figure-img { margin-bottom: 0.7809rem; line-height: 1; }

.figure-caption { font-size: 90%; color: #6c757d; }

.container { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container { max-width: 540px; } }

@media (min-width: 768px) { .container { max-width: 720px; } }

@media (min-width: 992px) { .container { max-width: 960px; } }

@media (min-width: 1200px) { .container { max-width: 1140px; } }

.container-fluid { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: none; }

.col-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }

.col-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }

.col-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }

.col-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }

.col-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.3333333333%; }

.offset-2 { margin-left: 16.6666666667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.3333333333%; }

.offset-5 { margin-left: 41.6666666667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.3333333333%; }

.offset-8 { margin-left: 66.6666666667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.3333333333%; }

.offset-11 { margin-left: 91.6666666667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-sm-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-sm-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-sm-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-sm-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-sm-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.3333333333%; }
  .offset-sm-2 { margin-left: 16.6666666667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.3333333333%; }
  .offset-sm-5 { margin-left: 41.6666666667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.3333333333%; }
  .offset-sm-8 { margin-left: 66.6666666667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.3333333333%; }
  .offset-sm-11 { margin-left: 91.6666666667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-md-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-md-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-md-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-md-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-md-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.3333333333%; }
  .offset-md-2 { margin-left: 16.6666666667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.3333333333%; }
  .offset-md-5 { margin-left: 41.6666666667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.3333333333%; }
  .offset-md-8 { margin-left: 66.6666666667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.3333333333%; }
  .offset-md-11 { margin-left: 91.6666666667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-lg-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-lg-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-lg-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-lg-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-lg-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.3333333333%; }
  .offset-lg-2 { margin-left: 16.6666666667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.3333333333%; }
  .offset-lg-5 { margin-left: 41.6666666667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.3333333333%; }
  .offset-lg-8 { margin-left: 66.6666666667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.3333333333%; }
  .offset-lg-11 { margin-left: 91.6666666667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-xl-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-xl-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-xl-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-xl-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-xl-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.3333333333%; }
  .offset-xl-2 { margin-left: 16.6666666667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.3333333333%; }
  .offset-xl-5 { margin-left: 41.6666666667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.3333333333%; }
  .offset-xl-8 { margin-left: 66.6666666667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.3333333333%; }
  .offset-xl-11 { margin-left: 91.6666666667%; } }

.table { width: 100%; max-width: 100%; margin-bottom: 1.5618rem; background-color: transparent; }

.table th, .table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; }

.table thead th { vertical-align: bottom; border-bottom: 2px solid #dee2e6; }

.table tbody + tbody { border-top: 2px solid #dee2e6; }

.table .table { background-color: white; }

.table-sm th, .table-sm td { padding: 0.3rem; }

.table-bordered { border: 1px solid #dee2e6; }

.table-bordered th, .table-bordered td { border: 1px solid #dee2e6; }

.table-bordered thead th, .table-bordered thead td { border-bottom-width: 2px; }

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody { border: 0; }

.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > th, .table-primary > td { background-color: #f3e6d8; }

.table-hover .table-primary:hover { background-color: #eddac5; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th { background-color: #eddac5; }

.table-secondary, .table-secondary > th, .table-secondary > td { background-color: #d6d8db; }

.table-hover .table-secondary:hover { background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th { background-color: #c8cbcf; }

.table-success, .table-success > th, .table-success > td { background-color: #c3e6cb; }

.table-hover .table-success:hover { background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th { background-color: #b1dfbb; }

.table-info, .table-info > th, .table-info > td { background-color: #bee5eb; }

.table-hover .table-info:hover { background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th { background-color: #abdde5; }

.table-warning, .table-warning > th, .table-warning > td { background-color: #ffeeba; }

.table-hover .table-warning:hover { background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th { background-color: #ffe8a1; }

.table-danger, .table-danger > th, .table-danger > td { background-color: #f5c6cb; }

.table-hover .table-danger:hover { background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th { background-color: #f1b0b7; }

.table-light, .table-light > th, .table-light > td { background-color: #fdfdfe; }

.table-hover .table-light:hover { background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th { background-color: #ececf6; }

.table-dark, .table-dark > th, .table-dark > td { background-color: #c6c8ca; }

.table-hover .table-dark:hover { background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th { background-color: #b9bbbe; }

.table-active, .table-active > th, .table-active > td { background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover { background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th { background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th { color: white; background-color: #212529; border-color: #32383e; }

.table .thead-light th { color: #495057; background-color: #e9ecef; border-color: #dee2e6; }

.table-dark { color: white; background-color: #212529; }

.table-dark th, .table-dark td, .table-dark thead th { border-color: #32383e; }

.table-dark.table-bordered { border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover { background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) { .table-responsive-sm { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered { border: 0; } }

@media (max-width: 767.98px) { .table-responsive-md { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered { border: 0; } }

@media (max-width: 991.98px) { .table-responsive-lg { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered { border: 0; } }

@media (max-width: 1199.98px) { .table-responsive-xl { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered { border: 0; } }

.table-responsive { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered { border: 0; }

.form-control { display: block; width: 100%; padding: 0.6rem 0.75rem; font-size: 1rem; line-height: 1.5618; color: #495057; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; border-radius: 0; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.1s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) { .form-control { transition: none; } }

.form-control::-ms-expand { background-color: transparent; border: 0; }

.form-control:focus { color: #495057; background-color: #fff; border-color: #d5a574; outline: 0; box-shadow: 0 0 0 0.2rem rgba(213, 165, 116, 0.25); }

.form-control::placeholder { color: #6c757d; opacity: 1; }

.form-control:disabled, .form-control[readonly] { background-color: #e9ecef; opacity: 1; }

select.form-control:not([size]):not([multiple]) { height: calc(3.1243rem + 2px); }

select.form-control:focus::-ms-value { color: #495057; background-color: #fff; }

.form-control-file, .form-control-range { display: block; width: 100%; }

.col-form-label { padding-top: calc(0.6rem + 1px); padding-bottom: calc(0.6rem + 1px); margin-bottom: 0; font-size: inherit; line-height: 1.5618; }

.col-form-label-lg { padding-top: calc(0.5rem + 1px); padding-bottom: calc(0.5rem + 1px); font-size: 1.25rem; line-height: 1.5; }

.col-form-label-sm { padding-top: calc(0.25rem + 1px); padding-bottom: calc(0.25rem + 1px); font-size: 0.875rem; line-height: 1.5; }

.form-control-plaintext { display: block; width: 100%; padding-top: 0.6rem; padding-bottom: 0.6rem; margin-bottom: 0; line-height: 1.5618; color: #404455; background-color: transparent; border: solid transparent; border-width: 1px 0; }

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.btn { padding-right: 0; padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) { height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) { height: calc(2.875rem + 2px); }

.form-group { margin-bottom: 1rem; }

.form-text { display: block; margin-top: 0.25rem; }

.form-row { display: flex; flex-wrap: wrap; margin-right: -5px; margin-left: -5px; }

.form-row > .col, .form-row > [class*="col-"] { padding-right: 5px; padding-left: 5px; }

.form-check { position: relative; display: block; padding-left: 1.25rem; }

.form-check-input { position: absolute; margin-top: 0.3rem; margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label { color: #6c757d; }

.form-check-label { margin-bottom: 0; }

.form-check-inline { display: inline-flex; align-items: center; padding-left: 0; margin-right: 0.75rem; }

.form-check-inline .form-check-input { position: static; margin-top: 0; margin-right: 0.3125rem; margin-left: 0; }

.valid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #28a745; }

.valid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: .5rem; margin-top: .1rem; font-size: .875rem; line-height: 1; color: #fff; background-color: rgba(40, 167, 69, 0.8); border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid { border-color: #28a745; }

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus { border-color: #28a745; box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip { display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label { color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label { color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before { background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before { background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label { border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before { border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label { box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545; }

.invalid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: .5rem; margin-top: .1rem; font-size: .875rem; line-height: 1; color: #fff; background-color: rgba(220, 53, 69, 0.8); border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid { border-color: #dc3545; }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus { border-color: #dc3545; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label { color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label { color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before { background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before { background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label { border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before { border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label { box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline { display: flex; flex-flow: row wrap; align-items: center; }

.form-inline .form-check { width: 100%; }

@media (min-width: 576px) { .form-inline label { display: flex; align-items: center; justify-content: center; margin-bottom: 0; }
  .form-inline .form-group { display: flex; flex: 0 0 auto; flex-flow: row wrap; align-items: center; margin-bottom: 0; }
  .form-inline .form-control { display: inline-block; width: auto; vertical-align: middle; }
  .form-inline .form-control-plaintext { display: inline-block; }
  .form-inline .input-group, .form-inline .custom-select { width: auto; }
  .form-inline .form-check { display: flex; align-items: center; justify-content: center; width: auto; padding-left: 0; }
  .form-inline .form-check-input { position: relative; margin-top: 0; margin-right: 0.25rem; margin-left: 0; }
  .form-inline .custom-control { align-items: center; justify-content: center; }
  .form-inline .custom-control-label { margin-bottom: 0; } }

.btn { display: inline-block; font-weight: 400; text-align: center; white-space: nowrap; vertical-align: middle; user-select: none; border: 1px solid transparent; padding: 10px 1.5618rem; font-size: 1rem; line-height: 1.5618; border-radius: 0; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) { .btn { transition: none; } }

.btn:hover, .btn:focus { text-decoration: none; }

.btn:focus, .btn.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(213, 165, 116, 0.25); }

.btn.disabled, .btn:disabled { opacity: 0.65; }

.btn:not(:disabled):not(.disabled) { cursor: pointer; }

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active { background-image: none; }

a.btn.disabled, fieldset:disabled a.btn { pointer-events: none; }

.btn-primary { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-primary:hover { color: white; background-color: #cc9257; border-color: #c98c4d; }

.btn-primary.disabled, .btn-primary:disabled { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle { color: white; background-color: #c98c4d; border-color: #c68543; }

.btn-secondary { color: white; background-color: #6c757d; border-color: #6c757d; }

.btn-secondary:hover { color: white; background-color: #5a6268; border-color: #545b62; }

.btn-secondary.disabled, .btn-secondary:disabled { color: white; background-color: #6c757d; border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle { color: white; background-color: #545b62; border-color: #4e555b; }

.btn-success { color: white; background-color: #28a745; border-color: #28a745; }

.btn-success:hover { color: white; background-color: #218838; border-color: #1e7e34; }

.btn-success.disabled, .btn-success:disabled { color: white; background-color: #28a745; border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle { color: white; background-color: #1e7e34; border-color: #1c7430; }

.btn-info { color: white; background-color: #17a2b8; border-color: #17a2b8; }

.btn-info:hover { color: white; background-color: #138496; border-color: #117a8b; }

.btn-info.disabled, .btn-info:disabled { color: white; background-color: #17a2b8; border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle { color: white; background-color: #117a8b; border-color: #10707f; }

.btn-warning { color: rgba(0, 0, 0, 0.7); background-color: #ffc107; border-color: #ffc107; }

.btn-warning:hover { color: white; background-color: #e0a800; border-color: #d39e00; }

.btn-warning.disabled, .btn-warning:disabled { color: rgba(0, 0, 0, 0.7); background-color: #ffc107; border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle { color: white; background-color: #d39e00; border-color: #c69500; }

.btn-danger { color: white; background-color: #dc3545; border-color: #dc3545; }

.btn-danger:hover { color: white; background-color: #c82333; border-color: #bd2130; }

.btn-danger.disabled, .btn-danger:disabled { color: white; background-color: #dc3545; border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle { color: white; background-color: #bd2130; border-color: #b21f2d; }

.btn-light { color: rgba(0, 0, 0, 0.7); background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-light:hover { color: rgba(0, 0, 0, 0.7); background-color: #e2e6ea; border-color: #dae0e5; }

.btn-light.disabled, .btn-light:disabled { color: rgba(0, 0, 0, 0.7); background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: #dae0e5; border-color: #d3d9df; }

.btn-dark { color: white; background-color: #343a40; border-color: #343a40; }

.btn-dark:hover { color: white; background-color: #23272b; border-color: #1d2124; }

.btn-dark.disabled, .btn-dark:disabled { color: white; background-color: #343a40; border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle { color: white; background-color: #1d2124; border-color: #171a1d; }

.btn-outline-primary { color: #d5a574; background-color: transparent; background-image: none; border-color: #d5a574; }

.btn-outline-primary:hover { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: #d5a574; background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-secondary { color: #6c757d; background-color: transparent; background-image: none; border-color: #6c757d; }

.btn-outline-secondary:hover { color: white; background-color: #6c757d; border-color: #6c757d; }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled { color: #6c757d; background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle { color: white; background-color: #6c757d; border-color: #6c757d; }

.btn-outline-success { color: #28a745; background-color: transparent; background-image: none; border-color: #28a745; }

.btn-outline-success:hover { color: white; background-color: #28a745; border-color: #28a745; }

.btn-outline-success.disabled, .btn-outline-success:disabled { color: #28a745; background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle { color: white; background-color: #28a745; border-color: #28a745; }

.btn-outline-info { color: #17a2b8; background-color: transparent; background-image: none; border-color: #17a2b8; }

.btn-outline-info:hover { color: white; background-color: #17a2b8; border-color: #17a2b8; }

.btn-outline-info.disabled, .btn-outline-info:disabled { color: #17a2b8; background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle { color: white; background-color: #17a2b8; border-color: #17a2b8; }

.btn-outline-warning { color: #ffc107; background-color: transparent; background-image: none; border-color: #ffc107; }

.btn-outline-warning:hover { color: rgba(0, 0, 0, 0.7); background-color: #ffc107; border-color: #ffc107; }

.btn-outline-warning.disabled, .btn-outline-warning:disabled { color: #ffc107; background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: #ffc107; border-color: #ffc107; }

.btn-outline-danger { color: #dc3545; background-color: transparent; background-image: none; border-color: #dc3545; }

.btn-outline-danger:hover { color: white; background-color: #dc3545; border-color: #dc3545; }

.btn-outline-danger.disabled, .btn-outline-danger:disabled { color: #dc3545; background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle { color: white; background-color: #dc3545; border-color: #dc3545; }

.btn-outline-light { color: #f8f9fa; background-color: transparent; background-image: none; border-color: #f8f9fa; }

.btn-outline-light:hover { color: rgba(0, 0, 0, 0.7); background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-outline-light.disabled, .btn-outline-light:disabled { color: #f8f9fa; background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-outline-dark { color: #343a40; background-color: transparent; background-image: none; border-color: #343a40; }

.btn-outline-dark:hover { color: white; background-color: #343a40; border-color: #343a40; }

.btn-outline-dark.disabled, .btn-outline-dark:disabled { color: #343a40; background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle { color: white; background-color: #343a40; border-color: #343a40; }

.btn-link { font-weight: 400; color: #d5a574; background-color: transparent; }

.btn-link:hover { color: #c27f3b; text-decoration: none; background-color: transparent; border-color: transparent; }

.btn-link:focus, .btn-link.focus { text-decoration: none; border-color: transparent; box-shadow: none; }

.btn-link:disabled, .btn-link.disabled { color: #c7c7c7; pointer-events: none; }

.btn-lg { padding: 0.5rem 1.5rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0; }

.btn-sm { padding: 0.25rem 1rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0; }

.btn-block { display: block; width: 100%; }

.btn-block + .btn-block { margin-top: 0.5rem; }

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block { width: 100%; }

.fade { transition: opacity 0.15s linear; }

@media screen and (prefers-reduced-motion: reduce) { .fade { transition: none; } }

.fade:not(.show) { opacity: 0; }

.collapse:not(.show) { display: none; }

.collapsing { position: relative; height: 0; overflow: hidden; transition: height 0.35s ease; }

@media screen and (prefers-reduced-motion: reduce) { .collapsing { transition: none; } }

.dropup, .dropright, .dropdown, .dropleft { position: relative; }

.dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after { margin-left: 0; }

.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 10rem; padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: #404455; text-align: left; list-style: none; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-menu-right { right: 0; left: auto; }

.dropup .dropdown-menu { top: auto; bottom: 100%; margin-top: 0; margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0; border-right: 0.3em solid transparent; border-bottom: 0.3em solid; border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after { margin-left: 0; }

.dropright .dropdown-menu { top: 0; right: auto; left: 100%; margin-top: 0; margin-left: 0.125rem; }

.dropright .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0; border-bottom: 0.3em solid transparent; border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after { margin-left: 0; }

.dropright .dropdown-toggle::after { vertical-align: 0; }

.dropleft .dropdown-menu { top: 0; right: 100%; left: auto; margin-top: 0; margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; }

.dropleft .dropdown-toggle::after { display: none; }

.dropleft .dropdown-toggle::before { display: inline-block; width: 0; height: 0; margin-right: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0.3em solid; border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after { margin-left: 0; }

.dropleft .dropdown-toggle::before { vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] { right: auto; bottom: auto; }

.dropdown-divider { height: 0; margin: 0.7809rem 0; overflow: hidden; border-top: 1px solid #e9ecef; }

.dropdown-item { display: block; width: 100%; padding: 0.25rem 1.5rem; clear: both; font-weight: 400; color: #212529; text-align: inherit; white-space: nowrap; background-color: transparent; border: 0; }

.dropdown-item:hover, .dropdown-item:focus { color: #16181b; text-decoration: none; background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active { color: #fff; text-decoration: none; background-color: #d5a574; }

.dropdown-item.disabled, .dropdown-item:disabled { color: #6c757d; background-color: transparent; }

.dropdown-menu.show { display: block; }

.dropdown-header { display: block; padding: 0.5rem 1.5rem; margin-bottom: 0; font-size: 0.875rem; color: #6c757d; white-space: nowrap; }

.dropdown-item-text { display: block; padding: 0.25rem 1.5rem; color: #212529; }

.input-group { position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%; }

.input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file { position: relative; flex: 1 1 auto; width: 1%; margin-bottom: 0; }

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file:focus { z-index: 3; }

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file { margin-left: -1px; }

.input-group > .custom-file { display: flex; align-items: center; }

.input-group-prepend, .input-group-append { display: flex; }

.input-group-prepend .btn, .input-group-append .btn { position: relative; z-index: 2; }

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn { margin-left: -1px; }

.input-group-prepend { margin-right: -1px; }

.input-group-append { margin-left: -1px; }

.input-group-text { display: flex; align-items: center; padding: 0.6rem 0.75rem; margin-bottom: 0; font-size: 1rem; font-weight: 400; line-height: 1.5618; color: #495057; text-align: center; white-space: nowrap; background-color: #e9ecef; border: 1px solid #ced4da; }

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] { margin-top: 0; }

.nav { display: flex; flex-wrap: wrap; padding-left: 0; margin-bottom: 0; list-style: none; }

.nav-link { display: block; padding: 0.5rem 1rem; }

.nav-link:hover, .nav-link:focus { text-decoration: none; }

.nav-link.disabled { color: #6c757d; }

.nav-tabs { border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item { margin-bottom: -1px; }

.nav-tabs .nav-link { border: 1px solid transparent; }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled { color: #6c757d; background-color: transparent; border-color: transparent; }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { color: #495057; background-color: white; border-color: #dee2e6 #dee2e6 white; }

.nav-tabs .dropdown-menu { margin-top: -1px; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link { color: #fff; background-color: #d5a574; }

.nav-fill .nav-item { flex: 1 1 auto; text-align: center; }

.nav-justified .nav-item { flex-basis: 0; flex-grow: 1; text-align: center; }

.tab-content > .tab-pane { display: none; }

.tab-content > .active { display: block; }

.alert { position: relative; padding: 0.75rem 1.25rem; margin-bottom: 1rem; border: 1px solid transparent; }

.alert-heading { color: inherit; }

.alert-link { font-weight: 700; }

.alert-dismissible { padding-right: 4rem; }

.alert-dismissible .close { position: absolute; top: 0; right: 0; padding: 0.75rem 1.25rem; color: inherit; }

.alert-primary { color: #6f563c; background-color: #f7ede3; border-color: #f3e6d8; }

.alert-primary hr { border-top-color: #eddac5; }

.alert-primary .alert-link { color: #4e3c2a; }

.alert-secondary { color: #383d41; background-color: #e2e3e5; border-color: #d6d8db; }

.alert-secondary hr { border-top-color: #c8cbcf; }

.alert-secondary .alert-link { color: #202326; }

.alert-success { color: #155724; background-color: #d4edda; border-color: #c3e6cb; }

.alert-success hr { border-top-color: #b1dfbb; }

.alert-success .alert-link { color: #0b2e13; }

.alert-info { color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb; }

.alert-info hr { border-top-color: #abdde5; }

.alert-info .alert-link { color: #062c33; }

.alert-warning { color: #856404; background-color: #fff3cd; border-color: #ffeeba; }

.alert-warning hr { border-top-color: #ffe8a1; }

.alert-warning .alert-link { color: #533f03; }

.alert-danger, .alert-error, #FormTourOfItaly #destinations .selection-required .error, #FormTourOfItaly #hotelcategory .selection-required .error, #FormTourOfItaly #rooms .selection-required .error, #FormBookNow #fieldset-payment_mode + .errors { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.alert-danger hr, .alert-error hr, #FormTourOfItaly #destinations .selection-required .error hr, #FormTourOfItaly #hotelcategory .selection-required .error hr, #FormTourOfItaly #rooms .selection-required .error hr, #FormBookNow #fieldset-payment_mode + .errors hr { border-top-color: #f1b0b7; }

.alert-danger .alert-link, .alert-error .alert-link, #FormTourOfItaly #destinations .selection-required .error .alert-link, #FormTourOfItaly #hotelcategory .selection-required .error .alert-link, #FormTourOfItaly #rooms .selection-required .error .alert-link, #FormBookNow #fieldset-payment_mode + .errors .alert-link { color: #491217; }

.alert-light { color: #818182; background-color: #fefefe; border-color: #fdfdfe; }

.alert-light hr { border-top-color: #ececf6; }

.alert-light .alert-link { color: #686868; }

.alert-dark { color: #1b1e21; background-color: #d6d8d9; border-color: #c6c8ca; }

.alert-dark hr { border-top-color: #b9bbbe; }

.alert-dark .alert-link { color: #040505; }

.close { float: right; font-size: 1.5rem; font-weight: 700; line-height: 1; color: #000; text-shadow: 0 1px 0 #fff; opacity: .5; }

.close:hover, .close:focus { color: #000; text-decoration: none; opacity: .75; }

.close:not(:disabled):not(.disabled) { cursor: pointer; }

button.close { padding: 0; background-color: transparent; border: 0; -webkit-appearance: none; }

.modal-open { overflow: hidden; }

.modal { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1050; display: none; overflow: hidden; outline: 0; }

.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal-dialog { position: relative; width: auto; margin: 0.5rem; }

.modal.fade .modal-dialog { transition: transform 0.3s ease-out; transform: translate(0, -25%); }

@media screen and (prefers-reduced-motion: reduce) { .modal.fade .modal-dialog { transition: none; } }

.modal.show .modal-dialog { transform: translate(0, 0); }

.modal-dialog-centered { display: flex; align-items: center; min-height: calc(100% - (0.5rem * 2)); }

.modal-content { position: relative; display: flex; flex-direction: column; width: 100%; pointer-events: auto; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); outline: 0; }

.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #000; }

.modal-backdrop.fade { opacity: 0; }

.modal-backdrop.show { opacity: 0.5; }

.modal-header { display: flex; align-items: flex-start; justify-content: space-between; padding: 1rem; border-bottom: 1px solid #e9ecef; }

.modal-header .close { padding: 1rem; margin: -1rem -1rem -1rem auto; }

.modal-title { margin-bottom: 0; line-height: 1.5618; }

.modal-body { position: relative; flex: 1 1 auto; padding: 1rem; }

.modal-footer { display: flex; align-items: center; justify-content: flex-end; padding: 1rem; border-top: 1px solid #e9ecef; }

.modal-footer > :not(:first-child) { margin-left: .25rem; }

.modal-footer > :not(:last-child) { margin-right: .25rem; }

.modal-scrollbar-measure { position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll; }

@media (min-width: 576px) { .modal-dialog { max-width: 500px; margin: 1.75rem auto; }
  .modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm { max-width: 300px; } }

@media (min-width: 992px) { .modal-lg { max-width: 800px; } }

.popover { position: absolute; top: 0; left: 0; z-index: 1060; display: block; max-width: 276px; font-family: 'MontRat', sans-serif; font-style: normal; font-weight: 400; line-height: 1.5618; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); }

.popover .arrow { position: absolute; display: block; width: 1rem; height: 0.5rem; margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after { position: absolute; display: block; content: ""; border-color: transparent; border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] { margin-bottom: 0.5rem; }

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow { bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after { border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before { bottom: 0; border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after { bottom: 1px; border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] { margin-left: 0.5rem; }

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow { left: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after { border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before { left: 0; border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after { left: 1px; border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] { margin-top: 0.5rem; }

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow { top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after { border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before { top: 0; border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after { top: 1px; border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before { position: absolute; top: 0; left: 50%; display: block; width: 1rem; margin-left: -0.5rem; content: ""; border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] { margin-right: 0.5rem; }

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow { right: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after { border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before { right: 0; border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after { right: 1px; border-left-color: #fff; }

.popover-header { padding: 0.5rem 0.75rem; margin-bottom: 0; font-size: 1rem; color: #d5a574; background-color: #f7f7f7; border-bottom: 1px solid #ebebeb; }

.popover-header:empty { display: none; }

.popover-body { padding: 0.5rem 0.75rem; color: #404455; }

.align-baseline { vertical-align: baseline !important; }

.align-top { vertical-align: top !important; }

.align-middle { vertical-align: middle !important; }

.align-bottom { vertical-align: bottom !important; }

.align-text-bottom { vertical-align: text-bottom !important; }

.align-text-top { vertical-align: text-top !important; }

.bg-primary { background-color: #d5a574 !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #c98c4d !important; }

.bg-secondary { background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: #545b62 !important; }

.bg-success { background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: #1e7e34 !important; }

.bg-info { background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: #117a8b !important; }

.bg-warning { background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: #d39e00 !important; }

.bg-danger { background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: #bd2130 !important; }

.bg-light { background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: #dae0e5 !important; }

.bg-dark { background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: #1d2124 !important; }

.bg-white { background-color: #fff !important; }

.bg-transparent { background-color: transparent !important; }

.border { border: 1px solid #dee2e6 !important; }

.border-top { border-top: 1px solid #dee2e6 !important; }

.border-right { border-right: 1px solid #dee2e6 !important; }

.border-bottom { border-bottom: 1px solid #dee2e6 !important; }

.border-left { border-left: 1px solid #dee2e6 !important; }

.border-0 { border: 0 !important; }

.border-top-0 { border-top: 0 !important; }

.border-right-0 { border-right: 0 !important; }

.border-bottom-0 { border-bottom: 0 !important; }

.border-left-0 { border-left: 0 !important; }

.border-primary { border-color: #d5a574 !important; }

.border-secondary { border-color: #6c757d !important; }

.border-success { border-color: #28a745 !important; }

.border-info { border-color: #17a2b8 !important; }

.border-warning { border-color: #ffc107 !important; }

.border-danger { border-color: #dc3545 !important; }

.border-light { border-color: #f8f9fa !important; }

.border-dark { border-color: #343a40 !important; }

.border-white { border-color: #fff !important; }

.rounded { border-radius: 0.25rem !important; }

.rounded-top { border-top-left-radius: 0.25rem !important; border-top-right-radius: 0.25rem !important; }

.rounded-right { border-top-right-radius: 0.25rem !important; border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom { border-bottom-right-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }

.rounded-left { border-top-left-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }

.rounded-circle { border-radius: 50% !important; }

.rounded-0 { border-radius: 0 !important; }

.clearfix::after { display: block; clear: both; content: ""; }

@supports (display: flow-root) { .clearfix { display: flow-root; }
  .clearfix::after { content: none; } }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex, .form__display-group .form-group.file { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }

.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }

.embed-responsive::before { display: block; content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive img, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }

.embed-responsive-21by9::before { padding-top: 42.8571428571%; }

.embed-responsive-16by9::before { padding-top: 56.25%; }

.embed-responsive-4by3::before { padding-top: 75%; }

.embed-responsive-1by1::before { padding-top: 100%; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.w-25 { width: 25% !important; }

.w-50 { width: 50% !important; }

.w-75 { width: 75% !important; }

.w-100 { width: 100% !important; }

.w-auto { width: auto !important; }

.h-25 { height: 25% !important; }

.h-50 { height: 50% !important; }

.h-75 { height: 75% !important; }

.h-100 { height: 100% !important; }

.h-auto { height: auto !important; }

.mw-100 { max-width: 100% !important; }

.mh-100 { max-height: 100% !important; }

.text-monospace { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify { text-align: justify !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-primary { color: #d5a574 !important; }

a.text-primary:hover, a.text-primary:focus { color: #c98c4d !important; }

.text-secondary { color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #545b62 !important; }

.text-success { color: #28a745 !important; }

a.text-success:hover, a.text-success:focus { color: #1e7e34 !important; }

.text-info { color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus { color: #117a8b !important; }

.text-warning { color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus { color: #d39e00 !important; }

.text-danger { color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus { color: #bd2130 !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #dae0e5 !important; }

.text-dark { color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus { color: #1d2124 !important; }

.text-body { color: #404455 !important; }

.text-muted { color: #6c757d !important; }

.text-black-50 { color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 { color: rgba(255, 255, 255, 0.5) !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.visible { visibility: visible !important; }

.invisible { visibility: hidden !important; }

.embed-responsive img { object-fit: cover; }

.embed-responsive-3by2:before { padding-top: 66.6666666667%; }

.embed-responsive-5by3:before { padding-top: 60%; }

.embed-responsive-7by10:before { padding-top: 70%; }

.embed-responsive-1by2:before { padding-top: 50%; }

.btn-primary { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-primary:hover { color: rgba(0, 0, 0, 0.7); background-color: #deba9c; border-color: #dbb595; }

.btn-primary.disabled, .btn-primary:disabled { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle { color: white; background-color: #c98c4d; border-color: #c68543; }

.btn-bananagold { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-bananagold:hover { color: rgba(0, 0, 0, 0.7); background-color: #deba9c; border-color: #dbb595; }

.btn-bananagold.disabled, .btn-bananagold:disabled { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-bananagold:not(:disabled):not(.disabled):active, .btn-bananagold:not(:disabled):not(.disabled).active, .show > .btn-bananagold.dropdown-toggle { color: white; background-color: #c98c4d; border-color: #c68543; }

.btn-lavagna { color: white; background-color: #404455; border-color: #404455; }

.btn-lavagna:hover { color: white; background-color: #595e6d; border-color: #545967; }

.btn-lavagna.disabled, .btn-lavagna:disabled { color: white; background-color: #404455; border-color: #404455; }

.btn-lavagna:not(:disabled):not(.disabled):active, .btn-lavagna:not(:disabled):not(.disabled).active, .show > .btn-lavagna.dropdown-toggle { color: white; background-color: #2a2d38; border-color: #252731; }

.btn-light_grey { color: rgba(0, 0, 0, 0.7); background-color: #D6D6D6; border-color: #D6D6D6; }

.btn-light_grey:hover { color: rgba(0, 0, 0, 0.7); background-color: #c7c7c7; border-color: #c2c2c2; }

.btn-light_grey.disabled, .btn-light_grey:disabled { color: rgba(0, 0, 0, 0.7); background-color: #D6D6D6; border-color: #D6D6D6; }

.btn-light_grey:not(:disabled):not(.disabled):active, .btn-light_grey:not(:disabled):not(.disabled).active, .show > .btn-light_grey.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: #bdbcbc; border-color: #b6b6b6; }

.btn-outline-primary { color: #d5a574; background-color: transparent; background-image: none; border-color: #d5a574; }

.btn-outline-primary:hover { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: #d5a574; background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-bananagold { color: #d5a574; background-color: transparent; background-image: none; border-color: #d5a574; }

.btn-outline-bananagold:hover { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-bananagold.disabled, .btn-outline-bananagold:disabled { color: #d5a574; background-color: transparent; }

.btn-outline-bananagold:not(:disabled):not(.disabled):active, .btn-outline-bananagold:not(:disabled):not(.disabled).active, .show > .btn-outline-bananagold.dropdown-toggle { color: white; background-color: #d5a574; border-color: #d5a574; }

.btn-outline-lavagna { color: #404455; background-color: transparent; background-image: none; border-color: #404455; }

.btn-outline-lavagna:hover { color: white; background-color: #404455; border-color: #404455; }

.btn-outline-lavagna.disabled, .btn-outline-lavagna:disabled { color: #404455; background-color: transparent; }

.btn-outline-lavagna:not(:disabled):not(.disabled):active, .btn-outline-lavagna:not(:disabled):not(.disabled).active, .show > .btn-outline-lavagna.dropdown-toggle { color: white; background-color: #404455; border-color: #404455; }

.btn-outline-light_grey { color: #D6D6D6; background-color: transparent; background-image: none; border-color: #D6D6D6; }

.btn-outline-light_grey:hover { color: rgba(0, 0, 0, 0.7); background-color: #D6D6D6; border-color: #D6D6D6; }

.btn-outline-light_grey.disabled, .btn-outline-light_grey:disabled { color: #D6D6D6; background-color: transparent; }

.btn-outline-light_grey:not(:disabled):not(.disabled):active, .btn-outline-light_grey:not(:disabled):not(.disabled).active, .show > .btn-outline-light_grey.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: #D6D6D6; border-color: #D6D6D6; }

.btn-outline-white { color: white; background-color: transparent; background-image: none; border-color: white; }

.btn-outline-white:hover { color: rgba(0, 0, 0, 0.7); background-color: white; border-color: white; }

.btn-outline-white.disabled, .btn-outline-white:disabled { color: white; background-color: transparent; }

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle { color: rgba(0, 0, 0, 0.7); background-color: white; border-color: white; }

.btn-white { color: #d5a574; background-color: #FFFFFF; border-color: #FFFFFF; }

.btn-white:hover { color: #FFFFFF; }

.bg-primary { background-color: #d5a574 !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #c98c4d !important; }

.bg-primary, .bg-primary a { color: white; }

.bg-bananagold { background-color: #d5a574 !important; }

a.bg-bananagold:hover, a.bg-bananagold:focus, button.bg-bananagold:hover, button.bg-bananagold:focus { background-color: #c98c4d !important; }

.bg-bananagold, .bg-bananagold a { color: white; }

.bg-lavagna { background-color: #404455 !important; }

a.bg-lavagna:hover, a.bg-lavagna:focus, button.bg-lavagna:hover, button.bg-lavagna:focus { background-color: #2a2d38 !important; }

.bg-lavagna, .bg-lavagna a { color: white; }

.bg-light_grey { background-color: #D6D6D6 !important; }

a.bg-light_grey:hover, a.bg-light_grey:focus, button.bg-light_grey:hover, button.bg-light_grey:focus { background-color: #bdbcbc !important; }

.bg-light_grey, .bg-light_grey a { color: rgba(0, 0, 0, 0.7); }

.bg-primary, .bg-primary a { color: white; }

.bg-secondary, .bg-secondary a { color: white; }

.bg-success, .bg-success a { color: white; }

.bg-info, .bg-info a { color: white; }

.bg-warning, .bg-warning a { color: rgba(0, 0, 0, 0.7); }

.bg-danger, .bg-danger a { color: white; }

.bg-light, .bg-light a { color: rgba(0, 0, 0, 0.7); }

.bg-dark, .bg-dark a { color: white; }

.text-primary { color: #d5a574 !important; }

a.text-primary:hover, a.text-primary:focus { color: #c98c4d !important; }

.text-bananagold { color: #d5a574 !important; }

a.text-bananagold:hover, a.text-bananagold:focus { color: #c98c4d !important; }

.text-lavagna { color: #404455 !important; }

a.text-lavagna:hover, a.text-lavagna:focus { color: #2a2d38 !important; }

.text-light_grey { color: #D6D6D6 !important; }

a.text-light_grey:hover, a.text-light_grey:focus { color: #bdbcbc !important; }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 1.5618rem !important; }

.mt-1, .my-1 { margin-top: 1.5618rem !important; }

.mr-1, .mx-1 { margin-right: 1.5618rem !important; }

.mb-1, .my-1 { margin-bottom: 1.5618rem !important; }

.ml-1, .mx-1 { margin-left: 1.5618rem !important; }

.m-2 { margin: 3.1236rem !important; }

.mt-2, .my-2 { margin-top: 3.1236rem !important; }

.mr-2, .mx-2 { margin-right: 3.1236rem !important; }

.mb-2, .my-2 { margin-bottom: 3.1236rem !important; }

.ml-2, .mx-2 { margin-left: 3.1236rem !important; }

.m-3 { margin: 4.6854rem !important; }

.mt-3, .my-3 { margin-top: 4.6854rem !important; }

.mr-3, .mx-3 { margin-right: 4.6854rem !important; }

.mb-3, .my-3 { margin-bottom: 4.6854rem !important; }

.ml-3, .mx-3 { margin-left: 4.6854rem !important; }

.m-4 { margin: 6.2472rem !important; }

.mt-4, .my-4 { margin-top: 6.2472rem !important; }

.mr-4, .mx-4 { margin-right: 6.2472rem !important; }

.mb-4, .my-4 { margin-bottom: 6.2472rem !important; }

.ml-4, .mx-4 { margin-left: 6.2472rem !important; }

.m-5 { margin: 7.809rem !important; }

.mt-5, .my-5 { margin-top: 7.809rem !important; }

.mr-5, .mx-5 { margin-right: 7.809rem !important; }

.mb-5, .my-5 { margin-bottom: 7.809rem !important; }

.ml-5, .mx-5 { margin-left: 7.809rem !important; }

.m-0\.125 { margin: 0.195225rem !important; }

.mt-0\.125, .my-0\.125 { margin-top: 0.195225rem !important; }

.mr-0\.125, .mx-0\.125 { margin-right: 0.195225rem !important; }

.mb-0\.125, .my-0\.125 { margin-bottom: 0.195225rem !important; }

.ml-0\.125, .mx-0\.125 { margin-left: 0.195225rem !important; }

.m-0\.25 { margin: 0.39045rem !important; }

.mt-0\.25, .my-0\.25 { margin-top: 0.39045rem !important; }

.mr-0\.25, .mx-0\.25 { margin-right: 0.39045rem !important; }

.mb-0\.25, .my-0\.25 { margin-bottom: 0.39045rem !important; }

.ml-0\.25, .mx-0\.25 { margin-left: 0.39045rem !important; }

.m-0\.5 { margin: 0.7809rem !important; }

.mt-0\.5, .my-0\.5 { margin-top: 0.7809rem !important; }

.mr-0\.5, .mx-0\.5 { margin-right: 0.7809rem !important; }

.mb-0\.5, .my-0\.5 { margin-bottom: 0.7809rem !important; }

.ml-0\.5, .mx-0\.5 { margin-left: 0.7809rem !important; }

.m-0\.75 { margin: 1.17135rem !important; }

.mt-0\.75, .my-0\.75 { margin-top: 1.17135rem !important; }

.mr-0\.75, .mx-0\.75 { margin-right: 1.17135rem !important; }

.mb-0\.75, .my-0\.75 { margin-bottom: 1.17135rem !important; }

.ml-0\.75, .mx-0\.75 { margin-left: 1.17135rem !important; }

.m-1\.25 { margin: 1.95225rem !important; }

.mt-1\.25, .my-1\.25 { margin-top: 1.95225rem !important; }

.mr-1\.25, .mx-1\.25 { margin-right: 1.95225rem !important; }

.mb-1\.25, .my-1\.25 { margin-bottom: 1.95225rem !important; }

.ml-1\.25, .mx-1\.25 { margin-left: 1.95225rem !important; }

.m-1\.5 { margin: 2.3427rem !important; }

.mt-1\.5, .my-1\.5 { margin-top: 2.3427rem !important; }

.mr-1\.5, .mx-1\.5 { margin-right: 2.3427rem !important; }

.mb-1\.5, .my-1\.5 { margin-bottom: 2.3427rem !important; }

.ml-1\.5, .mx-1\.5 { margin-left: 2.3427rem !important; }

.m-1\.75 { margin: 2.73315rem !important; }

.mt-1\.75, .my-1\.75 { margin-top: 2.73315rem !important; }

.mr-1\.75, .mx-1\.75 { margin-right: 2.73315rem !important; }

.mb-1\.75, .my-1\.75 { margin-bottom: 2.73315rem !important; }

.ml-1\.75, .mx-1\.75 { margin-left: 2.73315rem !important; }

.m-2\.5 { margin: 3.9045rem !important; }

.mt-2\.5, .my-2\.5 { margin-top: 3.9045rem !important; }

.mr-2\.5, .mx-2\.5 { margin-right: 3.9045rem !important; }

.mb-2\.5, .my-2\.5 { margin-bottom: 3.9045rem !important; }

.ml-2\.5, .mx-2\.5 { margin-left: 3.9045rem !important; }

.m-3\.5 { margin: 5.4663rem !important; }

.mt-3\.5, .my-3\.5 { margin-top: 5.4663rem !important; }

.mr-3\.5, .mx-3\.5 { margin-right: 5.4663rem !important; }

.mb-3\.5, .my-3\.5 { margin-bottom: 5.4663rem !important; }

.ml-3\.5, .mx-3\.5 { margin-left: 5.4663rem !important; }

.m-4\.5 { margin: 7.0281rem !important; }

.mt-4\.5, .my-4\.5 { margin-top: 7.0281rem !important; }

.mr-4\.5, .mx-4\.5 { margin-right: 7.0281rem !important; }

.mb-4\.5, .my-4\.5 { margin-bottom: 7.0281rem !important; }

.ml-4\.5, .mx-4\.5 { margin-left: 7.0281rem !important; }

.m-6 { margin: 9.3708rem !important; }

.mt-6, .my-6 { margin-top: 9.3708rem !important; }

.mr-6, .mx-6 { margin-right: 9.3708rem !important; }

.mb-6, .my-6 { margin-bottom: 9.3708rem !important; }

.ml-6, .mx-6 { margin-left: 9.3708rem !important; }

.m-7 { margin: 10.9326rem !important; }

.mt-7, .my-7 { margin-top: 10.9326rem !important; }

.mr-7, .mx-7 { margin-right: 10.9326rem !important; }

.mb-7, .my-7 { margin-bottom: 10.9326rem !important; }

.ml-7, .mx-7 { margin-left: 10.9326rem !important; }

.m-8 { margin: 12.4944rem !important; }

.mt-8, .my-8 { margin-top: 12.4944rem !important; }

.mr-8, .mx-8 { margin-right: 12.4944rem !important; }

.mb-8, .my-8 { margin-bottom: 12.4944rem !important; }

.ml-8, .mx-8 { margin-left: 12.4944rem !important; }

.m-9 { margin: 14.0562rem !important; }

.mt-9, .my-9 { margin-top: 14.0562rem !important; }

.mr-9, .mx-9 { margin-right: 14.0562rem !important; }

.mb-9, .my-9 { margin-bottom: 14.0562rem !important; }

.ml-9, .mx-9 { margin-left: 14.0562rem !important; }

.m-10 { margin: 15.618rem !important; }

.mt-10, .my-10 { margin-top: 15.618rem !important; }

.mr-10, .mx-10 { margin-right: 15.618rem !important; }

.mb-10, .my-10 { margin-bottom: 15.618rem !important; }

.ml-10, .mx-10 { margin-left: 15.618rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 1.5618rem !important; }

.pt-1, .py-1 { padding-top: 1.5618rem !important; }

.pr-1, .px-1 { padding-right: 1.5618rem !important; }

.pb-1, .py-1 { padding-bottom: 1.5618rem !important; }

.pl-1, .px-1 { padding-left: 1.5618rem !important; }

.p-2 { padding: 3.1236rem !important; }

.pt-2, .py-2 { padding-top: 3.1236rem !important; }

.pr-2, .px-2 { padding-right: 3.1236rem !important; }

.pb-2, .py-2 { padding-bottom: 3.1236rem !important; }

.pl-2, .px-2 { padding-left: 3.1236rem !important; }

.p-3 { padding: 4.6854rem !important; }

.pt-3, .py-3 { padding-top: 4.6854rem !important; }

.pr-3, .px-3 { padding-right: 4.6854rem !important; }

.pb-3, .py-3 { padding-bottom: 4.6854rem !important; }

.pl-3, .px-3 { padding-left: 4.6854rem !important; }

.p-4 { padding: 6.2472rem !important; }

.pt-4, .py-4 { padding-top: 6.2472rem !important; }

.pr-4, .px-4 { padding-right: 6.2472rem !important; }

.pb-4, .py-4 { padding-bottom: 6.2472rem !important; }

.pl-4, .px-4 { padding-left: 6.2472rem !important; }

.p-5 { padding: 7.809rem !important; }

.pt-5, .py-5 { padding-top: 7.809rem !important; }

.pr-5, .px-5 { padding-right: 7.809rem !important; }

.pb-5, .py-5 { padding-bottom: 7.809rem !important; }

.pl-5, .px-5 { padding-left: 7.809rem !important; }

.p-0\.125 { padding: 0.195225rem !important; }

.pt-0\.125, .py-0\.125 { padding-top: 0.195225rem !important; }

.pr-0\.125, .px-0\.125 { padding-right: 0.195225rem !important; }

.pb-0\.125, .py-0\.125 { padding-bottom: 0.195225rem !important; }

.pl-0\.125, .px-0\.125 { padding-left: 0.195225rem !important; }

.p-0\.25 { padding: 0.39045rem !important; }

.pt-0\.25, .py-0\.25 { padding-top: 0.39045rem !important; }

.pr-0\.25, .px-0\.25 { padding-right: 0.39045rem !important; }

.pb-0\.25, .py-0\.25 { padding-bottom: 0.39045rem !important; }

.pl-0\.25, .px-0\.25 { padding-left: 0.39045rem !important; }

.p-0\.5 { padding: 0.7809rem !important; }

.pt-0\.5, .py-0\.5 { padding-top: 0.7809rem !important; }

.pr-0\.5, .px-0\.5 { padding-right: 0.7809rem !important; }

.pb-0\.5, .py-0\.5 { padding-bottom: 0.7809rem !important; }

.pl-0\.5, .px-0\.5 { padding-left: 0.7809rem !important; }

.p-0\.75 { padding: 1.17135rem !important; }

.pt-0\.75, .py-0\.75 { padding-top: 1.17135rem !important; }

.pr-0\.75, .px-0\.75 { padding-right: 1.17135rem !important; }

.pb-0\.75, .py-0\.75 { padding-bottom: 1.17135rem !important; }

.pl-0\.75, .px-0\.75 { padding-left: 1.17135rem !important; }

.p-1\.25 { padding: 1.95225rem !important; }

.pt-1\.25, .py-1\.25 { padding-top: 1.95225rem !important; }

.pr-1\.25, .px-1\.25 { padding-right: 1.95225rem !important; }

.pb-1\.25, .py-1\.25 { padding-bottom: 1.95225rem !important; }

.pl-1\.25, .px-1\.25 { padding-left: 1.95225rem !important; }

.p-1\.5 { padding: 2.3427rem !important; }

.pt-1\.5, .py-1\.5 { padding-top: 2.3427rem !important; }

.pr-1\.5, .px-1\.5 { padding-right: 2.3427rem !important; }

.pb-1\.5, .py-1\.5 { padding-bottom: 2.3427rem !important; }

.pl-1\.5, .px-1\.5 { padding-left: 2.3427rem !important; }

.p-1\.75 { padding: 2.73315rem !important; }

.pt-1\.75, .py-1\.75 { padding-top: 2.73315rem !important; }

.pr-1\.75, .px-1\.75 { padding-right: 2.73315rem !important; }

.pb-1\.75, .py-1\.75 { padding-bottom: 2.73315rem !important; }

.pl-1\.75, .px-1\.75 { padding-left: 2.73315rem !important; }

.p-2\.5 { padding: 3.9045rem !important; }

.pt-2\.5, .py-2\.5 { padding-top: 3.9045rem !important; }

.pr-2\.5, .px-2\.5 { padding-right: 3.9045rem !important; }

.pb-2\.5, .py-2\.5 { padding-bottom: 3.9045rem !important; }

.pl-2\.5, .px-2\.5 { padding-left: 3.9045rem !important; }

.p-3\.5 { padding: 5.4663rem !important; }

.pt-3\.5, .py-3\.5 { padding-top: 5.4663rem !important; }

.pr-3\.5, .px-3\.5 { padding-right: 5.4663rem !important; }

.pb-3\.5, .py-3\.5 { padding-bottom: 5.4663rem !important; }

.pl-3\.5, .px-3\.5 { padding-left: 5.4663rem !important; }

.p-4\.5 { padding: 7.0281rem !important; }

.pt-4\.5, .py-4\.5 { padding-top: 7.0281rem !important; }

.pr-4\.5, .px-4\.5 { padding-right: 7.0281rem !important; }

.pb-4\.5, .py-4\.5 { padding-bottom: 7.0281rem !important; }

.pl-4\.5, .px-4\.5 { padding-left: 7.0281rem !important; }

.p-6 { padding: 9.3708rem !important; }

.pt-6, .py-6 { padding-top: 9.3708rem !important; }

.pr-6, .px-6 { padding-right: 9.3708rem !important; }

.pb-6, .py-6 { padding-bottom: 9.3708rem !important; }

.pl-6, .px-6 { padding-left: 9.3708rem !important; }

.p-7 { padding: 10.9326rem !important; }

.pt-7, .py-7 { padding-top: 10.9326rem !important; }

.pr-7, .px-7 { padding-right: 10.9326rem !important; }

.pb-7, .py-7 { padding-bottom: 10.9326rem !important; }

.pl-7, .px-7 { padding-left: 10.9326rem !important; }

.p-8 { padding: 12.4944rem !important; }

.pt-8, .py-8 { padding-top: 12.4944rem !important; }

.pr-8, .px-8 { padding-right: 12.4944rem !important; }

.pb-8, .py-8 { padding-bottom: 12.4944rem !important; }

.pl-8, .px-8 { padding-left: 12.4944rem !important; }

.p-9 { padding: 14.0562rem !important; }

.pt-9, .py-9 { padding-top: 14.0562rem !important; }

.pr-9, .px-9 { padding-right: 14.0562rem !important; }

.pb-9, .py-9 { padding-bottom: 14.0562rem !important; }

.pl-9, .px-9 { padding-left: 14.0562rem !important; }

.p-10 { padding: 15.618rem !important; }

.pt-10, .py-10 { padding-top: 15.618rem !important; }

.pr-10, .px-10 { padding-right: 15.618rem !important; }

.pb-10, .py-10 { padding-bottom: 15.618rem !important; }

.pl-10, .px-10 { padding-left: 15.618rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 1.5618rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 1.5618rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 1.5618rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 1.5618rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 1.5618rem !important; }
  .m-sm-2 { margin: 3.1236rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 3.1236rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 3.1236rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 3.1236rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 3.1236rem !important; }
  .m-sm-3 { margin: 4.6854rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 4.6854rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 4.6854rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 4.6854rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 4.6854rem !important; }
  .m-sm-4 { margin: 6.2472rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 6.2472rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 6.2472rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 6.2472rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 6.2472rem !important; }
  .m-sm-5 { margin: 7.809rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 7.809rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 7.809rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 7.809rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 7.809rem !important; }
  .m-sm-0\.125 { margin: 0.195225rem !important; }
  .mt-sm-0\.125, .my-sm-0\.125 { margin-top: 0.195225rem !important; }
  .mr-sm-0\.125, .mx-sm-0\.125 { margin-right: 0.195225rem !important; }
  .mb-sm-0\.125, .my-sm-0\.125 { margin-bottom: 0.195225rem !important; }
  .ml-sm-0\.125, .mx-sm-0\.125 { margin-left: 0.195225rem !important; }
  .m-sm-0\.25 { margin: 0.39045rem !important; }
  .mt-sm-0\.25, .my-sm-0\.25 { margin-top: 0.39045rem !important; }
  .mr-sm-0\.25, .mx-sm-0\.25 { margin-right: 0.39045rem !important; }
  .mb-sm-0\.25, .my-sm-0\.25 { margin-bottom: 0.39045rem !important; }
  .ml-sm-0\.25, .mx-sm-0\.25 { margin-left: 0.39045rem !important; }
  .m-sm-0\.5 { margin: 0.7809rem !important; }
  .mt-sm-0\.5, .my-sm-0\.5 { margin-top: 0.7809rem !important; }
  .mr-sm-0\.5, .mx-sm-0\.5 { margin-right: 0.7809rem !important; }
  .mb-sm-0\.5, .my-sm-0\.5 { margin-bottom: 0.7809rem !important; }
  .ml-sm-0\.5, .mx-sm-0\.5 { margin-left: 0.7809rem !important; }
  .m-sm-0\.75 { margin: 1.17135rem !important; }
  .mt-sm-0\.75, .my-sm-0\.75 { margin-top: 1.17135rem !important; }
  .mr-sm-0\.75, .mx-sm-0\.75 { margin-right: 1.17135rem !important; }
  .mb-sm-0\.75, .my-sm-0\.75 { margin-bottom: 1.17135rem !important; }
  .ml-sm-0\.75, .mx-sm-0\.75 { margin-left: 1.17135rem !important; }
  .m-sm-1\.25 { margin: 1.95225rem !important; }
  .mt-sm-1\.25, .my-sm-1\.25 { margin-top: 1.95225rem !important; }
  .mr-sm-1\.25, .mx-sm-1\.25 { margin-right: 1.95225rem !important; }
  .mb-sm-1\.25, .my-sm-1\.25 { margin-bottom: 1.95225rem !important; }
  .ml-sm-1\.25, .mx-sm-1\.25 { margin-left: 1.95225rem !important; }
  .m-sm-1\.5 { margin: 2.3427rem !important; }
  .mt-sm-1\.5, .my-sm-1\.5 { margin-top: 2.3427rem !important; }
  .mr-sm-1\.5, .mx-sm-1\.5 { margin-right: 2.3427rem !important; }
  .mb-sm-1\.5, .my-sm-1\.5 { margin-bottom: 2.3427rem !important; }
  .ml-sm-1\.5, .mx-sm-1\.5 { margin-left: 2.3427rem !important; }
  .m-sm-1\.75 { margin: 2.73315rem !important; }
  .mt-sm-1\.75, .my-sm-1\.75 { margin-top: 2.73315rem !important; }
  .mr-sm-1\.75, .mx-sm-1\.75 { margin-right: 2.73315rem !important; }
  .mb-sm-1\.75, .my-sm-1\.75 { margin-bottom: 2.73315rem !important; }
  .ml-sm-1\.75, .mx-sm-1\.75 { margin-left: 2.73315rem !important; }
  .m-sm-2\.5 { margin: 3.9045rem !important; }
  .mt-sm-2\.5, .my-sm-2\.5 { margin-top: 3.9045rem !important; }
  .mr-sm-2\.5, .mx-sm-2\.5 { margin-right: 3.9045rem !important; }
  .mb-sm-2\.5, .my-sm-2\.5 { margin-bottom: 3.9045rem !important; }
  .ml-sm-2\.5, .mx-sm-2\.5 { margin-left: 3.9045rem !important; }
  .m-sm-3\.5 { margin: 5.4663rem !important; }
  .mt-sm-3\.5, .my-sm-3\.5 { margin-top: 5.4663rem !important; }
  .mr-sm-3\.5, .mx-sm-3\.5 { margin-right: 5.4663rem !important; }
  .mb-sm-3\.5, .my-sm-3\.5 { margin-bottom: 5.4663rem !important; }
  .ml-sm-3\.5, .mx-sm-3\.5 { margin-left: 5.4663rem !important; }
  .m-sm-4\.5 { margin: 7.0281rem !important; }
  .mt-sm-4\.5, .my-sm-4\.5 { margin-top: 7.0281rem !important; }
  .mr-sm-4\.5, .mx-sm-4\.5 { margin-right: 7.0281rem !important; }
  .mb-sm-4\.5, .my-sm-4\.5 { margin-bottom: 7.0281rem !important; }
  .ml-sm-4\.5, .mx-sm-4\.5 { margin-left: 7.0281rem !important; }
  .m-sm-6 { margin: 9.3708rem !important; }
  .mt-sm-6, .my-sm-6 { margin-top: 9.3708rem !important; }
  .mr-sm-6, .mx-sm-6 { margin-right: 9.3708rem !important; }
  .mb-sm-6, .my-sm-6 { margin-bottom: 9.3708rem !important; }
  .ml-sm-6, .mx-sm-6 { margin-left: 9.3708rem !important; }
  .m-sm-7 { margin: 10.9326rem !important; }
  .mt-sm-7, .my-sm-7 { margin-top: 10.9326rem !important; }
  .mr-sm-7, .mx-sm-7 { margin-right: 10.9326rem !important; }
  .mb-sm-7, .my-sm-7 { margin-bottom: 10.9326rem !important; }
  .ml-sm-7, .mx-sm-7 { margin-left: 10.9326rem !important; }
  .m-sm-8 { margin: 12.4944rem !important; }
  .mt-sm-8, .my-sm-8 { margin-top: 12.4944rem !important; }
  .mr-sm-8, .mx-sm-8 { margin-right: 12.4944rem !important; }
  .mb-sm-8, .my-sm-8 { margin-bottom: 12.4944rem !important; }
  .ml-sm-8, .mx-sm-8 { margin-left: 12.4944rem !important; }
  .m-sm-9 { margin: 14.0562rem !important; }
  .mt-sm-9, .my-sm-9 { margin-top: 14.0562rem !important; }
  .mr-sm-9, .mx-sm-9 { margin-right: 14.0562rem !important; }
  .mb-sm-9, .my-sm-9 { margin-bottom: 14.0562rem !important; }
  .ml-sm-9, .mx-sm-9 { margin-left: 14.0562rem !important; }
  .m-sm-10 { margin: 15.618rem !important; }
  .mt-sm-10, .my-sm-10 { margin-top: 15.618rem !important; }
  .mr-sm-10, .mx-sm-10 { margin-right: 15.618rem !important; }
  .mb-sm-10, .my-sm-10 { margin-bottom: 15.618rem !important; }
  .ml-sm-10, .mx-sm-10 { margin-left: 15.618rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 1.5618rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 1.5618rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 1.5618rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 1.5618rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 1.5618rem !important; }
  .p-sm-2 { padding: 3.1236rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 3.1236rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 3.1236rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 3.1236rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 3.1236rem !important; }
  .p-sm-3 { padding: 4.6854rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 4.6854rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 4.6854rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 4.6854rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 4.6854rem !important; }
  .p-sm-4 { padding: 6.2472rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 6.2472rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 6.2472rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 6.2472rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 6.2472rem !important; }
  .p-sm-5 { padding: 7.809rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 7.809rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 7.809rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 7.809rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 7.809rem !important; }
  .p-sm-0\.125 { padding: 0.195225rem !important; }
  .pt-sm-0\.125, .py-sm-0\.125 { padding-top: 0.195225rem !important; }
  .pr-sm-0\.125, .px-sm-0\.125 { padding-right: 0.195225rem !important; }
  .pb-sm-0\.125, .py-sm-0\.125 { padding-bottom: 0.195225rem !important; }
  .pl-sm-0\.125, .px-sm-0\.125 { padding-left: 0.195225rem !important; }
  .p-sm-0\.25 { padding: 0.39045rem !important; }
  .pt-sm-0\.25, .py-sm-0\.25 { padding-top: 0.39045rem !important; }
  .pr-sm-0\.25, .px-sm-0\.25 { padding-right: 0.39045rem !important; }
  .pb-sm-0\.25, .py-sm-0\.25 { padding-bottom: 0.39045rem !important; }
  .pl-sm-0\.25, .px-sm-0\.25 { padding-left: 0.39045rem !important; }
  .p-sm-0\.5 { padding: 0.7809rem !important; }
  .pt-sm-0\.5, .py-sm-0\.5 { padding-top: 0.7809rem !important; }
  .pr-sm-0\.5, .px-sm-0\.5 { padding-right: 0.7809rem !important; }
  .pb-sm-0\.5, .py-sm-0\.5 { padding-bottom: 0.7809rem !important; }
  .pl-sm-0\.5, .px-sm-0\.5 { padding-left: 0.7809rem !important; }
  .p-sm-0\.75 { padding: 1.17135rem !important; }
  .pt-sm-0\.75, .py-sm-0\.75 { padding-top: 1.17135rem !important; }
  .pr-sm-0\.75, .px-sm-0\.75 { padding-right: 1.17135rem !important; }
  .pb-sm-0\.75, .py-sm-0\.75 { padding-bottom: 1.17135rem !important; }
  .pl-sm-0\.75, .px-sm-0\.75 { padding-left: 1.17135rem !important; }
  .p-sm-1\.25 { padding: 1.95225rem !important; }
  .pt-sm-1\.25, .py-sm-1\.25 { padding-top: 1.95225rem !important; }
  .pr-sm-1\.25, .px-sm-1\.25 { padding-right: 1.95225rem !important; }
  .pb-sm-1\.25, .py-sm-1\.25 { padding-bottom: 1.95225rem !important; }
  .pl-sm-1\.25, .px-sm-1\.25 { padding-left: 1.95225rem !important; }
  .p-sm-1\.5 { padding: 2.3427rem !important; }
  .pt-sm-1\.5, .py-sm-1\.5 { padding-top: 2.3427rem !important; }
  .pr-sm-1\.5, .px-sm-1\.5 { padding-right: 2.3427rem !important; }
  .pb-sm-1\.5, .py-sm-1\.5 { padding-bottom: 2.3427rem !important; }
  .pl-sm-1\.5, .px-sm-1\.5 { padding-left: 2.3427rem !important; }
  .p-sm-1\.75 { padding: 2.73315rem !important; }
  .pt-sm-1\.75, .py-sm-1\.75 { padding-top: 2.73315rem !important; }
  .pr-sm-1\.75, .px-sm-1\.75 { padding-right: 2.73315rem !important; }
  .pb-sm-1\.75, .py-sm-1\.75 { padding-bottom: 2.73315rem !important; }
  .pl-sm-1\.75, .px-sm-1\.75 { padding-left: 2.73315rem !important; }
  .p-sm-2\.5 { padding: 3.9045rem !important; }
  .pt-sm-2\.5, .py-sm-2\.5 { padding-top: 3.9045rem !important; }
  .pr-sm-2\.5, .px-sm-2\.5 { padding-right: 3.9045rem !important; }
  .pb-sm-2\.5, .py-sm-2\.5 { padding-bottom: 3.9045rem !important; }
  .pl-sm-2\.5, .px-sm-2\.5 { padding-left: 3.9045rem !important; }
  .p-sm-3\.5 { padding: 5.4663rem !important; }
  .pt-sm-3\.5, .py-sm-3\.5 { padding-top: 5.4663rem !important; }
  .pr-sm-3\.5, .px-sm-3\.5 { padding-right: 5.4663rem !important; }
  .pb-sm-3\.5, .py-sm-3\.5 { padding-bottom: 5.4663rem !important; }
  .pl-sm-3\.5, .px-sm-3\.5 { padding-left: 5.4663rem !important; }
  .p-sm-4\.5 { padding: 7.0281rem !important; }
  .pt-sm-4\.5, .py-sm-4\.5 { padding-top: 7.0281rem !important; }
  .pr-sm-4\.5, .px-sm-4\.5 { padding-right: 7.0281rem !important; }
  .pb-sm-4\.5, .py-sm-4\.5 { padding-bottom: 7.0281rem !important; }
  .pl-sm-4\.5, .px-sm-4\.5 { padding-left: 7.0281rem !important; }
  .p-sm-6 { padding: 9.3708rem !important; }
  .pt-sm-6, .py-sm-6 { padding-top: 9.3708rem !important; }
  .pr-sm-6, .px-sm-6 { padding-right: 9.3708rem !important; }
  .pb-sm-6, .py-sm-6 { padding-bottom: 9.3708rem !important; }
  .pl-sm-6, .px-sm-6 { padding-left: 9.3708rem !important; }
  .p-sm-7 { padding: 10.9326rem !important; }
  .pt-sm-7, .py-sm-7 { padding-top: 10.9326rem !important; }
  .pr-sm-7, .px-sm-7 { padding-right: 10.9326rem !important; }
  .pb-sm-7, .py-sm-7 { padding-bottom: 10.9326rem !important; }
  .pl-sm-7, .px-sm-7 { padding-left: 10.9326rem !important; }
  .p-sm-8 { padding: 12.4944rem !important; }
  .pt-sm-8, .py-sm-8 { padding-top: 12.4944rem !important; }
  .pr-sm-8, .px-sm-8 { padding-right: 12.4944rem !important; }
  .pb-sm-8, .py-sm-8 { padding-bottom: 12.4944rem !important; }
  .pl-sm-8, .px-sm-8 { padding-left: 12.4944rem !important; }
  .p-sm-9 { padding: 14.0562rem !important; }
  .pt-sm-9, .py-sm-9 { padding-top: 14.0562rem !important; }
  .pr-sm-9, .px-sm-9 { padding-right: 14.0562rem !important; }
  .pb-sm-9, .py-sm-9 { padding-bottom: 14.0562rem !important; }
  .pl-sm-9, .px-sm-9 { padding-left: 14.0562rem !important; }
  .p-sm-10 { padding: 15.618rem !important; }
  .pt-sm-10, .py-sm-10 { padding-top: 15.618rem !important; }
  .pr-sm-10, .px-sm-10 { padding-right: 15.618rem !important; }
  .pb-sm-10, .py-sm-10 { padding-bottom: 15.618rem !important; }
  .pl-sm-10, .px-sm-10 { padding-left: 15.618rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 1.5618rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 1.5618rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 1.5618rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 1.5618rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 1.5618rem !important; }
  .m-md-2 { margin: 3.1236rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 3.1236rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 3.1236rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 3.1236rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 3.1236rem !important; }
  .m-md-3 { margin: 4.6854rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 4.6854rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 4.6854rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 4.6854rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 4.6854rem !important; }
  .m-md-4 { margin: 6.2472rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 6.2472rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 6.2472rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 6.2472rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 6.2472rem !important; }
  .m-md-5 { margin: 7.809rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 7.809rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 7.809rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 7.809rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 7.809rem !important; }
  .m-md-0\.125 { margin: 0.195225rem !important; }
  .mt-md-0\.125, .my-md-0\.125 { margin-top: 0.195225rem !important; }
  .mr-md-0\.125, .mx-md-0\.125 { margin-right: 0.195225rem !important; }
  .mb-md-0\.125, .my-md-0\.125 { margin-bottom: 0.195225rem !important; }
  .ml-md-0\.125, .mx-md-0\.125 { margin-left: 0.195225rem !important; }
  .m-md-0\.25 { margin: 0.39045rem !important; }
  .mt-md-0\.25, .my-md-0\.25 { margin-top: 0.39045rem !important; }
  .mr-md-0\.25, .mx-md-0\.25 { margin-right: 0.39045rem !important; }
  .mb-md-0\.25, .my-md-0\.25 { margin-bottom: 0.39045rem !important; }
  .ml-md-0\.25, .mx-md-0\.25 { margin-left: 0.39045rem !important; }
  .m-md-0\.5 { margin: 0.7809rem !important; }
  .mt-md-0\.5, .my-md-0\.5 { margin-top: 0.7809rem !important; }
  .mr-md-0\.5, .mx-md-0\.5 { margin-right: 0.7809rem !important; }
  .mb-md-0\.5, .my-md-0\.5 { margin-bottom: 0.7809rem !important; }
  .ml-md-0\.5, .mx-md-0\.5 { margin-left: 0.7809rem !important; }
  .m-md-0\.75 { margin: 1.17135rem !important; }
  .mt-md-0\.75, .my-md-0\.75 { margin-top: 1.17135rem !important; }
  .mr-md-0\.75, .mx-md-0\.75 { margin-right: 1.17135rem !important; }
  .mb-md-0\.75, .my-md-0\.75 { margin-bottom: 1.17135rem !important; }
  .ml-md-0\.75, .mx-md-0\.75 { margin-left: 1.17135rem !important; }
  .m-md-1\.25 { margin: 1.95225rem !important; }
  .mt-md-1\.25, .my-md-1\.25 { margin-top: 1.95225rem !important; }
  .mr-md-1\.25, .mx-md-1\.25 { margin-right: 1.95225rem !important; }
  .mb-md-1\.25, .my-md-1\.25 { margin-bottom: 1.95225rem !important; }
  .ml-md-1\.25, .mx-md-1\.25 { margin-left: 1.95225rem !important; }
  .m-md-1\.5 { margin: 2.3427rem !important; }
  .mt-md-1\.5, .my-md-1\.5 { margin-top: 2.3427rem !important; }
  .mr-md-1\.5, .mx-md-1\.5 { margin-right: 2.3427rem !important; }
  .mb-md-1\.5, .my-md-1\.5 { margin-bottom: 2.3427rem !important; }
  .ml-md-1\.5, .mx-md-1\.5 { margin-left: 2.3427rem !important; }
  .m-md-1\.75 { margin: 2.73315rem !important; }
  .mt-md-1\.75, .my-md-1\.75 { margin-top: 2.73315rem !important; }
  .mr-md-1\.75, .mx-md-1\.75 { margin-right: 2.73315rem !important; }
  .mb-md-1\.75, .my-md-1\.75 { margin-bottom: 2.73315rem !important; }
  .ml-md-1\.75, .mx-md-1\.75 { margin-left: 2.73315rem !important; }
  .m-md-2\.5 { margin: 3.9045rem !important; }
  .mt-md-2\.5, .my-md-2\.5 { margin-top: 3.9045rem !important; }
  .mr-md-2\.5, .mx-md-2\.5 { margin-right: 3.9045rem !important; }
  .mb-md-2\.5, .my-md-2\.5 { margin-bottom: 3.9045rem !important; }
  .ml-md-2\.5, .mx-md-2\.5 { margin-left: 3.9045rem !important; }
  .m-md-3\.5 { margin: 5.4663rem !important; }
  .mt-md-3\.5, .my-md-3\.5 { margin-top: 5.4663rem !important; }
  .mr-md-3\.5, .mx-md-3\.5 { margin-right: 5.4663rem !important; }
  .mb-md-3\.5, .my-md-3\.5 { margin-bottom: 5.4663rem !important; }
  .ml-md-3\.5, .mx-md-3\.5 { margin-left: 5.4663rem !important; }
  .m-md-4\.5 { margin: 7.0281rem !important; }
  .mt-md-4\.5, .my-md-4\.5 { margin-top: 7.0281rem !important; }
  .mr-md-4\.5, .mx-md-4\.5 { margin-right: 7.0281rem !important; }
  .mb-md-4\.5, .my-md-4\.5 { margin-bottom: 7.0281rem !important; }
  .ml-md-4\.5, .mx-md-4\.5 { margin-left: 7.0281rem !important; }
  .m-md-6 { margin: 9.3708rem !important; }
  .mt-md-6, .my-md-6 { margin-top: 9.3708rem !important; }
  .mr-md-6, .mx-md-6 { margin-right: 9.3708rem !important; }
  .mb-md-6, .my-md-6 { margin-bottom: 9.3708rem !important; }
  .ml-md-6, .mx-md-6 { margin-left: 9.3708rem !important; }
  .m-md-7 { margin: 10.9326rem !important; }
  .mt-md-7, .my-md-7 { margin-top: 10.9326rem !important; }
  .mr-md-7, .mx-md-7 { margin-right: 10.9326rem !important; }
  .mb-md-7, .my-md-7 { margin-bottom: 10.9326rem !important; }
  .ml-md-7, .mx-md-7 { margin-left: 10.9326rem !important; }
  .m-md-8 { margin: 12.4944rem !important; }
  .mt-md-8, .my-md-8 { margin-top: 12.4944rem !important; }
  .mr-md-8, .mx-md-8 { margin-right: 12.4944rem !important; }
  .mb-md-8, .my-md-8 { margin-bottom: 12.4944rem !important; }
  .ml-md-8, .mx-md-8 { margin-left: 12.4944rem !important; }
  .m-md-9 { margin: 14.0562rem !important; }
  .mt-md-9, .my-md-9 { margin-top: 14.0562rem !important; }
  .mr-md-9, .mx-md-9 { margin-right: 14.0562rem !important; }
  .mb-md-9, .my-md-9 { margin-bottom: 14.0562rem !important; }
  .ml-md-9, .mx-md-9 { margin-left: 14.0562rem !important; }
  .m-md-10 { margin: 15.618rem !important; }
  .mt-md-10, .my-md-10 { margin-top: 15.618rem !important; }
  .mr-md-10, .mx-md-10 { margin-right: 15.618rem !important; }
  .mb-md-10, .my-md-10 { margin-bottom: 15.618rem !important; }
  .ml-md-10, .mx-md-10 { margin-left: 15.618rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 1.5618rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 1.5618rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 1.5618rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 1.5618rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 1.5618rem !important; }
  .p-md-2 { padding: 3.1236rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 3.1236rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 3.1236rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 3.1236rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 3.1236rem !important; }
  .p-md-3 { padding: 4.6854rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 4.6854rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 4.6854rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 4.6854rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 4.6854rem !important; }
  .p-md-4 { padding: 6.2472rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 6.2472rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 6.2472rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 6.2472rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 6.2472rem !important; }
  .p-md-5 { padding: 7.809rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 7.809rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 7.809rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 7.809rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 7.809rem !important; }
  .p-md-0\.125 { padding: 0.195225rem !important; }
  .pt-md-0\.125, .py-md-0\.125 { padding-top: 0.195225rem !important; }
  .pr-md-0\.125, .px-md-0\.125 { padding-right: 0.195225rem !important; }
  .pb-md-0\.125, .py-md-0\.125 { padding-bottom: 0.195225rem !important; }
  .pl-md-0\.125, .px-md-0\.125 { padding-left: 0.195225rem !important; }
  .p-md-0\.25 { padding: 0.39045rem !important; }
  .pt-md-0\.25, .py-md-0\.25 { padding-top: 0.39045rem !important; }
  .pr-md-0\.25, .px-md-0\.25 { padding-right: 0.39045rem !important; }
  .pb-md-0\.25, .py-md-0\.25 { padding-bottom: 0.39045rem !important; }
  .pl-md-0\.25, .px-md-0\.25 { padding-left: 0.39045rem !important; }
  .p-md-0\.5 { padding: 0.7809rem !important; }
  .pt-md-0\.5, .py-md-0\.5 { padding-top: 0.7809rem !important; }
  .pr-md-0\.5, .px-md-0\.5 { padding-right: 0.7809rem !important; }
  .pb-md-0\.5, .py-md-0\.5 { padding-bottom: 0.7809rem !important; }
  .pl-md-0\.5, .px-md-0\.5 { padding-left: 0.7809rem !important; }
  .p-md-0\.75 { padding: 1.17135rem !important; }
  .pt-md-0\.75, .py-md-0\.75 { padding-top: 1.17135rem !important; }
  .pr-md-0\.75, .px-md-0\.75 { padding-right: 1.17135rem !important; }
  .pb-md-0\.75, .py-md-0\.75 { padding-bottom: 1.17135rem !important; }
  .pl-md-0\.75, .px-md-0\.75 { padding-left: 1.17135rem !important; }
  .p-md-1\.25 { padding: 1.95225rem !important; }
  .pt-md-1\.25, .py-md-1\.25 { padding-top: 1.95225rem !important; }
  .pr-md-1\.25, .px-md-1\.25 { padding-right: 1.95225rem !important; }
  .pb-md-1\.25, .py-md-1\.25 { padding-bottom: 1.95225rem !important; }
  .pl-md-1\.25, .px-md-1\.25 { padding-left: 1.95225rem !important; }
  .p-md-1\.5 { padding: 2.3427rem !important; }
  .pt-md-1\.5, .py-md-1\.5 { padding-top: 2.3427rem !important; }
  .pr-md-1\.5, .px-md-1\.5 { padding-right: 2.3427rem !important; }
  .pb-md-1\.5, .py-md-1\.5 { padding-bottom: 2.3427rem !important; }
  .pl-md-1\.5, .px-md-1\.5 { padding-left: 2.3427rem !important; }
  .p-md-1\.75 { padding: 2.73315rem !important; }
  .pt-md-1\.75, .py-md-1\.75 { padding-top: 2.73315rem !important; }
  .pr-md-1\.75, .px-md-1\.75 { padding-right: 2.73315rem !important; }
  .pb-md-1\.75, .py-md-1\.75 { padding-bottom: 2.73315rem !important; }
  .pl-md-1\.75, .px-md-1\.75 { padding-left: 2.73315rem !important; }
  .p-md-2\.5 { padding: 3.9045rem !important; }
  .pt-md-2\.5, .py-md-2\.5 { padding-top: 3.9045rem !important; }
  .pr-md-2\.5, .px-md-2\.5 { padding-right: 3.9045rem !important; }
  .pb-md-2\.5, .py-md-2\.5 { padding-bottom: 3.9045rem !important; }
  .pl-md-2\.5, .px-md-2\.5 { padding-left: 3.9045rem !important; }
  .p-md-3\.5 { padding: 5.4663rem !important; }
  .pt-md-3\.5, .py-md-3\.5 { padding-top: 5.4663rem !important; }
  .pr-md-3\.5, .px-md-3\.5 { padding-right: 5.4663rem !important; }
  .pb-md-3\.5, .py-md-3\.5 { padding-bottom: 5.4663rem !important; }
  .pl-md-3\.5, .px-md-3\.5 { padding-left: 5.4663rem !important; }
  .p-md-4\.5 { padding: 7.0281rem !important; }
  .pt-md-4\.5, .py-md-4\.5 { padding-top: 7.0281rem !important; }
  .pr-md-4\.5, .px-md-4\.5 { padding-right: 7.0281rem !important; }
  .pb-md-4\.5, .py-md-4\.5 { padding-bottom: 7.0281rem !important; }
  .pl-md-4\.5, .px-md-4\.5 { padding-left: 7.0281rem !important; }
  .p-md-6 { padding: 9.3708rem !important; }
  .pt-md-6, .py-md-6 { padding-top: 9.3708rem !important; }
  .pr-md-6, .px-md-6 { padding-right: 9.3708rem !important; }
  .pb-md-6, .py-md-6 { padding-bottom: 9.3708rem !important; }
  .pl-md-6, .px-md-6 { padding-left: 9.3708rem !important; }
  .p-md-7 { padding: 10.9326rem !important; }
  .pt-md-7, .py-md-7 { padding-top: 10.9326rem !important; }
  .pr-md-7, .px-md-7 { padding-right: 10.9326rem !important; }
  .pb-md-7, .py-md-7 { padding-bottom: 10.9326rem !important; }
  .pl-md-7, .px-md-7 { padding-left: 10.9326rem !important; }
  .p-md-8 { padding: 12.4944rem !important; }
  .pt-md-8, .py-md-8 { padding-top: 12.4944rem !important; }
  .pr-md-8, .px-md-8 { padding-right: 12.4944rem !important; }
  .pb-md-8, .py-md-8 { padding-bottom: 12.4944rem !important; }
  .pl-md-8, .px-md-8 { padding-left: 12.4944rem !important; }
  .p-md-9 { padding: 14.0562rem !important; }
  .pt-md-9, .py-md-9 { padding-top: 14.0562rem !important; }
  .pr-md-9, .px-md-9 { padding-right: 14.0562rem !important; }
  .pb-md-9, .py-md-9 { padding-bottom: 14.0562rem !important; }
  .pl-md-9, .px-md-9 { padding-left: 14.0562rem !important; }
  .p-md-10 { padding: 15.618rem !important; }
  .pt-md-10, .py-md-10 { padding-top: 15.618rem !important; }
  .pr-md-10, .px-md-10 { padding-right: 15.618rem !important; }
  .pb-md-10, .py-md-10 { padding-bottom: 15.618rem !important; }
  .pl-md-10, .px-md-10 { padding-left: 15.618rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 1.5618rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 1.5618rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 1.5618rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 1.5618rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 1.5618rem !important; }
  .m-lg-2 { margin: 3.1236rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 3.1236rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 3.1236rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 3.1236rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 3.1236rem !important; }
  .m-lg-3 { margin: 4.6854rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 4.6854rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 4.6854rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 4.6854rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 4.6854rem !important; }
  .m-lg-4 { margin: 6.2472rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 6.2472rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 6.2472rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 6.2472rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 6.2472rem !important; }
  .m-lg-5 { margin: 7.809rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 7.809rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 7.809rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 7.809rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 7.809rem !important; }
  .m-lg-0\.125 { margin: 0.195225rem !important; }
  .mt-lg-0\.125, .my-lg-0\.125 { margin-top: 0.195225rem !important; }
  .mr-lg-0\.125, .mx-lg-0\.125 { margin-right: 0.195225rem !important; }
  .mb-lg-0\.125, .my-lg-0\.125 { margin-bottom: 0.195225rem !important; }
  .ml-lg-0\.125, .mx-lg-0\.125 { margin-left: 0.195225rem !important; }
  .m-lg-0\.25 { margin: 0.39045rem !important; }
  .mt-lg-0\.25, .my-lg-0\.25 { margin-top: 0.39045rem !important; }
  .mr-lg-0\.25, .mx-lg-0\.25 { margin-right: 0.39045rem !important; }
  .mb-lg-0\.25, .my-lg-0\.25 { margin-bottom: 0.39045rem !important; }
  .ml-lg-0\.25, .mx-lg-0\.25 { margin-left: 0.39045rem !important; }
  .m-lg-0\.5 { margin: 0.7809rem !important; }
  .mt-lg-0\.5, .my-lg-0\.5 { margin-top: 0.7809rem !important; }
  .mr-lg-0\.5, .mx-lg-0\.5 { margin-right: 0.7809rem !important; }
  .mb-lg-0\.5, .my-lg-0\.5 { margin-bottom: 0.7809rem !important; }
  .ml-lg-0\.5, .mx-lg-0\.5 { margin-left: 0.7809rem !important; }
  .m-lg-0\.75 { margin: 1.17135rem !important; }
  .mt-lg-0\.75, .my-lg-0\.75 { margin-top: 1.17135rem !important; }
  .mr-lg-0\.75, .mx-lg-0\.75 { margin-right: 1.17135rem !important; }
  .mb-lg-0\.75, .my-lg-0\.75 { margin-bottom: 1.17135rem !important; }
  .ml-lg-0\.75, .mx-lg-0\.75 { margin-left: 1.17135rem !important; }
  .m-lg-1\.25 { margin: 1.95225rem !important; }
  .mt-lg-1\.25, .my-lg-1\.25 { margin-top: 1.95225rem !important; }
  .mr-lg-1\.25, .mx-lg-1\.25 { margin-right: 1.95225rem !important; }
  .mb-lg-1\.25, .my-lg-1\.25 { margin-bottom: 1.95225rem !important; }
  .ml-lg-1\.25, .mx-lg-1\.25 { margin-left: 1.95225rem !important; }
  .m-lg-1\.5 { margin: 2.3427rem !important; }
  .mt-lg-1\.5, .my-lg-1\.5 { margin-top: 2.3427rem !important; }
  .mr-lg-1\.5, .mx-lg-1\.5 { margin-right: 2.3427rem !important; }
  .mb-lg-1\.5, .my-lg-1\.5 { margin-bottom: 2.3427rem !important; }
  .ml-lg-1\.5, .mx-lg-1\.5 { margin-left: 2.3427rem !important; }
  .m-lg-1\.75 { margin: 2.73315rem !important; }
  .mt-lg-1\.75, .my-lg-1\.75 { margin-top: 2.73315rem !important; }
  .mr-lg-1\.75, .mx-lg-1\.75 { margin-right: 2.73315rem !important; }
  .mb-lg-1\.75, .my-lg-1\.75 { margin-bottom: 2.73315rem !important; }
  .ml-lg-1\.75, .mx-lg-1\.75 { margin-left: 2.73315rem !important; }
  .m-lg-2\.5 { margin: 3.9045rem !important; }
  .mt-lg-2\.5, .my-lg-2\.5 { margin-top: 3.9045rem !important; }
  .mr-lg-2\.5, .mx-lg-2\.5 { margin-right: 3.9045rem !important; }
  .mb-lg-2\.5, .my-lg-2\.5 { margin-bottom: 3.9045rem !important; }
  .ml-lg-2\.5, .mx-lg-2\.5 { margin-left: 3.9045rem !important; }
  .m-lg-3\.5 { margin: 5.4663rem !important; }
  .mt-lg-3\.5, .my-lg-3\.5 { margin-top: 5.4663rem !important; }
  .mr-lg-3\.5, .mx-lg-3\.5 { margin-right: 5.4663rem !important; }
  .mb-lg-3\.5, .my-lg-3\.5 { margin-bottom: 5.4663rem !important; }
  .ml-lg-3\.5, .mx-lg-3\.5 { margin-left: 5.4663rem !important; }
  .m-lg-4\.5 { margin: 7.0281rem !important; }
  .mt-lg-4\.5, .my-lg-4\.5 { margin-top: 7.0281rem !important; }
  .mr-lg-4\.5, .mx-lg-4\.5 { margin-right: 7.0281rem !important; }
  .mb-lg-4\.5, .my-lg-4\.5 { margin-bottom: 7.0281rem !important; }
  .ml-lg-4\.5, .mx-lg-4\.5 { margin-left: 7.0281rem !important; }
  .m-lg-6 { margin: 9.3708rem !important; }
  .mt-lg-6, .my-lg-6 { margin-top: 9.3708rem !important; }
  .mr-lg-6, .mx-lg-6 { margin-right: 9.3708rem !important; }
  .mb-lg-6, .my-lg-6 { margin-bottom: 9.3708rem !important; }
  .ml-lg-6, .mx-lg-6 { margin-left: 9.3708rem !important; }
  .m-lg-7 { margin: 10.9326rem !important; }
  .mt-lg-7, .my-lg-7 { margin-top: 10.9326rem !important; }
  .mr-lg-7, .mx-lg-7 { margin-right: 10.9326rem !important; }
  .mb-lg-7, .my-lg-7 { margin-bottom: 10.9326rem !important; }
  .ml-lg-7, .mx-lg-7 { margin-left: 10.9326rem !important; }
  .m-lg-8 { margin: 12.4944rem !important; }
  .mt-lg-8, .my-lg-8 { margin-top: 12.4944rem !important; }
  .mr-lg-8, .mx-lg-8 { margin-right: 12.4944rem !important; }
  .mb-lg-8, .my-lg-8 { margin-bottom: 12.4944rem !important; }
  .ml-lg-8, .mx-lg-8 { margin-left: 12.4944rem !important; }
  .m-lg-9 { margin: 14.0562rem !important; }
  .mt-lg-9, .my-lg-9 { margin-top: 14.0562rem !important; }
  .mr-lg-9, .mx-lg-9 { margin-right: 14.0562rem !important; }
  .mb-lg-9, .my-lg-9 { margin-bottom: 14.0562rem !important; }
  .ml-lg-9, .mx-lg-9 { margin-left: 14.0562rem !important; }
  .m-lg-10 { margin: 15.618rem !important; }
  .mt-lg-10, .my-lg-10 { margin-top: 15.618rem !important; }
  .mr-lg-10, .mx-lg-10 { margin-right: 15.618rem !important; }
  .mb-lg-10, .my-lg-10 { margin-bottom: 15.618rem !important; }
  .ml-lg-10, .mx-lg-10 { margin-left: 15.618rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 1.5618rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 1.5618rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 1.5618rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 1.5618rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 1.5618rem !important; }
  .p-lg-2 { padding: 3.1236rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 3.1236rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 3.1236rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 3.1236rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 3.1236rem !important; }
  .p-lg-3 { padding: 4.6854rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 4.6854rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 4.6854rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 4.6854rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 4.6854rem !important; }
  .p-lg-4 { padding: 6.2472rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 6.2472rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 6.2472rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 6.2472rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 6.2472rem !important; }
  .p-lg-5 { padding: 7.809rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 7.809rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 7.809rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 7.809rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 7.809rem !important; }
  .p-lg-0\.125 { padding: 0.195225rem !important; }
  .pt-lg-0\.125, .py-lg-0\.125 { padding-top: 0.195225rem !important; }
  .pr-lg-0\.125, .px-lg-0\.125 { padding-right: 0.195225rem !important; }
  .pb-lg-0\.125, .py-lg-0\.125 { padding-bottom: 0.195225rem !important; }
  .pl-lg-0\.125, .px-lg-0\.125 { padding-left: 0.195225rem !important; }
  .p-lg-0\.25 { padding: 0.39045rem !important; }
  .pt-lg-0\.25, .py-lg-0\.25 { padding-top: 0.39045rem !important; }
  .pr-lg-0\.25, .px-lg-0\.25 { padding-right: 0.39045rem !important; }
  .pb-lg-0\.25, .py-lg-0\.25 { padding-bottom: 0.39045rem !important; }
  .pl-lg-0\.25, .px-lg-0\.25 { padding-left: 0.39045rem !important; }
  .p-lg-0\.5 { padding: 0.7809rem !important; }
  .pt-lg-0\.5, .py-lg-0\.5 { padding-top: 0.7809rem !important; }
  .pr-lg-0\.5, .px-lg-0\.5 { padding-right: 0.7809rem !important; }
  .pb-lg-0\.5, .py-lg-0\.5 { padding-bottom: 0.7809rem !important; }
  .pl-lg-0\.5, .px-lg-0\.5 { padding-left: 0.7809rem !important; }
  .p-lg-0\.75 { padding: 1.17135rem !important; }
  .pt-lg-0\.75, .py-lg-0\.75 { padding-top: 1.17135rem !important; }
  .pr-lg-0\.75, .px-lg-0\.75 { padding-right: 1.17135rem !important; }
  .pb-lg-0\.75, .py-lg-0\.75 { padding-bottom: 1.17135rem !important; }
  .pl-lg-0\.75, .px-lg-0\.75 { padding-left: 1.17135rem !important; }
  .p-lg-1\.25 { padding: 1.95225rem !important; }
  .pt-lg-1\.25, .py-lg-1\.25 { padding-top: 1.95225rem !important; }
  .pr-lg-1\.25, .px-lg-1\.25 { padding-right: 1.95225rem !important; }
  .pb-lg-1\.25, .py-lg-1\.25 { padding-bottom: 1.95225rem !important; }
  .pl-lg-1\.25, .px-lg-1\.25 { padding-left: 1.95225rem !important; }
  .p-lg-1\.5 { padding: 2.3427rem !important; }
  .pt-lg-1\.5, .py-lg-1\.5 { padding-top: 2.3427rem !important; }
  .pr-lg-1\.5, .px-lg-1\.5 { padding-right: 2.3427rem !important; }
  .pb-lg-1\.5, .py-lg-1\.5 { padding-bottom: 2.3427rem !important; }
  .pl-lg-1\.5, .px-lg-1\.5 { padding-left: 2.3427rem !important; }
  .p-lg-1\.75 { padding: 2.73315rem !important; }
  .pt-lg-1\.75, .py-lg-1\.75 { padding-top: 2.73315rem !important; }
  .pr-lg-1\.75, .px-lg-1\.75 { padding-right: 2.73315rem !important; }
  .pb-lg-1\.75, .py-lg-1\.75 { padding-bottom: 2.73315rem !important; }
  .pl-lg-1\.75, .px-lg-1\.75 { padding-left: 2.73315rem !important; }
  .p-lg-2\.5 { padding: 3.9045rem !important; }
  .pt-lg-2\.5, .py-lg-2\.5 { padding-top: 3.9045rem !important; }
  .pr-lg-2\.5, .px-lg-2\.5 { padding-right: 3.9045rem !important; }
  .pb-lg-2\.5, .py-lg-2\.5 { padding-bottom: 3.9045rem !important; }
  .pl-lg-2\.5, .px-lg-2\.5 { padding-left: 3.9045rem !important; }
  .p-lg-3\.5 { padding: 5.4663rem !important; }
  .pt-lg-3\.5, .py-lg-3\.5 { padding-top: 5.4663rem !important; }
  .pr-lg-3\.5, .px-lg-3\.5 { padding-right: 5.4663rem !important; }
  .pb-lg-3\.5, .py-lg-3\.5 { padding-bottom: 5.4663rem !important; }
  .pl-lg-3\.5, .px-lg-3\.5 { padding-left: 5.4663rem !important; }
  .p-lg-4\.5 { padding: 7.0281rem !important; }
  .pt-lg-4\.5, .py-lg-4\.5 { padding-top: 7.0281rem !important; }
  .pr-lg-4\.5, .px-lg-4\.5 { padding-right: 7.0281rem !important; }
  .pb-lg-4\.5, .py-lg-4\.5 { padding-bottom: 7.0281rem !important; }
  .pl-lg-4\.5, .px-lg-4\.5 { padding-left: 7.0281rem !important; }
  .p-lg-6 { padding: 9.3708rem !important; }
  .pt-lg-6, .py-lg-6 { padding-top: 9.3708rem !important; }
  .pr-lg-6, .px-lg-6 { padding-right: 9.3708rem !important; }
  .pb-lg-6, .py-lg-6 { padding-bottom: 9.3708rem !important; }
  .pl-lg-6, .px-lg-6 { padding-left: 9.3708rem !important; }
  .p-lg-7 { padding: 10.9326rem !important; }
  .pt-lg-7, .py-lg-7 { padding-top: 10.9326rem !important; }
  .pr-lg-7, .px-lg-7 { padding-right: 10.9326rem !important; }
  .pb-lg-7, .py-lg-7 { padding-bottom: 10.9326rem !important; }
  .pl-lg-7, .px-lg-7 { padding-left: 10.9326rem !important; }
  .p-lg-8 { padding: 12.4944rem !important; }
  .pt-lg-8, .py-lg-8 { padding-top: 12.4944rem !important; }
  .pr-lg-8, .px-lg-8 { padding-right: 12.4944rem !important; }
  .pb-lg-8, .py-lg-8 { padding-bottom: 12.4944rem !important; }
  .pl-lg-8, .px-lg-8 { padding-left: 12.4944rem !important; }
  .p-lg-9 { padding: 14.0562rem !important; }
  .pt-lg-9, .py-lg-9 { padding-top: 14.0562rem !important; }
  .pr-lg-9, .px-lg-9 { padding-right: 14.0562rem !important; }
  .pb-lg-9, .py-lg-9 { padding-bottom: 14.0562rem !important; }
  .pl-lg-9, .px-lg-9 { padding-left: 14.0562rem !important; }
  .p-lg-10 { padding: 15.618rem !important; }
  .pt-lg-10, .py-lg-10 { padding-top: 15.618rem !important; }
  .pr-lg-10, .px-lg-10 { padding-right: 15.618rem !important; }
  .pb-lg-10, .py-lg-10 { padding-bottom: 15.618rem !important; }
  .pl-lg-10, .px-lg-10 { padding-left: 15.618rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 1.5618rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 1.5618rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 1.5618rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 1.5618rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 1.5618rem !important; }
  .m-xl-2 { margin: 3.1236rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 3.1236rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 3.1236rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 3.1236rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 3.1236rem !important; }
  .m-xl-3 { margin: 4.6854rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 4.6854rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 4.6854rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 4.6854rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 4.6854rem !important; }
  .m-xl-4 { margin: 6.2472rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 6.2472rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 6.2472rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 6.2472rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 6.2472rem !important; }
  .m-xl-5 { margin: 7.809rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 7.809rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 7.809rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 7.809rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 7.809rem !important; }
  .m-xl-0\.125 { margin: 0.195225rem !important; }
  .mt-xl-0\.125, .my-xl-0\.125 { margin-top: 0.195225rem !important; }
  .mr-xl-0\.125, .mx-xl-0\.125 { margin-right: 0.195225rem !important; }
  .mb-xl-0\.125, .my-xl-0\.125 { margin-bottom: 0.195225rem !important; }
  .ml-xl-0\.125, .mx-xl-0\.125 { margin-left: 0.195225rem !important; }
  .m-xl-0\.25 { margin: 0.39045rem !important; }
  .mt-xl-0\.25, .my-xl-0\.25 { margin-top: 0.39045rem !important; }
  .mr-xl-0\.25, .mx-xl-0\.25 { margin-right: 0.39045rem !important; }
  .mb-xl-0\.25, .my-xl-0\.25 { margin-bottom: 0.39045rem !important; }
  .ml-xl-0\.25, .mx-xl-0\.25 { margin-left: 0.39045rem !important; }
  .m-xl-0\.5 { margin: 0.7809rem !important; }
  .mt-xl-0\.5, .my-xl-0\.5 { margin-top: 0.7809rem !important; }
  .mr-xl-0\.5, .mx-xl-0\.5 { margin-right: 0.7809rem !important; }
  .mb-xl-0\.5, .my-xl-0\.5 { margin-bottom: 0.7809rem !important; }
  .ml-xl-0\.5, .mx-xl-0\.5 { margin-left: 0.7809rem !important; }
  .m-xl-0\.75 { margin: 1.17135rem !important; }
  .mt-xl-0\.75, .my-xl-0\.75 { margin-top: 1.17135rem !important; }
  .mr-xl-0\.75, .mx-xl-0\.75 { margin-right: 1.17135rem !important; }
  .mb-xl-0\.75, .my-xl-0\.75 { margin-bottom: 1.17135rem !important; }
  .ml-xl-0\.75, .mx-xl-0\.75 { margin-left: 1.17135rem !important; }
  .m-xl-1\.25 { margin: 1.95225rem !important; }
  .mt-xl-1\.25, .my-xl-1\.25 { margin-top: 1.95225rem !important; }
  .mr-xl-1\.25, .mx-xl-1\.25 { margin-right: 1.95225rem !important; }
  .mb-xl-1\.25, .my-xl-1\.25 { margin-bottom: 1.95225rem !important; }
  .ml-xl-1\.25, .mx-xl-1\.25 { margin-left: 1.95225rem !important; }
  .m-xl-1\.5 { margin: 2.3427rem !important; }
  .mt-xl-1\.5, .my-xl-1\.5 { margin-top: 2.3427rem !important; }
  .mr-xl-1\.5, .mx-xl-1\.5 { margin-right: 2.3427rem !important; }
  .mb-xl-1\.5, .my-xl-1\.5 { margin-bottom: 2.3427rem !important; }
  .ml-xl-1\.5, .mx-xl-1\.5 { margin-left: 2.3427rem !important; }
  .m-xl-1\.75 { margin: 2.73315rem !important; }
  .mt-xl-1\.75, .my-xl-1\.75 { margin-top: 2.73315rem !important; }
  .mr-xl-1\.75, .mx-xl-1\.75 { margin-right: 2.73315rem !important; }
  .mb-xl-1\.75, .my-xl-1\.75 { margin-bottom: 2.73315rem !important; }
  .ml-xl-1\.75, .mx-xl-1\.75 { margin-left: 2.73315rem !important; }
  .m-xl-2\.5 { margin: 3.9045rem !important; }
  .mt-xl-2\.5, .my-xl-2\.5 { margin-top: 3.9045rem !important; }
  .mr-xl-2\.5, .mx-xl-2\.5 { margin-right: 3.9045rem !important; }
  .mb-xl-2\.5, .my-xl-2\.5 { margin-bottom: 3.9045rem !important; }
  .ml-xl-2\.5, .mx-xl-2\.5 { margin-left: 3.9045rem !important; }
  .m-xl-3\.5 { margin: 5.4663rem !important; }
  .mt-xl-3\.5, .my-xl-3\.5 { margin-top: 5.4663rem !important; }
  .mr-xl-3\.5, .mx-xl-3\.5 { margin-right: 5.4663rem !important; }
  .mb-xl-3\.5, .my-xl-3\.5 { margin-bottom: 5.4663rem !important; }
  .ml-xl-3\.5, .mx-xl-3\.5 { margin-left: 5.4663rem !important; }
  .m-xl-4\.5 { margin: 7.0281rem !important; }
  .mt-xl-4\.5, .my-xl-4\.5 { margin-top: 7.0281rem !important; }
  .mr-xl-4\.5, .mx-xl-4\.5 { margin-right: 7.0281rem !important; }
  .mb-xl-4\.5, .my-xl-4\.5 { margin-bottom: 7.0281rem !important; }
  .ml-xl-4\.5, .mx-xl-4\.5 { margin-left: 7.0281rem !important; }
  .m-xl-6 { margin: 9.3708rem !important; }
  .mt-xl-6, .my-xl-6 { margin-top: 9.3708rem !important; }
  .mr-xl-6, .mx-xl-6 { margin-right: 9.3708rem !important; }
  .mb-xl-6, .my-xl-6 { margin-bottom: 9.3708rem !important; }
  .ml-xl-6, .mx-xl-6 { margin-left: 9.3708rem !important; }
  .m-xl-7 { margin: 10.9326rem !important; }
  .mt-xl-7, .my-xl-7 { margin-top: 10.9326rem !important; }
  .mr-xl-7, .mx-xl-7 { margin-right: 10.9326rem !important; }
  .mb-xl-7, .my-xl-7 { margin-bottom: 10.9326rem !important; }
  .ml-xl-7, .mx-xl-7 { margin-left: 10.9326rem !important; }
  .m-xl-8 { margin: 12.4944rem !important; }
  .mt-xl-8, .my-xl-8 { margin-top: 12.4944rem !important; }
  .mr-xl-8, .mx-xl-8 { margin-right: 12.4944rem !important; }
  .mb-xl-8, .my-xl-8 { margin-bottom: 12.4944rem !important; }
  .ml-xl-8, .mx-xl-8 { margin-left: 12.4944rem !important; }
  .m-xl-9 { margin: 14.0562rem !important; }
  .mt-xl-9, .my-xl-9 { margin-top: 14.0562rem !important; }
  .mr-xl-9, .mx-xl-9 { margin-right: 14.0562rem !important; }
  .mb-xl-9, .my-xl-9 { margin-bottom: 14.0562rem !important; }
  .ml-xl-9, .mx-xl-9 { margin-left: 14.0562rem !important; }
  .m-xl-10 { margin: 15.618rem !important; }
  .mt-xl-10, .my-xl-10 { margin-top: 15.618rem !important; }
  .mr-xl-10, .mx-xl-10 { margin-right: 15.618rem !important; }
  .mb-xl-10, .my-xl-10 { margin-bottom: 15.618rem !important; }
  .ml-xl-10, .mx-xl-10 { margin-left: 15.618rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 1.5618rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 1.5618rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 1.5618rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 1.5618rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 1.5618rem !important; }
  .p-xl-2 { padding: 3.1236rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 3.1236rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 3.1236rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 3.1236rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 3.1236rem !important; }
  .p-xl-3 { padding: 4.6854rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 4.6854rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 4.6854rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 4.6854rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 4.6854rem !important; }
  .p-xl-4 { padding: 6.2472rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 6.2472rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 6.2472rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 6.2472rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 6.2472rem !important; }
  .p-xl-5 { padding: 7.809rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 7.809rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 7.809rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 7.809rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 7.809rem !important; }
  .p-xl-0\.125 { padding: 0.195225rem !important; }
  .pt-xl-0\.125, .py-xl-0\.125 { padding-top: 0.195225rem !important; }
  .pr-xl-0\.125, .px-xl-0\.125 { padding-right: 0.195225rem !important; }
  .pb-xl-0\.125, .py-xl-0\.125 { padding-bottom: 0.195225rem !important; }
  .pl-xl-0\.125, .px-xl-0\.125 { padding-left: 0.195225rem !important; }
  .p-xl-0\.25 { padding: 0.39045rem !important; }
  .pt-xl-0\.25, .py-xl-0\.25 { padding-top: 0.39045rem !important; }
  .pr-xl-0\.25, .px-xl-0\.25 { padding-right: 0.39045rem !important; }
  .pb-xl-0\.25, .py-xl-0\.25 { padding-bottom: 0.39045rem !important; }
  .pl-xl-0\.25, .px-xl-0\.25 { padding-left: 0.39045rem !important; }
  .p-xl-0\.5 { padding: 0.7809rem !important; }
  .pt-xl-0\.5, .py-xl-0\.5 { padding-top: 0.7809rem !important; }
  .pr-xl-0\.5, .px-xl-0\.5 { padding-right: 0.7809rem !important; }
  .pb-xl-0\.5, .py-xl-0\.5 { padding-bottom: 0.7809rem !important; }
  .pl-xl-0\.5, .px-xl-0\.5 { padding-left: 0.7809rem !important; }
  .p-xl-0\.75 { padding: 1.17135rem !important; }
  .pt-xl-0\.75, .py-xl-0\.75 { padding-top: 1.17135rem !important; }
  .pr-xl-0\.75, .px-xl-0\.75 { padding-right: 1.17135rem !important; }
  .pb-xl-0\.75, .py-xl-0\.75 { padding-bottom: 1.17135rem !important; }
  .pl-xl-0\.75, .px-xl-0\.75 { padding-left: 1.17135rem !important; }
  .p-xl-1\.25 { padding: 1.95225rem !important; }
  .pt-xl-1\.25, .py-xl-1\.25 { padding-top: 1.95225rem !important; }
  .pr-xl-1\.25, .px-xl-1\.25 { padding-right: 1.95225rem !important; }
  .pb-xl-1\.25, .py-xl-1\.25 { padding-bottom: 1.95225rem !important; }
  .pl-xl-1\.25, .px-xl-1\.25 { padding-left: 1.95225rem !important; }
  .p-xl-1\.5 { padding: 2.3427rem !important; }
  .pt-xl-1\.5, .py-xl-1\.5 { padding-top: 2.3427rem !important; }
  .pr-xl-1\.5, .px-xl-1\.5 { padding-right: 2.3427rem !important; }
  .pb-xl-1\.5, .py-xl-1\.5 { padding-bottom: 2.3427rem !important; }
  .pl-xl-1\.5, .px-xl-1\.5 { padding-left: 2.3427rem !important; }
  .p-xl-1\.75 { padding: 2.73315rem !important; }
  .pt-xl-1\.75, .py-xl-1\.75 { padding-top: 2.73315rem !important; }
  .pr-xl-1\.75, .px-xl-1\.75 { padding-right: 2.73315rem !important; }
  .pb-xl-1\.75, .py-xl-1\.75 { padding-bottom: 2.73315rem !important; }
  .pl-xl-1\.75, .px-xl-1\.75 { padding-left: 2.73315rem !important; }
  .p-xl-2\.5 { padding: 3.9045rem !important; }
  .pt-xl-2\.5, .py-xl-2\.5 { padding-top: 3.9045rem !important; }
  .pr-xl-2\.5, .px-xl-2\.5 { padding-right: 3.9045rem !important; }
  .pb-xl-2\.5, .py-xl-2\.5 { padding-bottom: 3.9045rem !important; }
  .pl-xl-2\.5, .px-xl-2\.5 { padding-left: 3.9045rem !important; }
  .p-xl-3\.5 { padding: 5.4663rem !important; }
  .pt-xl-3\.5, .py-xl-3\.5 { padding-top: 5.4663rem !important; }
  .pr-xl-3\.5, .px-xl-3\.5 { padding-right: 5.4663rem !important; }
  .pb-xl-3\.5, .py-xl-3\.5 { padding-bottom: 5.4663rem !important; }
  .pl-xl-3\.5, .px-xl-3\.5 { padding-left: 5.4663rem !important; }
  .p-xl-4\.5 { padding: 7.0281rem !important; }
  .pt-xl-4\.5, .py-xl-4\.5 { padding-top: 7.0281rem !important; }
  .pr-xl-4\.5, .px-xl-4\.5 { padding-right: 7.0281rem !important; }
  .pb-xl-4\.5, .py-xl-4\.5 { padding-bottom: 7.0281rem !important; }
  .pl-xl-4\.5, .px-xl-4\.5 { padding-left: 7.0281rem !important; }
  .p-xl-6 { padding: 9.3708rem !important; }
  .pt-xl-6, .py-xl-6 { padding-top: 9.3708rem !important; }
  .pr-xl-6, .px-xl-6 { padding-right: 9.3708rem !important; }
  .pb-xl-6, .py-xl-6 { padding-bottom: 9.3708rem !important; }
  .pl-xl-6, .px-xl-6 { padding-left: 9.3708rem !important; }
  .p-xl-7 { padding: 10.9326rem !important; }
  .pt-xl-7, .py-xl-7 { padding-top: 10.9326rem !important; }
  .pr-xl-7, .px-xl-7 { padding-right: 10.9326rem !important; }
  .pb-xl-7, .py-xl-7 { padding-bottom: 10.9326rem !important; }
  .pl-xl-7, .px-xl-7 { padding-left: 10.9326rem !important; }
  .p-xl-8 { padding: 12.4944rem !important; }
  .pt-xl-8, .py-xl-8 { padding-top: 12.4944rem !important; }
  .pr-xl-8, .px-xl-8 { padding-right: 12.4944rem !important; }
  .pb-xl-8, .py-xl-8 { padding-bottom: 12.4944rem !important; }
  .pl-xl-8, .px-xl-8 { padding-left: 12.4944rem !important; }
  .p-xl-9 { padding: 14.0562rem !important; }
  .pt-xl-9, .py-xl-9 { padding-top: 14.0562rem !important; }
  .pr-xl-9, .px-xl-9 { padding-right: 14.0562rem !important; }
  .pb-xl-9, .py-xl-9 { padding-bottom: 14.0562rem !important; }
  .pl-xl-9, .px-xl-9 { padding-left: 14.0562rem !important; }
  .p-xl-10 { padding: 15.618rem !important; }
  .pt-xl-10, .py-xl-10 { padding-top: 15.618rem !important; }
  .pr-xl-10, .px-xl-10 { padding-right: 15.618rem !important; }
  .pb-xl-10, .py-xl-10 { padding-bottom: 15.618rem !important; }
  .pl-xl-10, .px-xl-10 { padding-left: 15.618rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

.icon, form input[type="checkbox"] ~ label:before, .form-group.select:after, .form-group.calendar:after, .form-group.multicheckbox input[type="checkbox"] + span:before, .captcha #refresh-captcha > .icon-refresh, #FormTourOfItaly #destinations .destinations_item strong:before, #FormTourOfItaly #destinations .destinations_item input:checked ~ strong:before, #FormTourOfItaly #hotel_category strong:before, #FormTourOfItaly #hotel_category input:checked + div strong:before, #FormTourOfItaly .form-group.radio label.radio input[type="radio"] ~ span:before { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-vehicle:before { content: ""; }

.icon-boat-tour:before { content: ""; }

.icon-boat-vehicle:before { content: ""; }

.icon-vehicle-walking:before, .icon-vehicle-walking-tour:before { content: ""; }

.icon-walking-tour:before { content: ""; }

.icon-mixed-car-yacht:before { content: ""; }

.icon-my-cart:before { content: ""; }

.icon-cart2:before { content: ""; }

.icon-shopping-cart:before { content: ""; }

.icon-credit-cards:before { content: ""; }

.icon-comments:before { content: ""; }

.icon-clothes-label:before { content: ""; }

.icon-volume:before { content: ""; }

.icon-clock-filled:before { content: ""; }

.icon-anchor:before { content: ""; }

.icon-arrows:before { content: ""; }

.icon-baby-seat:before { content: ""; }

.icon-boat:before { content: ""; }

.icon-camera:before { content: ""; }

.icon-car:before { content: ""; }

.icon-cart:before { content: ""; }

.icon-child:before { content: ""; }

.icon-committee:before { content: ""; }

.icon-folded-calendar:before { content: ""; }

.icon-fridge:before { content: ""; }

.icon-gears:before { content: ""; }

.icon-arrow-down-scroll:before { content: ""; }

.icon-crociera:before { content: ""; }

.icon-globe:before { content: ""; }

.icon-graphic:before { content: ""; }

.icon-hand-shake:before { content: ""; }

.icon-headphones:before { content: ""; }

.icon-heart-in-hands:before { content: ""; }

.icon-hotels:before { content: ""; }

.icon-hours-delivery:before { content: ""; }

.icon-italia:before { content: ""; }

.icon-circle_check:before { content: ""; }

.icon-lens:before { content: ""; }

.icon-included:before { content: ""; }

.icon-list-check:before { content: ""; }

.icon-information:before { content: ""; }

.icon-calendar-grid:before { content: ""; }

.icon-local-guide:before { content: ""; }

.icon-magnifying-glass:before { content: ""; }

.icon-map-pin:before { content: ""; }

.icon-bell:before { content: ""; }

.icon-facebook2:before { content: ""; }

.icon-menu:before { content: ""; }

.icon-minus:before { content: ""; }

.icon-calendar-grid-round:before { content: ""; }

.icon-municipality:before { content: ""; }

.icon-not-included:before { content: ""; }

.icon-eye:before { content: ""; }

.icon-camera1:before { content: ""; }

.icon-check:before { content: ""; }

.icon-house-dollar:before { content: ""; }

.icon-operator:before { content: ""; }

.icon-circle-phone:before { content: ""; }

.icon-padlock-filled:before { content: ""; }

.icon-passenger:before { content: ""; }

.icon-person:before { content: ""; }

.icon-location:before { content: ""; }

.icon-phone-bookings:before { content: ""; }

.icon-arrow-down1:before { content: ""; }

.icon-arrow-left1:before { content: ""; }

.icon-arrow-right1:before { content: ""; }

.icon-arrow-up1:before { content: ""; }

.icon-bell-o:before { content: ""; }

.icon-bell-ring:before { content: ""; }

.icon-bell-ring-linear:before { content: ""; }

.icon-business-meeting:before { content: ""; }

.icon-call-linear:before { content: ""; }

.icon-cross:before { content: ""; }

.icon-pin:before { content: ""; }

.icon-close:before { content: ""; }

.icon-plane:before { content: ""; }

.icon-couple:before { content: ""; }

.icon-fax:before { content: ""; }

.icon-close2:before { content: ""; }

.icon-call:before { content: ""; }

.icon-group:before { content: ""; }

.icon-envelope:before { content: ""; }

.icon-calendar-checked:before { content: ""; }

.icon-facebook-logo-button:before { content: ""; }

.icon-instagram:before { content: ""; }

.icon-facebook:before { content: ""; }

.icon-familiar-group-of-three:before { content: ""; }

.icon-kid:before { content: ""; }

.icon-envelope-linear:before { content: ""; }

.icon-calendar:before { content: ""; }

.icon-plus1:before { content: ""; }

.icon-label:before { content: ""; }

.icon-family:before { content: ""; }

.icon-father-with-his-daughter-and-baby:before { content: ""; }

.icon-ports:before { content: ""; }

.icon-link-external:before { content: ""; }

.icon-garage:before { content: ""; }

.icon-left-quote:before { content: ""; }

.icon-man:before { content: ""; }

.icon-minivan:before { content: ""; }

.icon-minus1:before { content: ""; }

.icon-arrow-down:before { content: ""; }

.icon-arrow-left:before { content: ""; }

.icon-arrow-right:before { content: ""; }

.icon-arrow-up:before { content: ""; }

.icon-phone:before { content: ""; }

.icon-circle:before { content: ""; }

.icon-mother-with-son:before { content: ""; }

.icon-plus:before { content: ""; }

.icon-properties:before { content: ""; }

.icon-minus-circle:before { content: ""; }

.icon-plus-circle:before { content: ""; }

.icon-parking:before { content: ""; }

.icon-google-plus:before { content: ""; }

.icon-pushpin:before { content: ""; }

.icon-plus-square:before { content: ""; }

.icon-questions:before { content: ""; }

.icon-refresh:before { content: ""; }

.icon-refresh1:before { content: ""; }

.icon-right-quote:before { content: ""; }

.icon-clock:before { content: ""; }

.icon-send:before { content: ""; }

.icon-share:before { content: ""; }

.icon-spinner:before { content: ""; }

.icon-arrow-down-circle:before { content: ""; }

.icon-arrow-left-circle:before { content: ""; }

.icon-arrow-right-circle:before { content: ""; }

.icon-arrow-up-circle:before { content: ""; }

.icon-check-button:before { content: ""; }

.icon-legal-hammer:before { content: ""; }

.icon-dot-inside-a-circle:before { content: ""; }

.icon-foreign:before { content: ""; }

.icon-square:before { content: ""; }

.icon-quotes-left:before { content: ""; }

.icon-link:before { content: ""; }

.icon-bus:before { content: ""; }

.icon-quotes-right:before { content: ""; }

.icon-sun:before { content: ""; }

.icon-square-checked:before { content: ""; }

.icon-target-shooting:before { content: ""; }

.icon-circle-fill:before { content: ""; }

.icon-arrow-down2:before { content: ""; }

.icon-technology:before { content: ""; }

.icon-telephone:before { content: ""; }

.icon-circle1:before { content: ""; }

.icon-plus2:before { content: ""; }

.icon-photocopies:before { content: ""; }

.icon-telephone-linear:before { content: ""; }

.icon-tick:before { content: ""; }

.icon-yacht:before { content: ""; }

.icon-arrow-down-circle1:before { content: ""; }

.icon-arrow-left-circle1:before { content: ""; }

.icon-arrow-right-circle1:before { content: ""; }

.icon-check-button1:before { content: ""; }

.icon-foreign1:before { content: ""; }

.icon-double-angle-right:before { content: ""; }

.icon-circle-fill1:before { content: ""; }

.icon-tick-inside-circle:before { content: ""; }

.icon-train:before { content: ""; }

.icon-tripadvisor:before { content: ""; }

.icon-tripadvisor-text:before { content: ""; }

.icon-twitter:before { content: ""; }

.icon-uniE952:before { content: ""; }

.icon-uniE953:before { content: ""; }

.icon-user-circle:before { content: ""; }

.icon-user-shape:before { content: ""; }

.icon-van:before { content: ""; }

.icon-whatsapp:before { content: ""; }

.icon-zoom-in:before { content: ""; }

.icon-wifi:before { content: ""; }

@svg-load logo-placeholder  url(images/layout/logo-inverse.svg) { * { fill-opacity: 0.8; } }

@svg-load tripadvisor url(images/svg/logos/tripadvisor.svg) { .st0 { fill: #FFDE9D; stroke: #FFDE9D; stroke-miterlimit: 10; }
  .st1 { fill: #EC2227; }
  .st2 { fill: #00AF87; }
  .st3 { fill: #231F20; }
  .st4 { fill: #999999; stroke: #999999; stroke-miterlimit: 10; }
  .st5 { fill: #999999; }
  .st6 { fill: #333333; }
  .mark { display: none; } }

@svg-load instagram url(images/svg/logos/instagram.svg) {}@svg-load youtube url(images/svg/logos/youtube.svg) {}@svg-load flickr url(images/svg/logos/flickr.svg) {}@svg-load twitter url(images/svg/logos/twitter.svg) { * { fill: #1da1f2; } }

@svg-load facebook url(images/svg/logos/facebook.svg) { .color-fb { fill: #3b5998; } }

@svg-load enjoy-the-coast url(images/svg/logos/enjoy.svg) { #Sole { fill: #f0af30; }
  #Onda { fill: #0384bd; }
  #E { fill: #d55b47; }
  #N { fill: #f0af30; }
  #J { fill: #7fc24f; }
  #O { fill: #1e7ac0; }
  #Y { fill: #664199; } }

@svg-load caret url(images/svg/caret.svg) { #Riempimento { fill: #d5a574; }
  #Contorno { fill: #fff; fill-opacity: 0.75; } }

@svg-load flag_en url(images/svg/flags/united_kingdom.svg) { .fill-blue { fill: #00247d; }
  .stroke-white { stroke: #fff; }
  .stroke-red { stroke: #cf142b; } }

@svg-load flag_it url(images/svg/flags/vertical_3.svg) { #Left { fill: #009246; }
  #Center { fill: #fff; }
  #Right { fill: #cf2b37; } }

@svg-load flag_fr url(images/svg/flags/vertical_3.svg) { #Left { fill: #002495; }
  #Center { fill: #fff; }
  #Right { fill: #ed2939; } }

@svg-load flag_de url(images/svg/flags/horizontal_3.svg) { #Top { fill: black; }
  #Center { fill: #dd0f15; }
  #Bottom { fill: #ffce00; } }

@svg-load flag_es url(images/svg/flags/horizontal_3_1.svg) { #Top, #Bottom { fill: #c70c1f; }
  #Center { fill: #ffc400; } }

@svg-load airbnb-logotype url(images/svg/airbnb-logotype.svg) {}@svg-load booking_com-logotype url(images/svg/booking_com-logotype.svg) {}@svg-load expedia-logotype url(images/svg/expedia-logotype.svg) {}@svg-load web_site-logotype url(images/layout/logo.svg) {}@svg-load facebook-logotype url(images/svg/logos/facebook.svg) { .color-fb { fill: #3b5998; }
  .mark { display: none; } }

@svg-load tripadvisor-logotype url(images/svg/tripadvisor-logotype.svg) {}::selection { background: rgba(213, 165, 116, 0.925); color: white; }

.gallery-item-link:before, .instagramphotos--item--link:before, .yachts__gallery-blended .yacht-item-gallery .gallery-item-link:before { position: absolute; content: ""; display: block; }

.gallery-item-link:before, .instagramphotos--item--link:before, .yachts__gallery-blended .yacht-item-gallery .gallery-item-link:before { transition: all 0.25s; width: 100%; height: 100%; z-index: 1; top: 0; left: 0; }

.gallery-item-link:hover:before, .instagramphotos--item--link:hover:before, .yachts__gallery-blended .yacht-item-gallery .gallery-item-link:hover:before { background: rgba(255, 255, 255, 0.25); }

.gallery-item-zoom { visibility: hidden; color: #f9f2ea; transform: scale(0); position: absolute; top: 50%; left: 50%; line-height: 1; font-size: 6.25em; margin-top: -0.5em; margin-left: -0.5em; -webkit-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530); -moz-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530); -o-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530); transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530); -webkit-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530); -moz-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530); -ms-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530); -o-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530); transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530); z-index: 4; }

.svg-embed.gallery-item-zoom { width: 1em; height: 1em; }

.gallery-item-link:hover .gallery-item-zoom { transform: scale(1); visibility: visible; }

.trip_detail--photo .embed-responsive, .groupings .group-image, #FormTransferStops .transfer-stop--photo { background-color: #3a3d4d; background-image: svg-inline(logo-placeholder); background-position: 50% 50%; background-repeat: no-repeat; background-size: auto 70%; }

.flex.align-left, .inline-flex.align-left { justify-content: flex-start; }

.flex.align-right, .inline-flex.align-right { justify-content: flex-end; }

.flex.align-center, .inline-flex.align-center { justify-content: center; }

.flex.align-top, .inline-flex.align-top { align-items: flex-start; }

.flex.align-bottom, .inline-flex.align-bottom { align-items: flex-end; }

.flex.align-middle, .inline-flex.align-middle { align-items: center; }

.flex.align-justify, .inline-flex.align-justify { justify-content: space-between; }

.flex.align-spaced, .inline-flex.align-spaced { justify-content: space-around; }

.flex.column, .inline-flex.column { flex-direction: column; }

.flex.column.align-top, .inline-flex.column.align-top { justify-content: flex-start; align-items: stretch; }

.flex.column.align-bottom, .inline-flex.column.align-bottom { justify-content: flex-end; align-items: stretch; }

.flex.column.align-middle, .inline-flex.column.align-middle { justify-content: center; align-items: stretch; }

.flex.column.align-center, .inline-flex.column.align-center { align-items: center; }

.flex.column.align-left, .inline-flex.column.align-left { align-items: flex-start; }

.flex.column.align-right, .inline-flex.column.align-right { align-items: flex-end; }

.flex.column.reverse, .inline-flex.column.reverse { flex-direction: column-reverse; }

.flex.column.reverse.align-top, .inline-flex.column.reverse.align-top { justify-content: flex-end; }

.flex.column.reverse.align-bottom, .inline-flex.column.reverse.align-bottom { justify-content: flex-start; }

.flex.reverse, .inline-flex.reverse { flex-direction: row-reverse; }

.flex.reverse.align-left, .inline-flex.reverse.align-left { justify-content: flex-end; }

.flex.reverse.align-right, .inline-flex.reverse.align-right { justify-content: flex-start; }

.flex.wrap, .inline-flex.wrap { flex-wrap: wrap; }

.flex.wrap-reverse, .inline-flex.wrap-reverse { flex-wrap: wrap-reverse; }

.flex { display: flex; }

.inline-flex { display: inline-flex; }

body { flex-direction: column; }

body > pre { flex: 0 0 auto; }

body, .vegas-container .navigation { display: flex; }

.flexible { flex: 1 1; }

.inflexible { flex: 0; flex-shrink: 0; }

.growable { flex: 1 0; }

.shrinkable { flex: 0 1; }

.relative.flexible, .relative.inflexible, .relative.growable, .relative.shrinkable { flex-basis: auto; }

.absolute.flexible, .absolute.inflexible, .absolute.growable, .absolute.shrinkable { flex-basis: 0; }

@keyframes scrolltocontent { 0% { transform: translateY(0); }
  20% { transform: translateY(0); }
  50% { transform: translateY(0); }
  80% { transform: translateY(0); }
  100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); } }

@keyframes flip { 0% { transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% { transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% { transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% { transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  100% { transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in; } }

@keyframes spin { from { transform: rotate(0deg); }
  to { transform: rotate(359deg); } }

@keyframes slide-right { from { transform: translateX(-50%);
    opacity: 0; }
  to { transform: translateX(0%);
    opacity: 1; } }

@keyframes slide-left { from { transform: translateX(50%);
    opacity: 0; }
  to { transform: translateX(0%);
    opacity: 1; } }

@keyframes slide-bottom { from { transform: translateY(-50%);
    opacity: 0; }
  to { transform: translateY(0%);
    opacity: 1; } }

@keyframes slide-top { from { transform: translateY(50%);
    opacity: 0; }
  to { transform: translateY(0%);
    opacity: 1; } }

@keyframes slideInDown { from { transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to { transform: translate3d(0, 0, 0); } }

@keyframes slideInUp { from { transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to { transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft { from { transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to { transform: translate3d(0, 0, 0); } }

@keyframes slideInRight { from { transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to { transform: translate3d(0, 0, 0); } }

@keyframes flipInX { from { transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% { transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% { transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% { transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to { transform: perspective(400px); } }

@keyframes slideInRight { from { transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to { transform: translate3d(0, 0, 0); } }

@keyframes flipInY { from { transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% { transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in; }
  60% { transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% { transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to { transform: perspective(400px); } }

@keyframes fadeOut { from { opacity: 0; }
  to { opacity: 1; } }

@keyframes foldingLeft { from { opacity: 0;
    transform: skewY(20deg) scale(0.5); }
  to { opacity: 1;
    transform: skewY(0deg) scale(1); } }

@keyframes foldingRight { from { opacity: 0;
    transform: skewY(-20deg) scale(0.5); }
  to { opacity: 1;
    transform: skewY(0deg) scale(1); } }

@keyframes rotateLeft { from { opacity: 0;
    transform: rotateY(18deg); }
  to { opacity: 1;
    transform: rotateY(0deg); } }

@keyframes rotateRight { from { opacity: 0;
    transform: rotateY(-18deg); }
  to { opacity: 1;
    transform: rotateY(0deg); } }

@keyframes sepia { from { filter: sepia(1); }
  to { filter: sepia(0); } }

@keyframes blur { from { filter: blur(5px); }
  to { filter: blur(0px); } }

@keyframes blinker { 50% { opacity: 0; } }

@keyframes pulse { from { transform: perspective(100px) translateZ(25px); }
  to { transform: perspective(0px) translateZ(0px); } }

.stretch, .fluid, .new-modal.modal-dialog { width: 100%; }

.fluid { margin-left: auto; margin-right: auto; }

@media (min-width: 1200px) { .fluid.__xl, .fluid .__xl { max-width: 1080px; } }

@media (min-width: 1400px) { .fluid.__2xl, .fluid .__2xl { max-width: 1260px; } }

@media (min-width: 1600px) { .fluid.__3xl, .fluid .__3xl { max-width: 1440px; } }

@media (min-width: 1800px) { .fluid.__4xl, .fluid .__4xl { max-width: 1620px; } }

@media (min-width: 2000px) { .fluid.__5xl, .fluid .__5xl { max-width: 1800px; } }

.fluid--infinite-width { max-width: none !important; }

.content.__infinite, .fluid.__infinite { width: 100%; max-width: none; }

.opacity-0 { opacity: 0; }

.opacity-10 { opacity: 0.1; }

.opacity-20 { opacity: 0.2; }

.opacity-30 { opacity: 0.3; }

.opacity-40 { opacity: 0.4; }

.opacity-50 { opacity: 0.5; }

.opacity-60 { opacity: 0.6; }

.opacity-70 { opacity: 0.7; }

.opacity-80 { opacity: 0.8; }

.opacity-90 { opacity: 0.9; }

.opacity-100 { opacity: 1; }

.endesia-validator .valid { box-shadow: 0 0 0px 4px rgba(225, 10, 10, 0), inset 0px 0px 0px 1px rgba(225, 10, 10, 0) !important; }

.endesia-validator .has-error input[type="radio"], .endesia-validator .has-error input[type="checkbox"], .endesia-validator .has-error.radio input[type="radio"] ~ label:before, .endesia-validator .has-error.checkbox input[type="checkbox"] ~ label:before, .endesia-validator .error:not(.help-block), .flex-form .form-group .form-control:focus:invalid { border-color: rgba(225, 10, 10, 0.5) !important; box-shadow: 0 0 0px 4px rgba(225, 10, 10, 0.125), inset 0px 0px 0px 1px rgba(225, 10, 10, 0.5) !important; }

form :invalid { box-shadow: none; }

form { margin-left: auto; margin-right: auto; }

form legend { color: #d5a574; margin-top: 1rem; border-bottom: solid 1px currentColor; }

form input[type="text"], form textarea { appearance: none; }

form input[type="checkbox"] { clip: rect(0px, 0px, 0px, 0px); width: 1px; height: 1px; display: block !important; position: absolute; z-index: -1; top: 0px; left: 0px; }

form input[type="checkbox"] ~ label { display: inline-block; align-items: baseline; margin-bottom: 0; line-height: 1.5; padding-left: 30px !important; }

form input[type="checkbox"] ~ label > * { flex: 0 0 auto; }

form input[type="checkbox"] ~ label:before { content: ""; margin-right: 0.5ch; cursor: pointer; margin-top: 1px; position: relative; z-index: 10; font-size: 18px; position: absolute; text-align: 9px; top: 0.25ex; left: 0; }

form input[type="checkbox"] ~ label a { text-decoration: underline; }

form input[type="checkbox"]:checked ~ label:before { content: ""; }

form .has-error { flex-wrap: wrap; }

form .has-error .help-block { flex: 0 0 100%; order: 100; width: 100%; display: block; font-size: 0.9em; color: #900; }

.form-group { position: relative; }

.form-group.select:after { content: ""; color: currentColor; font-size: 1.25em; position: absolute; display: inline; line-height: 1; top: 50%; margin-top: -0.5em; right: 0.75rem; pointer-events: none; }

.form-group.select select { outline: none; appearance: none; }

.form-group.select select > option[value=""] { display: none; }

.form-group.select select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

.form-group.checkbox, .form-group.radio { display: flex; align-items: center; margin-top: 1rem; }

.form-group.checkbox label, .form-group.radio label { padding-left: 0; }

.form-group.checkbox label a, .form-group.radio label a { color: inherit; }

.form-group.checkbox input[type="checkbox"], .form-group.checkbox input[type="radio"], .form-group.radio input[type="checkbox"], .form-group.radio input[type="radio"] { order: -1; margin: 0; }

.form-group.calendar:after { content: ""; color: currentColor; font-size: 1.25em; position: absolute; display: inline; line-height: 1; top: 50%; margin-top: -0.5em; right: 0.75rem; pointer-events: none; }

.form-group.calendar.checked:after { content: ""; }

.form-group.submit-send { text-align: center; }

.form-group.multicheckbox { margin-right: 0.5em; display: flex; flex-wrap: wrap; }

.form-group.multicheckbox label.checkbox { display: flex; align-items: center; }

.form-group.multicheckbox label.checkbox ~ label.checkbox { margin-left: calc(1.5618rem + 1ch); }

.form-group.multicheckbox label:not(.checkbox) { width: 100%; color: #d5a574; }

.form-group.multicheckbox input[type="checkbox"] { display: none; }

.form-group.multicheckbox input[type="checkbox"] + span:before { content: ""; content: ""; margin-right: 0.7809rem; cursor: pointer; }

.form-group.multicheckbox input[type="checkbox"]:checked + span:before { content: ""; }

.form-control { background-color: #F9F9FB; border-color: #e0e0e2; }

.form-control::placeholder { color: #a9abb3; opacity: 1; }

.form-control ~ label { position: absolute; color: #a9abb3; z-index: 51; top: 50%; left: 0; font-weight: normal; pointer-events: none; transform: translate(0.75rem, -50%); transform-origin: 0 0; transition: 0.15s; transition-timing-function: ease-in-out; transition-property: all; font-size: 0.9em; }

@media (min-width: 992px) { .form-control ~ label { font-size: 1em; } }

.form-control:focus ~ label, .form-group.filled .form-control ~ label { line-height: 1.6; font-size: 11.5px; font-weight: bold; color: #d5a574; top: 0%; transform: translate(0, -100%); transition-timing-function: ease-in; }

.form-group.active .form-control:-ms-input-placeholder { opacity: 0; color: rgba(0, 0, 0, 0); }

.form-group.active .form-control:-moz-placeholder { opacity: 0; color: rgba(0, 0, 0, 0); }

.form-group.active .form-control::-moz-placeholder { opacity: 0; color: rgba(0, 0, 0, 0); }

.form-group.active .form-control::-webkit-input-placeholder { opacity: 0; color: rgba(0, 0, 0, 0); }

.form__display-group { display: flex; flex-wrap: wrap; margin-bottom: 1rem; }

@media (min-width: 992px) { .form__display-group { margin-right: -1.5618rem; } }

.form__display-group .form-group { flex: 1 1 100%; margin-top: 1rem; }

@media (min-width: 992px) { .form__display-group .form-group { margin-right: 1.5618rem; } }

.form__display-group .form-group label { margin-bottom: 1px; }

.form__display-group .form-group.file > .btn { flex: 1 1 auto; }

.form__display-group--2col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--2col .form-group { flex-shrink: 0; flex-basis: 50%; max-width: calc(50% - 1.5618rem); } }

.form__display-group--3col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--3col .form-group { flex-shrink: 0; flex-basis: 33.3333333333%; max-width: calc(33.3333333333% - 1.5618rem); } }

.form__display-group--4col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--4col .form-group { flex-shrink: 0; flex-basis: 25%; max-width: calc(25% - 1.5618rem); } }

.form__display-group--5col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--5col .form-group { flex-shrink: 0; flex-basis: 20%; max-width: calc(20% - 1.5618rem); } }

.form__display-group--6col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--6col .form-group { flex-shrink: 0; flex-basis: 16.6666666667%; max-width: calc(16.6666666667% - 1.5618rem); } }

.form__display-group--7col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--7col .form-group { flex-shrink: 0; flex-basis: 14.2857142857%; max-width: calc(14.2857142857% - 1.5618rem); } }

.form__display-group--8col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--8col .form-group { flex-shrink: 0; flex-basis: 12.5%; max-width: calc(12.5% - 1.5618rem); } }

.form__display-group--9col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--9col .form-group { flex-shrink: 0; flex-basis: 11.1111111111%; max-width: calc(11.1111111111% - 1.5618rem); } }

.form__display-group--10col .form-group { flex-basis: 100%; }

@media (min-width: 992px) { .form__display-group--10col .form-group { flex-shrink: 0; flex-basis: 10%; max-width: calc(10% - 1.5618rem); } }

.form-actions { font-size: 1.125em; text-align: center; text-transform: uppercase; margin: 1.5rem 0; }

.form-actions .btn { min-width: 12ch; }

textarea.form-control ~ label { display: none !important; }

.footer-form { max-width: 1110px; margin-left: auto; margin-top: 2em; margin-right: auto; font-size: 0.9em; }

.footer-form p { margin: 0; }

.captcha { min-height: 45px; display: flex; align-items: center; }

.captcha label { margin-bottom: 0; order: -1; width: auto; margin-bottom: 0; }

.captcha #captcha-input { width: 15%; min-width: 8ch; max-width: 160px; text-align: center; font-family: courier, monospace; line-height: 1.3075; letter-spacing: 0.5ch; color: #111; }

.captcha #refresh-captcha { display: table-cell; vertical-align: middle; }

.captcha #refresh-captcha > .icon-refresh { display: block; }

.captcha .refresh { cursor: pointer; color: #d5a574; margin-left: 1ch; display: inline-table; line-height: 1; }

.captcha .refresh .icon-refresh { font-size: 1.5em; }

.primary .captcha .refresh { color: #d5a574; }

.bananagold .captcha .refresh { color: #d5a574; }

.lavagna .captcha .refresh { color: #404455; }

.light_grey .captcha .refresh { color: #D6D6D6; }

.secondary .captcha .refresh { color: #6c757d; }

.success .captcha .refresh { color: #28a745; }

.info .captcha .refresh { color: #17a2b8; }

.warning .captcha .refresh { color: #ffc107; }

.danger .captcha .refresh { color: #dc3545; }

.light .captcha .refresh { color: #f8f9fa; }

.dark .captcha .refresh { color: #343a40; }

.captcha + * { clear: both; }

.captcha .captcha-image { position: relative; display: block; width: 120px; height: 40px; }

.captcha .captcha-image img { max-width: 100%; max-height: 100%; }

.input-group.date .form-control[readonly] { background: #fff; cursor: pointer; }

.ui-autocomplete { z-index: 3 !important; }

.flex-form .form-group .form-control { font-size: inherit; }

.flex-form .form-group.textarea textarea { height: auto; }

.flex-form .form-group.captcha { font-size: 1.125em; padding-top: 1rem; }

.datepicker[readonly], .input-daterange input[readonly] { background-color: #fff; }

p.legenda-campi-richiesti { font-size: 0.90625em; margin: 1.5618rem 0; text-align: right; }

#gre_container { margin-top: 1rem; margin-bottom: 2rem; }

.svg-facebook-logotype.svg-embed:before { display: inline-block; content: ""; background: none center no-repeat; background-size: contain; background-image: svg-inline(facebook-logotype); }

.svg-tripadvisor-logotype.svg-embed:before { display: inline-block; content: ""; background: none center no-repeat; background-size: contain; background-image: svg-inline(tripadvisor-logotype); }

.svg-web_site-logotype.svg-embed:before { display: inline-block; content: ""; background: none center no-repeat; background-size: contain; background-image: svg-inline(web_site-logotype); }

.svg-embed { position: relative; display: inline-block; font-size: 1rem; height: 1em; line-height: 1; }

.svg-embed:before { width: 1em; height: 1em; }

.vegas-container .navigation { position: absolute; width: 100%; }

.vegas-container .navigation .nav-item { width: 1em; height: 1em; text-align: center; margin: 0.25em 0.125em; color: #fff; cursor: pointer; }

#SliderWrapper { width: 100%; min-height: 300px; position: relative; z-index: 1; }

@media (orientation: portrait) and (min-height: 1493.9309057px) { #SliderWrapper { height: 20.08125vh; } }

@media (orientation: landscape) and (min-height: 840.336134454px) { #SliderWrapper { height: 35.7vh; } }

.modal { height: 100vh; overflow: hidden; }

.new-modal.modal-dialog { height: inherit; max-width: none; padding-left: 5%; padding-right: 5%; margin: 0 auto; }

.new-modal.modal-dialog .scroll-content { pointer-events: auto; border-top: solid 6vh transparent; border-bottom: solid 6vh transparent; }

.new-modal.modal-dialog .new-modal-content { position: relative; max-width: 1200px; background: #fff; margin: 1.5618rem auto; padding: 1.5618rem; padding-top: 2.5rem; box-shadow: 0 0.5rem 1.5rem -0.5rem rgba(0, 0, 0, 0.5); }

@media (min-width: 1400px) { .new-modal.modal-dialog .new-modal-content { padding: 3.1236rem; padding-top: 4.0618rem; } }

.new-modal.modal-dialog .new-modal-content > .close { display: block !important; }

.new-modal.modal-dialog .new-modal-content > .close { font-size: inherit; position: absolute; top: 0; right: 0; width: 2.5rem; height: 2.5rem; background: #d5a574; opacity: 1; }

.new-modal.modal-dialog .new-modal-content > .close span { display: inline-block; text-indent: -999rem; }

.new-modal.modal-dialog .new-modal-content > .close:hover { background: #c27f3b; }

.new-modal.modal-dialog .new-modal-content > .close:before { content: svg-load("images/svg/close.svg", fill=white); position: absolute; top: 20%; left: 20%; width: 60%; height: 60%; pointer-events: none; }

#cookieChoiceInfo:before { content: ""; display: inline-block; background: svg-load("images/svg/cookiechoices_icon.svg") no-repeat; background-size: cover; width: 32px; height: 32px; float: left; margin-top: 0.75em; margin-right: 1em; }

@-webkit-viewport { width: device-width; }

@-moz-viewport { width: device-width; }

@-ms-viewport { width: device-width; }

@-o-viewport { width: device-width; }

@viewport { width: device-width; }

:focus { outline: none; }

html { font-size: 14px; }

@media (min-width: 576px) { html { font-size: 16px; } }

@media (min-width: 768px) { html { font-size: 14px; } }

@media (min-width: 1200px) { html { font-size: 16px; } }

html, body { min-height: 100vh; }

body _:-ms-lang(x), body _:-webkit-full-screen, body { max-width: 100vw; overflow-x: hidden; }

.fade.lazy-loaded { opacity: 1; }

main { flex: 1 0 auto; }

figure { margin: 0; }

h1, .h1, #SliderWrapper .slider--header--title { font-size: 1.59375rem; line-height: 1.40562; }

@media (min-width: 992px) { h1, .h1, #SliderWrapper .slider--header--title { font-size: 2.125rem; line-height: inherit; } }

h2, .h2 { font-size: 1.249188945rem; line-height: 1.40562; }

@media (min-width: 992px) { h2, .h2 { font-size: 1.66558526rem; line-height: inherit; } }

h3, .h3 { font-size: 1.0944221869rem; line-height: 1.40562; }

@media (min-width: 992px) { h3, .h3 { font-size: 1.4592295825rem; line-height: inherit; } }

h4, .h4 { font-size: 0.9059893157rem; line-height: 1.40562; }

@media (min-width: 992px) { h4, .h4 { font-size: 1.2079857543rem; line-height: inherit; } }

p + ul, p + ol { margin-bottom: 0.7809rem; }

p:last-child { margin-bottom: 0; }

.lead > strong { font-weight: bold; }

.d-contents { display: contents; }

@media (min-width: 576px) { .d-sm-contents { display: contents; } }

@media (min-width: 768px) { .d-md-contents { display: contents; } }

@media (min-width: 992px) { .d-lg-contents { display: contents; } }

.btn { border-radius: 3px; color: #fff; font-family: 'TraPro-Bold', serif; box-shadow: none; display: inline-flex; justify-content: center; align-items: center; }

.btn > span { display: inline-block; padding-top: 0.3ex; }

.btn:focus { box-shadow: inherit; }

.btn.loading { position: relative; cursor: progress !important; }

.btn.loading[disabled] { opacity: 1; }

.btn.loading:after { content: ""; position: absolute; top: 0; left: 0px; width: 100%; height: 100%; background-color: inherit; background-image: url(/assets/images/loaders/three-dots.svg); background-position: 50% 50%; background-repeat: no-repeat; background-size: auto 1.25ex; }

.form-actions .btn { font-size: calc(1rem + 3px); padding-top: 0.4rem; padding-bottom: 0.55rem; }

.section-header + .section-subheader + p { margin-top: 0.7809rem; }

.section-subheader { color: #535766; font-family: 'MontRat', sans-serif; }

.section-default, .section-transfers { padding-top: 2.3427rem; padding-bottom: 2.3427rem; }

@media (min-width: 1200px) { .section-default, .section-transfers { padding-top: 3.1236rem; padding-bottom: 3.1236rem; } }

#modal-page, #modal-form { padding-top: 1rem; }

#modal-page .new-modal.modal-dialog .new-modal-content, #modal-form .new-modal.modal-dialog .new-modal-content { box-shadow: 0 0 25px rgba(0, 0, 0, 0.5); }

.alert { padding-top: 2rem; padding-bottom: 2rem; border-width: 1px; border-left-width: 3rem; border-radius: 0; position: relative; }

.alert:last-child { margin-bottom: 0; }

.alert:before { position: absolute; top: 0px; left: 0px; font-family: 'icomoon' !important; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; color: #fff; margin-left: -3rem; font-size: 1.75rem; width: 3rem; height: 100%; display: flex; align-items: center; justify-content: center; }

.alert-success { background-color: rgba(165, 221, 134, 0.1); border-color: #a5dd86; color: #749b5e; }

.alert-success:before { content: ""; }

.alert-danger, .alert-error, #FormTourOfItaly #destinations .selection-required .error, #FormTourOfItaly #hotelcategory .selection-required .error, #FormTourOfItaly #rooms .selection-required .error, #FormBookNow #fieldset-payment_mode + .errors { background-color: rgba(242, 116, 116, 0.1); border-color: #f27474; color: #da6868; }

.alert-danger:before, .alert-error:before, #FormTourOfItaly #destinations .selection-required .error:before, #FormTourOfItaly #hotelcategory .selection-required .error:before, #FormTourOfItaly #rooms .selection-required .error:before, #FormBookNow #fieldset-payment_mode + .errors:before { content: ""; }

.alert-warning { background-color: rgba(248, 187, 134, 0.1); border-color: #f8bb86; color: #dfa879; }

.alert-info { background-color: rgba(63, 195, 238, 0.1); border-color: #3fc3ee; color: #39b0d6; }

form, form * { scroll-margin-top: 160px; }

@media (min-width: 992px) { form, form * { scroll-margin-top: 200px; } }

form .form-group { margin-bottom: 5px; }

form .form-group .form-control + label { background: rgba(255, 255, 255, 0); }

form .form-group.filled .form-control, form .form-group .form-control:focus, form .form-group .form-control:active { border-color: #535766; background: #fff; }

form .form-group.filled .form-control ~ label, form .form-group .form-control:focus ~ label, form .form-group .form-control:active ~ label { left: -0.5ch; color: #404455; transform: translate(0.75rem, -50%); background: white; font-weight: 500; padding: 0 0.5ch; }

form .form-control:focus, form .form-control:active { box-shadow: none !important; }

form select.form-control:not([size]):not([multiple]) { height: auto; max-height: calc(100% - 2px); }

#blueimp-gallery { box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5); }

#blueimp-gallery .icon-close { font-size: 2rem; font-weight: 900; }

#blueimp-gallery .blueimp-gallery > .slides > .slide > .slide-content { border: calc(50px + 1vh) solid transparent; }

.content { padding-left: 1.5618rem; padding-right: 1.5618rem; }

@media (min-width: 992px) { .content { padding-left: calc(1.5618rem + 1vw); padding-right: calc(1.5618rem + 1vw); } }

@media (min-width: 1600px) { .content { padding-left: 1.5618rem; padding-right: 1.5618rem; } }

/* Vue transitions slideup/down */
.fade-enter-active, .fade-leave-active { transition: opacity .3s ease-in-out; }

.fade-enter, .fade-leave-to { opacity: 0; }

.slide-enter-active, .slide-leave-active { transform-origin: 50% 0; transform-style: preserve-3d; transition-delay: 0s; transition: all 0.26s cubic-bezier(0.165, 0.840, 0.440, 1.000); }

.slide-enter-to, .slide-leave { transform: scale3d(1, 1, 1); overflow: hidden; max-height: 1000px; }

.slide-enter, .slide-leave-to { transform: scale3d(1, 0, 1); overflow: hidden; max-height: 0; }

html.no-js #loader-wrapper { display: none; }

#loader-wrapper { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; opacity: 1; }

#loader-wrapper .loader-section { position: fixed; top: 0; width: 51%; height: 100%; background: #fff; z-index: 1000; }

#loader-wrapper .loader-section.section-left { left: 0; }

.body-homepage.loaded #loader-wrapper .loader-section.section-left { transform: translateX(-100%); transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

#loader-wrapper .loader-section.section-right { right: 0; }

.body-homepage.loaded #loader-wrapper .loader-section.section-right { transform: translateX(100%); transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.body-homepage.loaded #loader-wrapper { visibility: hidden; transform: translateY(-100%); transition: all 0.3s 1s ease-out; }

:not(.body-homepage).loaded #loader-wrapper { opacity: 0; transform: translateY(-100%); transition: opacity 0.2s 0s ease-out, transform 0s 0.2s linear; }

#loader { display: block; position: relative; left: 50%; top: 50%; width: 150px; height: 150px; margin: -75px 0 0 -75px; border-radius: 50%; border: 3px solid transparent; border-top-color: #d5a574; animation: loader-spin 2s linear infinite; z-index: 1001; }

#loader:before { content: ""; position: absolute; top: 5px; left: 5px; right: 5px; bottom: 5px; border-radius: 50%; border: 3px solid transparent; border-top-color: #404455; animation: loader-spin 3s linear infinite; }

#loader:after { content: ""; position: absolute; top: 15px; left: 15px; right: 15px; bottom: 15px; border-radius: 50%; border: 3px solid transparent; border-top-color: #d5a574; animation: loader-spin 1.5s linear infinite; }

.body-homepage.loaded #loader { opacity: 0; transition: all 0.3s ease-out; }

@keyframes loader-spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.lightbox-container .lightbox-bg { background: #292C38; }

#Footer .footer-list .link-list--item a:before, .body-transfers #SliderWrapper .slider--content .intro-transfers p:not(:first-child):before { flex: 0 0 2ex; content: ""; width: 2ex; min-width: calc(2ex + 0.1px); max-width: 2ex; height: 2ex; border-radius: 100%; background: #d5a574; margin-right: 1ch; }

.icon.icon-type-tour, .icon.icon-type-cruise, .icon.icon-type-boat, .icon.icon-type-transfer, .icon.icon-type-location, .icon.icon-type-contacts, .icon.icon-type-vehicle, .icon.icon-type-calendar, .icon.icon-type-info, .icon.icon-type-people, .icon.icon-type-phone, .icon.icon-type-tickets, .icon.icon-type-directions, .icon.icon-type-guide { background-position: 50% 50%; background-repeat: no-repeat; }

.icon.icon-type-tour { background-image: url(/assets/images/svg/icons/type-tour.svg); }

.icon.icon-type-cruise { background-image: url(/assets/images/svg/icons/type-cruise.svg); }

.icon.icon-type-boat { background-image: url(/assets/images/svg/icons/type-boat.svg); }

.icon.icon-type-transfer { background-image: url(/assets/images/svg/icons/type-transfer.svg); }

.icon.icon-type-location { background-image: url(/assets/images/svg/icons/location.svg); }

.icon.icon-type-contacts { background-image: url(/assets/images/svg/icons/email.svg); }

.icon.icon-type-vehicle { background-image: url(/assets/images/svg/icons/vehicle.svg); }

.icon.icon-type-calendar { background-image: url(/assets/images/svg/icons/calendar.svg); }

.icon.icon-type-info { background-image: url(/assets/images/svg/icons/info.svg); }

.icon.icon-type-people { background-image: url(/assets/images/svg/icons/people.svg); }

.icon.icon-type-phone { background-image: url(/assets/images/svg/icons/phone.svg); }

.icon.icon-type-tickets { background-image: url(/assets/images/svg/icons/tickets.svg); }

.icon.icon-type-directions { background-image: url(/assets/images/svg/icons/type-directions.svg); }

.icon.icon-type-guide { background-image: url(/assets/images/svg/icons/type-guide.svg); }

.swal2-container .swal2-icon { font-size: 0.85rem; }

.swal2-container .swal2-icon.swal2-info { border-color: #d5a574; color: #d5a574; }

.swal2-container .swal2-title { font-size: 1.8rem; }

.swal2-container .swal2-content { font-size: 1rem; }

.swal2-container .swal2-close { outline: none; }

.swal2-container .swal2-actions .swal2-styled { font-family: 'TraPro-Bold', serif; color: #fff; background-color: #d5a574; border-color: #d5a574; }

.swal2-container .swal2-actions .swal2-styled[style="border-left-color"] { border-left-color: inherit !important; }

.swal2-container .swal2-actions .swal2-styled[style="border-right-color"] { border-left-color: inherit !important; }

#NewRequest { scroll-margin-top: 160px; }

@media (min-width: 992px) { #NewRequest { scroll-margin-top: 200px; } }

.jblbooking--tour-services--item .service--info:before, #TransferBookingSolution .solutions-item--vehicledetail--info:before { content: ""; display: inline-block; width: 1em; height: 1em; background: url(/assets/images/svg/info.svg) 50% 50% no-repeat; background-size: contain; }

#PopUp.page-popup { max-width: 640px; }

#PopUp.page-popup img { width: auto !important; max-width: 100% !important; height: auto !important; }

@media (max-width: 767px) { .section-default.section-covid-19 img { float: none !important; margin: 0 0 1rem 0 !important; max-width: 100% !important; height: auto !important; } }

@media (min-width: 992px) and (max-width: 1199px) { #menu .nav-wrapper .nav-item__link { font-size: 0.875rem; } }

@media (min-width: 1200px) and (max-width: 1599px) { #menu .nav-wrapper .nav-item__link { font-size: 0.9rem; } }

#Top { flex: 0 0 auto; position: relative; top: 0; left: 0; background: white; z-index: 100; flex: 0 0 auto; min-height: fit-content; width: 100%; box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0); transition: all 0.25s ease-out; transition-delay: 0ms; }

#Top.headroom--not-top { box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.25); }

@supports (position: sticky) { #Top.headroom--not-top { position: sticky; } }

@media (max-width: 991px) { #Top.scrolling-down { transform: translateY(-100%); transition-delay: 0.1s; } }

@media (min-width: 992px) { #Top { padding: 1rem 0; transition: none; visibility: visible; transition-delay: 100ms; transform: translateY(0%); /* disattivano - come richiesto da report seo deve essere sempre visibile .body-homepage &{ position: fixed; visibility: hidden; transform: translateY(-100%); min-height: min-intrinsic; } &.headroom--not-top { transition-timing-function: ease-in; visibility: visible; transition-delay: 100ms; .body-homepage &{ position: fixed; transform: translateY(0%); min-height: min-intrinsic; } } */ } }

#Top .content.fluid { justify-content: center; }

@support (justify-content: space-evenly) { #Top .content.fluid { justify-content: space-evenly; } }

@media (max-width: 991px) { #Top .content.fluid { padding: 0; } }

@media (min-width: 992px) and (max-width: 1199px) { #Top .content.fluid { padding-left: 1rem; padding-right: 1rem; } }

.logo__heading { margin: 0; flex: 0 0 48px; position: relative; text-align: center; margin-bottom: -2px; }

.logo__heading img { object-fit: contain; width: 48px; height: 76px; }

@media (min-width: 992px) and (max-width: 1199px) { .logo__heading { flex-basis: 38px; } }

.logo__heading-img { object-fit: contain; width: 200px; height: 76px; }

@media (min-width: 992px) { .logo__heading-img { width: auto; } }

.logo__heading-img svg { width: 100%; height: 100%; }

.logo__heading-img svg #logomark { transform: scale(0.7); transform-origin: 50% 30%; }

@media (min-width: 992px) { .logo__heading-img svg #logomark { transform: scale(3); transform-origin: 50% 25%; } }

.logo__heading-img svg #lettering { transform: scale(1.5); transform-origin: 50% 115%; }

@media (min-width: 992px) { .logo__heading-img svg #lettering { display: none; } }

#shopping-cart { flex: 0 0 auto; display: flex; justify-content: center; align-items: center; text-transform: uppercase; padding: 0; cursor: pointer; perspective: 300px; transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000); color: #d5a574; position: absolute; top: 0; right: 1rem; padding: 0 20px; height: 100%; }

@media (min-width: 992px) { #shopping-cart { position: relative; padding: 0; height: auto; right: 0; } }

#shopping-cart .icon-button { position: relative; color: #d5a574; letter-spacing: -1px; }

@media (min-width: 992px) { #shopping-cart .icon-button { transition: color 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000); letter-spacing: 0; } }

@media (max-width: 991px) { #shopping-cart .icon-button[data-products]:after { content: attr(data-products); position: absolute; bottom: calc(100% - 1ex); left: calc(100% + 0.5ch); display: inline-block; font-family: arial; font-size: 11px; letter-spacing: 0.1px; } }

#shopping-cart .icon-button .icon { font-size: 1.75em; }

@media (min-width: 992px) { #shopping-cart .icon-button .icon { font-size: 1em; } }

#shopping-cart .icon-button .icon + span { display: none; }

@media (min-width: 992px) { #shopping-cart .icon-button .icon + span { display: inline; } }

#shopping-cart:before { content: ""; position: absolute; bottom: calc(0% - 1rem); left: -1rem; width: calc(100% + 2rem); height: 0; background: rgba(213, 165, 116, 0); z-index: -1; transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000); }

#shopping-cart > .icon { color: #d5a574; margin-right: 0.5ch; transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000); }

#shopping-cart.opened { color: #fff; transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); transition-delay: 0; }

#shopping-cart.opened:before { height: calc(100% + 2rem); background: #d5a574; transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); transition-delay: 0; }

#shopping-cart.opened > .icon { color: #fff; transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); transition-delay: 0; }

#shopping-cart.opened .icon-button { color: #fff; transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); transition-delay: 0; }

.nav { top: 0px; left: 0px; z-index: 10; width: auto; height: auto; background: transparent; padding: 0.7809rem 0; }

@media (min-width: 992px) { .nav { flex: 1 0 auto; position: relative; } }

.nav-wrapper { list-style: none; margin: 0; padding-left: 0; z-index: 10; opacity: 0; display: flex; flex-direction: column; justify-content: center; position: fixed; top: 0; left: 0; height: 100vh; max-height: calc(100vh - var(--vh-offset, 0px)); width: 100vw; transition: opacity .1s ease-in; transition-delay: 0.2s; }

@media (max-width: 767px) { .nav-wrapper { pointer-events: none; } }

.nav--open .nav-wrapper { transition-timing-function: ease-out; opacity: 1; transition-duration: 0.2s; transition-delay: 0.5s; }

@media (max-width: 767px) { .nav--open .nav-wrapper { pointer-events: auto; } }

@media (min-width: 992px) { .nav-wrapper { flex-direction: row; width: 100%; height: auto; position: relative; opacity: 1; }
  .nav-wrapper _:-ms-lang(x), .nav-wrapper _:-webkit-full-screen, .nav-wrapper { justify-content: space-around !important; }
  @supports (justify-content: space-evenly) { .nav-wrapper { justify-content: space-evenly; } } }

.nav-item { display: flex; flex: 0.05 0 auto; justify-content: center; position: relative; }

@media (min-width: 992px) { .nav-item { flex: 1 0 auto; }
  @supports (justify-content: space-evenly) { .nav-item { flex-grow: 0; } } }

.nav-item.has-subnav { margin: 0 -5px; padding: 0 5px; }

.nav-item.has-subnav .icon.icon-arrow-down { margin-left: 0.25ch; font-weight: bold; font-size: 0.9em; padding-top: 2px; transition: all 150ms ease-in; }

@media (min-width: 1200px) { .nav-item.has-subnav .icon.icon-arrow-down { padding-top: 0; font-size: 1em; } }

.nav-item > .subnav { visibility: hidden; opacity: 0; font-size: 1.125em; }

@media (min-width: 992px) { .nav-item > .subnav { font-size: 1em; } }

.nav-item.opened > .subnav { visibility: visible; opacity: 1; }

.nav-item.opened.has-subnav { background: rgba(0, 0, 0, 0.25); }

.nav-item.opened.has-subnav .nav-item__inline, .nav-item.opened.has-subnav .icon.icon-arrow-down { color: #d5a574; }

.nav-item.opened .nav-item__inline:after { display: none; }

.nav-item.opened .icon.icon-arrow-down { transform: rotate(180deg); transition-timing-function: ease-out; }

@media (min-width: 992px) { .nav-item:hover > .subnav { visibility: visible; opacity: 1; box-shadow: 0 -1px 0 6px #fff, 0 0px 0 7px #D6D6D6; }
  .nav-item:hover.has-subnav { background: rgba(0, 0, 0, 0.25); background: transparent; box-shadow: 0 0 0 6px #fff, 0 0 0 7px #D6D6D6; }
  .nav-item:hover.has-subnav .nav-item__inline, .nav-item:hover.has-subnav .icon.icon-arrow-down { color: #d5a574; }
  .nav-item:hover.has-subnav .nav-item__inline:after { display: none; } }

.nav-item__link { display: flex; align-items: center; justify-content: center; font-weight: bold; color: #fff; font-family: 'TraPro-Bold', serif; font-size: 1.15rem; letter-spacing: 0.5px; }

@media (min-width: 992px) { .nav-item__link { font-size: 1rem; font-weight: normal; font-family: 'MontRat', sans-serif; color: #7F7F7F; letter-spacing: 0px; }
  ._long-menu .nav-item__link { font-size: 0.8rem; } }

@media (min-width: 1200px) { ._long-menu .nav-item__link { font-size: 0.925rem; letter-spacing: -0.05px; } }

.nav-item__link.active { color: #d5a574; }

.nav-item__link.active .nav-item__inline:after { opacity: 1; min-height: 2px; height: 0.1ex; }

.nav-item__link:hover { color: #d5a574; }

.nav-item__link:hover .nav-item__inline:after { opacity: 1; height: 0.175em; transition-timing-function: ease-in; }

.nav-item__inline { text-align: center; text-transform: uppercase; position: relative; }

.nav-item__inline:after { content: ""; position: absolute; top: 100%; left: 0px; display: block; width: 100%; height: 0em; max-height: 5px; background: #d5a574; opacity: 0; pointer-events: none; transition: all 150ms ease-out; }

.nav .subnav { position: absolute; top: 100%; left: 0%; min-width: auto; list-style: none; padding: 0; background: transparent; display: flex; flex-direction: column; margin-top: 0; width: 100%; text-align: center; text-transform: uppercase; z-index: 1; }

@media (min-width: 992px) { .nav .subnav { background: rgba(255, 255, 255, 0.95); display: grid; gap: 0.5rem; } }

.nav .subnav:before { content: ""; height: 100%; position: absolute; top: 0; left: -1px; right: 0; width: calc(100% + 2px); background: #d5a574; }

@media (min-width: 992px) { .nav .subnav:before { background: rgba(255, 255, 255, 0.95); } }

.nav .subnav-item { flex: 1 0 auto; padding: 0.7809rem 0; margin-top: 0; padding-top: 0; }

@media (min-width: 992px) { .nav .subnav-item { max-width: 230px; padding-left: 0.5rem; padding-right: 0.5rem; line-height: 1.4; }
  .nav .subnav-item + .subnav-item { margin-top: calc(0.7809rem * -1); } }

.nav .subnav-item:before { content: ""; display: block; height: 1px; background: #f2f2f3; width: 100%; margin: 0; margin-bottom: 0.75rem; }

.nav .subnav-item__link { display: inline-block; color: #fff; }

@media (min-width: 992px) { .nav .subnav-item__link { color: #7F7F7F; } }

.nav .subnav-item__link.active { color: #d5a574; }

.nav .subnav-item__link:hover { color: #d5a574; }

@media (min-width: 992px) { .nav .subnav._grid { grid-template-columns: repeat(3, minmax(0, auto)); width: max-content; }
  .nav .subnav._grid .subnav-item { padding-top: 0.7809rem; padding-bottom: 0.7809rem; }
  .nav .subnav._grid .subnav-item:first-child { margin-top: -0.75rem; }
  .nav .subnav._grid .subnav-item:before { width: calc(100% + 12px + 1rem); margin-left: -6px; margin-right: -6px; margin-top: -6px; } }

.nav__toggle { display: inline-block; position: absolute; z-index: 10; padding: 0; border: 0; background: transparent; outline: 0; left: -10px; top: 50%; margin-top: -25px; margin-left: 20px; cursor: pointer; border-radius: 50%; transition: background-color .15s linear; box-shadow: none; outline: none !important; transform: translate3d(0, 0, 0); }

@media (min-width: 992px) { .nav__toggle { display: none; } }

.nav .splash { position: absolute; top: 40px; left: 40px; width: 1px; height: 1px; transform: translate3d(0, 0, 0); }

.nav .splash::after { content: ""; display: block; position: absolute; border-radius: 50%; background-color: rgba(64, 68, 85, 0.825); backdrop-filter: blur(10px); width: 284vmax; height: 284vmax; top: -142vmax; left: -142vmax; transform: scale(0); transform-origin: 50% 50%; transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06); will-change: transform; }

.nav-wrapper._long-menu .subnav-item__link { font-size: 0.875rem; letter-spacing: -0.05px; }

.menuicon { display: block; cursor: pointer; color: #404455; transform: rotate(0deg); transition: 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22); width: 50px; height: 50px; }

.menuicon__bar, .menuicon__circle { fill: none; stroke: currentColor; stroke-width: 3; stroke-linecap: round; }

.menuicon__bar { transform: rotate(0deg); transform-origin: 50% 50%; transition: transform .25s ease-out; }

.menuicon__circle { transition: stroke-dashoffset .3s linear .1s; stroke-dashoffset: 144.513262038; stroke-dasharray: 144.513262038; }

.nav:target > .splash::after, .nav--open > .splash::after { transform: scale(1); }

.nav:target .menuicon, .nav--open .menuicon { color: #d5a574; transform: rotate(180deg); transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.nav:target .menuicon__bar, .nav--open .menuicon__bar { transition-timing-function: ease-out; }

.nav:target .menuicon__circle, .nav--open .menuicon__circle { stroke-dashoffset: 0; }

.nav:target .menuicon__bar:nth-child(1), .nav:target .menuicon__bar:nth-child(4), .nav--open .menuicon__bar:nth-child(1), .nav--open .menuicon__bar:nth-child(4) { opacity: 0; }

.nav:target .menuicon__bar:nth-child(2), .nav--open .menuicon__bar:nth-child(2) { transform: rotate(45deg); }

.nav:target .menuicon__bar:nth-child(3), .nav--open .menuicon__bar:nth-child(3) { transform: rotate(-45deg); }

.main { position: relative; z-index: 25; }

#SliderWrapper { flex: 1 0 auto; z-index: 10; max-height: 400px; content: content; }

#SliderWrapper > video, #SliderWrapper > .slider { width: 100% !important; height: 100% !important; position: absolute; top: 0px; left: 0px; z-index: 1; }

#SliderWrapper > .video-cover { width: 100%; height: 100%; object-fit: cover; }

#SliderWrapper > video { object-fit: cover; }

#SliderWrapper .slider--content { position: relative; z-index: 10; }

#SliderWrapper .vegas-slide { background: rgba(41, 44, 56, 0.4); }

#SliderWrapper .vegas-slide-inner { mix-blend-mode: multiply; }

#SliderWrapper .slider { background-color: #404455; transition: background 1s ease-in; transition-delay: 0s; }

#SliderWrapper .slider.loaded { background-color: #fff; transition-delay: 0.2s; /* &:after{ visibility: visible; opacity: 1; } */ }

#SliderWrapper .slider--header { color: #fff; text-shadow: 1px 1px 0 #000, 1px 1px 0px rgba(0, 0, 0, 0.125), 1px 1px 3px rgba(0, 0, 0, 0.25), 0px 0px 5ch black, 0px 0px 10ch rgba(0, 0, 0, 0.5); /* .icon{ color: #fff; font-size: 3rem; width: 1em; height: 1em; box-sizing: content-box; padding: 10px; background: $color_bananagold; border-radius: 50%; //border: 5px solid #fff; text-shadow: none; &:before{ display: block; transform: scale(0.8); } } &.with-icon{ position: relative; padding-left: calc(4rem + 20px + 10px); .icon{ position: absolute; top: 50%; left: 0%; transform: translateY(-50%); } } */ }

#SliderWrapper .slider--header--title { margin-top: 0; margin-bottom: 0; font-weight: bold; }

#SliderWrapper .slider--header--subtitle { margin: 0; font-family: 'Courgette', cursive; font-weight: normal; font-size: 2rem; text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5), 1px 1px 3px rgba(0, 0, 0, 0.5), 0px 0px 5ch black, 0px 0px 10ch #000; position: relative; }

@media (max-width: 991px) { #SliderWrapper .slider--header--subtitle { font-size: calc(1rem + 0.25vw); } }

#SliderWrapper .slider--header--subtitle .author-quote { position: absolute; top: 100%; right: 0; margin-top: 1rem; font-weight: 400; font-family: 'MontRat', sans-serif; font-size: 1rem; }

#SliderWrapper.slider--home { height: 41.5vh; max-height: 55vh; }

@media (min-width: 992px) { #SliderWrapper.slider--home { height: 100vh; min-height: 100vh; max-height: 100vh; }
  @supports (height: 100svh) { #SliderWrapper.slider--home { height: calc(100svh - 2rem - 74px); min-height: 0; max-height: none; } } }

#SliderWrapper.slider--home:before { display: none; content: initial; }

#SliderWrapper.slider--home .logo-home { display: block; position: absolute; top: 0%; left: 50%; width: 350px; margin-left: -175px; height: 186px; z-index: 11; background: #fff; padding: 1.5rem; box-shadow: 0 5px 15px -7.5px #000; }

#SliderWrapper.slider--home .scroll { position: absolute; width: 4.375rem; height: 4.375rem; bottom: 4.375rem; left: calc(50% - 2.1875rem); animation: scrolltocontent 7s infinite; cursor: pointer; z-index: 11; }

#SliderWrapper.slider--home .scroll:before { transition: transform 0.5s; }

#SliderWrapper.slider--home .scroll .icon { font-size: 2.1875rem; transition-delay: 0.25s; transition-timing-function: cubic-bezier(uquote("0.950, 0.050, 0.795, 0.035")); transition: all 0.5s; }

#SliderWrapper.slider--home .scroll:before { position: absolute; content: ""; top: 0; left: 0; right: 0; bottom: 0; background: transparent; border: solid 2px rgba(255, 255, 255, 0.5); border-radius: 2.1875rem; transition-timing-function: cubic-bezier(uquote("0.550, 0.055, 0.675, 0.190")); }

#SliderWrapper.slider--home .scroll:hover:before { transform: scale(1.3333333333); }

#SliderWrapper.slider--home .scroll:hover .icon { transform: scale(1.3333333333); color: #d5a574; }

@media (max-width: 991.98px) { #SliderWrapper.slider--home .scroll { display: none; } }

#SliderWrapper .video-audio-toggler, #SliderWrapper .video-playback-toggler { position: absolute; bottom: 1rem; font-size: 2rem; z-index: 13; color: #fff; cursor: pointer; }

#SliderWrapper .video-audio-toggler { left: 1rem; }

#SliderWrapper .video-audio-toggler i:before { content: ""; }

#SliderWrapper .video-audio-toggler.music-on i:before { content: ""; }

#SliderWrapper .video-playback-toggler { left: 4.5rem; }

#SliderWrapper .video-playback-toggler i:before { content: ""; }

#SliderWrapper .video-playback-toggler.paused i:before { content: ""; }

#Top + #SliderWrapper { margin-top: 0; }

.gallery-item-additional { position: absolute; z-index: 1; right: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); color: #fff; }

.gallery-item-additional > span { transition: all 0.25s; display: inline-block; font-size: 2.625rem; line-height: 1; text-align: center; text-shadow: 1px 1px 0 black; }

.gallery__wrapper { padding-left: 3.75vw; padding-right: 3.75vw; }

@media (min-width: 2000px) { .gallery__wrapper { padding-left: 75px; padding-right: 75px; } }

@media (max-width: 575.98px) { .gallery__wrapper { padding-left: 21.6px; padding-right: 21.6px; } }

.gallery__container { margin-left: -0.39045rem; margin-right: -0.39045rem; }

.gallery-item { padding-left: 0.39045rem; padding-right: 0.39045rem; margin-bottom: 0.7809rem; position: relative; z-index: 1; }

.gallery-item.embed-responsive::before { padding-top: 66.6666666667%; }

.gallery-item-link { position: relative; display: block; perspective: 35em; width: 100%; height: 100%; color: #fff; }

.gallery-item-link:hover .gallery-item-additional > span { transform: scale(1.25); }

.gallery-item-img { object-fit: cover; }

.gallery__grid { display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 0.39045rem; grid-autoflow: dense; padding-left: 3.1236px; padding-right: 3.1236px; }

@media (min-width: 992px) { .gallery__grid { grid-gap: 0.7809rem; grid-template-columns: repeat(4, 1fr); padding-left: 6.2472px; padding-right: 6.2472px; } }

.gallery__grid .gallery-item { padding: 0; margin: 0; width: 100%; max-width: none; }

@media (min-width: 992px) { .gallery__grid .gallery-item:nth-child(10n+1), .gallery__grid .gallery-item:nth-child(10n+8) { grid-row: span 2; grid-column: span 2; } }

@media (max-width: 991.98px) { .gallery__grid .gallery-item:nth-child(6n+1), .gallery__grid .gallery-item:nth-child(6n+5) { grid-row: span 2; grid-column: span 2; } }

/* Datepicker =========================================================================== */
#ui-datepicker-div { display: none; position: absolute !important; z-index: 1100 !important; font-family: 'MontRat', sans-serif; border-radius: 0; /* &:before{ content:""; position:absolute; top:-14px; left:7px; @include size(0, 0); border-style: solid; border-width: 0 10px 14px 10px; border-color: transparent transparent #fff transparent; } */ }

.ui-datepicker { display: block; position: absolute; color: black; width: 300px; padding: 10px; background-color: #fff; box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.4), 0 0 3px -1px rgba(0, 0, 0, 0.6); z-index: 100 !important; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.ui-datepicker .ui-datepicker-header { background: none; position: relative; padding: 15px; margin: -10px; margin-bottom: 10px; box-sizing: content-box; display: flex; align-items: center; background: #d5a574; color: #fff; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev { flex: 0 0 auto; order: 1; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-title { flex: 1 0 auto; order: 2; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-next { flex: 0 0 auto; order: 3; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .ui-datepicker .ui-datepicker-header .ui-datepicker-next { text-align: center; display: block; position: relative; top: 0; left: 0; right: auto; color: black; text-decoration: none; width: 24px; height: 24px; line-height: 24px; vertical-align: middle; opacity: 1; cursor: pointer; user-select: none; transition: all 300ms ease; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before, .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before { display: block; font-family: "sans-serif"; font-size: 24px; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; text-decoration: none; line-height: 1; width: 24px; height: 24px; -webkit-font-smoothing: antialiased; speak: none; color: #fff; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-header .ui-datepicker-next span { display: none; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before { content: "❮"; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-next { right: 0; text-align: right; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before { content: "❯"; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover { opacity: 1; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-title { font-size: 1rem; text-align: center; color: #fff; font-family: 'TraPro-Bold', serif; line-height: 1; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-month { font-size: 1.25em; }

.ui-datepicker .ui-datepicker-header .ui-datepicker-year { font-size: 1em; margin-top: -0.5ex; }

.ui-datepicker .ui-datepicker-header .ui-state-disabled { color: #ccc; }

.ui-datepicker table { display: table; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 0; }

.ui-datepicker table thead th { font-size: 13px; font-weight: 400; line-height: 18px; text-align: center; border-bottom: 1px solid rgba(0, 0, 0, 0.1); color: rgba(0, 0, 0, 0.4) !important; }

.ui-datepicker table .ui-datepicker-week-col { width: 25px; }

.ui-datepicker table th { height: 32px; border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.ui-datepicker table th.ui-datepicker-week-col { font-size: 0; line-height: 0; border-bottom: 0; }

.ui-datepicker table td { color: black; text-align: center; height: 36px; padding: 1px; margin: 0; border: 0; outline: 0; }

.ui-datepicker table td span, .ui-datepicker table td a, .ui-datepicker table td .ui-state-default, .ui-datepicker table td .ui-state-active { box-shadow: none; display: block; color: inherit; font-size: 14px; font-weight: inherit; line-height: 34px !important; width: 34px; height: 34px; border-radius: 2px !important; text-decoration: none; }

.ui-datepicker table td span.ui-state-hover, .ui-datepicker table td a.ui-state-hover { background-color: #d5a574; color: #fff; }

.ui-datepicker table td span.ui-state-active, .ui-datepicker table td a.ui-state-active { color: #fff; background-color: #d5a574 !important; line-height: 34px !important; }

.ui-datepicker table td.ui-datepicker-week-col { font-size: 11px; line-height: 20px; }

.ui-datepicker table td.ui-datepicker-date-highlight-depart a { background-color: rgba(248, 222, 171, 0.4); }

.ui-datepicker table .ui-datepicker-week-end { color: inherit; }

.ui-datepicker table .ui-datepicker-other-month { color: #999; font-weight: 400; }

.ui-datepicker table .ui-datepicker-unselectable { color: #ccc; font-weight: 400; cursor: not-allowed; }

.ui-datepicker.ui-datepicker-multi { display: table; table-layout: fixed; width: 721px; padding: 0; }

.ui-datepicker .ui-datepicker-group { display: table-cell; vertical-align: top; width: 50%; padding: 10px; }

.ui-datepicker .ui-datepicker-group-last { border-left: 1px solid #e4e4e4; }

.ui-calendar { display: block; position: relative; color: black; text-align: left; background-color: #fff; }

.ui-calendar.ui-state-nodate { background-color: rgba(255, 255, 255, 0.7); }

.ui-calendar.ui-state-nodate .ui-calendar-button { display: none; }

.ui-calendar > .label { display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; font-size: 14px; font-weight: 400; line-height: 38px; padding: 0 10px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.ui-calendar > .label .year { font-weight: 400; }

.ui-calendar input { display: block; position: relative; color: inherit; font-size: 14px; font-weight: 400; line-height: 20px; width: 100%; height: 38px; padding: 0 10px; margin: 0; border: 0; outline: 0; -webkit-appearance: none !important; cursor: pointer; background-color: transparent; opacity: 0; filter: alpha(opacity=0); -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.ui-calendar input::-ms-clear { display: none; }

.ui-calendar.ui-state-focus { color: black; background-color: #f8deab; }

.ui-calendar .ui-calendar-button { position: absolute; top: 50%; right: 5px; color: #a32017; width: 22px; height: 22px; margin-top: -11px; cursor: inherit; }

.ui-calendar .ui-calendar-button:before, .ui-calendar .ui-calendar-button:after { content: '\e60b'; display: block; position: absolute; top: 50%; left: 50%; font-family: 'momicons'; font-size: 16px; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; text-decoration: none; line-height: 1; width: 16px; height: 16px; margin-top: -8px; margin-left: -8px; -webkit-font-smoothing: antialiased; speak: none; visibility: visible; opacity: 1; -webkit-transition: all 300ms ease; -moz-transition: all 300ms ease; -ms-transition: all 300ms ease; -o-transition: all 300ms ease; transition: all 300ms ease; }

.ui-calendar .ui-calendar-button:before { opacity: 1; }

.ui-calendar .ui-calendar-button:after { content: '\e60c'; visibility: hidden; opacity: 0; }

.ui-calendar .ui-calendar-button.ui-state-hover { background-color: transparent; cursor: pointer; }

.ui-calendar .ui-calendar-button.ui-state-hover:before { visibility: hidden; opacity: 0; }

.ui-calendar .ui-calendar-button.ui-state-hover:after { visibility: visible; opacity: 1; }

.ui-calendar.ui-state-disabled { opacity: .5; cursor: default; }

.booking-steps { display: flex; list-style-position: inside; padding: 0; margin: 3rem 0 -1.5rem 0; font-size: 1.0625rem; text-transform: uppercase; }

@media (max-width: 991.98px) { .booking-steps { font-size: 0.8125rem; } }

.booking-steps--item { border: 2px solid rgba(255, 255, 255, 0.75); color: rgba(255, 255, 255, 0.75); font-weight: 700; background: rgba(0, 0, 0, 0.35); padding: 0.65rem 1.5rem .65rem 1.25em; line-height: 1; position: relative; top: -2px; left: 0px; }

.booking-steps--item + .booking-steps--item { margin-left: 1.5618rem; }

.booking-steps--item + .booking-steps--item:before { content: ""; pointer-events: none; width: 1.5618rem; height: 2px; background: #fff; position: absolute; bottom: calc(50% - 1px); right: calc(100% + 2px); }

.booking-steps--item.active { color: #fff; border-color: #fff; }

.booking-steps--item.active ~ .booking-steps--item { color: #d5a574; border-color: #d5a574; background: rgba(0, 0, 0, 0.35); text-shadow: none; box-shadow: none; }

.booking-steps--item.active ~ .booking-steps--item:before { background: #d5a574; }

.jblbooking--form { display: flex; flex-direction: column; max-width: none; flex-wrap: wrap; justify-content: center; padding: 0 15px; }

.jblbooking--form.loading .jblbooking--field { background: rgba(204, 204, 204, 0.95); overflow: hidden; }

.jblbooking--field { display: flex; background: #fff; color: #404455; flex: 1 1 auto; position: relative; box-shadow: 0 0px 7.5px -3px #000; height: calc((0.5rem * 2) + (0.9rem * 1.5618) * 2); user-select: none; }

.jblbooking--field.disabled { opacity: 0.5; pointer-events: none; }

.jblbooking--field.is-loading { user-select: none; cursor: progress; }

.jblbooking--field.is-loading > * { pointer-events: none; }

.jblbooking--field.is-loading:after { content: ""; display: block; width: 1.5rem; height: 1.5rem; position: absolute; bottom: 0.25rem; right: 0.25rem; background: svg-load("images/svg/tail-spin.svg") no-repeat; background-size: cover; z-index: 10; }

.jblbooking--field + .jblbooking--field { margin-top: 1rem; }

.jblbooking--field--icon { flex: 0 0 calc(3.5rem + 0.5vw); width: calc(3.5rem + 0.5vw); background: #d5a574; padding: 0 calc(0.5rem + 0.25vw); display: flex; align-items: center; justify-content: center; font-size: 2em; color: #fff; }

.jblbooking--field--icon.icon-location { background-image: url(/assets/images/svg/icons/white/location.svg); }

.jblbooking--field--icon.icon-calendar { background-image: url(/assets/images/svg/icons/white/calendar.svg); }

.jblbooking--field--icon.icon-passenger, .jblbooking--field--icon.icon-user-shape { background-image: url(/assets/images/svg/icons/white/people.svg); }

.jblbooking--field--icon.icon-map-pin { background-image: url(/assets/images/svg/icons/white/hotel.svg); }

.jblbooking--field--icon.icon-ports { background-image: url(/assets/images/svg/icons/white/cruise-ship.svg); }

.jblbooking--field--icon.icon-profile { background-image: url(/assets/images/svg/icons/white/names.svg); }

.jblbooking--field--icon.icon-boat { background-image: url(/assets/images/svg/icons/white/boat.svg); }

.jblbooking--field--icon.icon-envelope { background-image: url(/assets/images/svg/icons/white/world.svg); }

.jblbooking--field--icon.icon-technology { background-image: url(/assets/images/svg/icons/white/phone.svg); }

.jblbooking--field--element { display: flex; flex-direction: column; flex: 1 0.5 50%; padding: 0.5rem; padding-left: 1rem; margin: 0; position: relative; justify-content: flex-end; }

@media (min-width: 992px) { .jblbooking--field--element { justify-content: normal; } }

.jblbooking--field--element input, .jblbooking--field--element select, .jblbooking--field--element span.input { background: inherit; display: block; width: 100%; border: 0; appearance: none; outline: 0 !important; box-shadow: none !important; color: #666977; height: 1.5618em; padding: 0; }

.jblbooking--field--element select > option { padding: 2px 1ch; color: #666977; font-weight: normal; }

.jblbooking--field--element input.hide-carret { color: transparent; text-shadow: 0 0 0 #666977; }

.jblbooking--field--element span.input { color: #707380; }

.jblbooking--field--element input:-ms-input-placeholder { opacity: 1; color: #707380; }

.jblbooking--field--element input:-moz-placeholder { opacity: 1; color: #707380; }

.jblbooking--field--element input::-moz-placeholder { opacity: 1; color: #707380; }

.jblbooking--field--element input::-webkit-input-placeholder { opacity: 1; color: #707380; }

.jblbooking--field--element input.dp-input.hasDatepicker { pointer-events: none; position: relative; top: 100%; left: calc(-3.5rem - 0.5vw - 1rem); }

.jblbooking--field--element.is-selected input, .jblbooking--field--element.is-selected select, .jblbooking--field--element.is-selected span.input { color: #404455; font-weight: 500; }

.jblbooking--field--element span.input.focus { color: transparent; }

.jblbooking--field--element input:focus:-ms-input-placeholder { color: transparent; }

.jblbooking--field--element input:focus:-moz-placeholder { color: transparent; }

.jblbooking--field--element input:focus::-moz-placeholder { color: transparent; }

.jblbooking--field--element input:focus::-webkit-input-placeholder { color: transparent; }

.jblbooking--field--element .label { letter-spacing: 0.2px; user-select: none; }

@media (max-width: 1199.98px) { .jblbooking--field--element .label { line-height: 1; } }

.jblbooking--field--element.timepicker { flex: 0 0 auto; background: #F4EDE3; width: 90px; }

.jblbooking--field.__textarea { min-height: calc((0.5rem * 2) + (0.9rem * 1.5618) * 2); height: auto; }

.jblbooking--field.__textarea .jblbooking--field--element { padding-left: 0.5rem; }

.jblbooking--field.__textarea textarea { border: 0 !important; font-size: 0.85em; height: 10ex; }

.jblbooking--field.__datepicker .dp-input[type="text"], .jblbooking--field.__datepicker .dp-input[type="radio"] { position: absolute; top: 100%; left: 0%; width: 1px; height: 1px; visibility: hidden; pointer-events: none; clip: rect(0, 0, 0, 0); }

.jblbooking--field.__boat-select .jblbooking--field-options .item.boat-item { display: flex; margin-bottom: 5px; }

.jblbooking--field.__boat-select .jblbooking--field-options .item.boat-item .photo { flex: 0 0 120px; margin-left: -0.25rem; }

.jblbooking--field.__boat-select .jblbooking--field-options .item.boat-item .details { flex: 0 0 auto; display: flex; flex-direction: column; justify-content: center; margin-left: 0.5rem; }

.jblbooking--field.__boat-select .jblbooking--field-options .item.boat-item .details .pax { opacity: 0.75; }

.jblbooking--field-options { position: absolute; top: 100%; left: 0px; width: 100%; background: #fff; color: #404455; z-index: 100; margin: 0; padding: 0.195225rem; list-style: none; box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25); max-height: 210px; overflow: auto; }

.jblbooking--field-options .item { padding: 1.25ex 0.39045rem; }

@media (min-width: 992px) { .jblbooking--field-options .item { padding-top: 1ex; padding-bottom: 1ex; } }

.jblbooking--field-options .item:hover, .jblbooking--field-options .item.selected { background: #d5a574; color: #fff; cursor: pointer; border-top-color: #fff !important; }

.jblbooking--field-options .item.__no-results { color: #9c3030; }

.jblbooking--field-options .item + .item { border-top: 1px solid #D6D6D6; }

.jblbooking--field-options.item-focused .item:hover { background: none; color: inherit; }

.jblbooking--field-options.item-focused .item.focus { background: #d5a574; color: #fff; }

.jblbooking--group { margin: 0; padding: 0 15px; position: relative; display: block; }

.jblbooking--group--title { color: #fff; border: 0; line-height: 1; font-size: 1.2rem; font-family: 'TraPro-Bold', serif; font-weight: normal; flex: 1 0 auto; margin: 0 0 0.39045rem 0; letter-spacing: 0.5px; text-shadow: 0 0 2px black, 0 0 12px rgba(0, 0, 0, 0.5); }

.jblbooking--partial-price { border-top: 1px solid rgba(255, 255, 255, 0.2); padding: 0.7rem 1rem 0.75rem 1rem; line-height: 1; display: flex; justify-content: space-between; align-items: center; }

.jblbooking--partial-price + .jblbooking--partial-price { border-top: 0; }

.jblbooking--partial-price.price-pax { margin-bottom: 1rem; }

.jblbooking--pricing { line-height: 1.3; padding: 1rem 0 2rem 0; }

.jblbooking--pricing--intro { font-size: 1.25rem; }

.jblbooking--pricing--finalprice { font-size: 2.5rem; font-weight: 700; text-decoration: none; }

.jblbooking--tour-services { margin-top: 1rem; background: #484b5d; }

.jblbooking--tour-services._no-selected { border-bottom: 4px double rgba(255, 255, 255, 0.2); }

.jblbooking--tour-services._no-selected ~ .jblbooking--pricing { padding-top: 2rem; }

.jblbooking--tour-services .services-header { display: flex; padding: 0.35rem 1rem 0.375rem 1rem; }

.jblbooking--tour-services .services-header .label { word-spacing: 0.25em; }

.jblbooking--tour-services .services-header .btn { background: #505367; border-color: #505367; padding: 0.3rem 0.5rem 0.25rem 0.5rem; font-size: 0.8rem; border-radius: 0; line-height: 1; text-transform: uppercase; margin-left: 1rem; }

.jblbooking--tour-services .services-header .btn:hover { background: #626476; border-color: #626476; }

.jblbooking--tour-services .btn-wrapper { text-align: center; margin-top: 1rem; }

.jblbooking--tour-services .btn-wrapper .btn { background: #f6f6f7; border-color: #f6f6f7; color: #505367; padding: 4px 25px; border-radius: 0; text-transform: uppercase; }

.jblbooking--tour-services .btn-wrapper .btn:hover { background: #fff; border-color: #fff; color: #505367; }

.jblbooking--tour-services .services-selected { padding: 0 1rem; margin-bottom: 0.25rem; }

.jblbooking--tour-services .services-choice { background: #505367; border-radius: 0.5rem; padding: 1rem; }

.jblbooking--tour-services .services-choice .quantity-choice select { appearance: none; border: 0; background: #3c3e4d; color: #fff; width: 50px; font-size: 0.85em; line-height: 1; padding: 2px 6px 4px 2px; margin-right: 0.5rem; background-image: svg-load("images/svg/chevron.svg", fill=rgba(255, 255, 255, 0.8)); background-repeat: no-repeat; background-size: auto 25%; background-position: center right 7px; box-shadow: 0 0 0 2px #fff; border-radius: 2px; }

.jblbooking--tour-services--item { display: flex; justify-content: flex-start; align-items: center; }

.jblbooking--tour-services--item + jblbooking--tour-services--item { margin-top: 0.5rem; }

.jblbooking--tour-services--item .service-name { flex: 1 1 auto; line-height: 1.1; font-size: 0.9rem; padding-bottom: 1px; }

.jblbooking--tour-services--item .service-price { flex: 0 0 auto; text-align: right; margin-left: 1ch; word-spacing: -1px; }

.jblbooking--tour-services--item .service-icon { flex: 0 0 auto; margin-right: 1rem; }

.jblbooking--tour-services--item .service-icon:before { content: ""; display: inline-block; background: none 50% 50% no-repeat; background-size: contain; width: 1.5em; height: 1.5em; }

.jblbooking--tour-services--item .service--info { cursor: pointer; opacity: 0.75; }

.jblbooking--tour-services--item .service--info:before { filter: invert(100%); margin-bottom: -1px; }

.jblbooking--tour-services--item .service--info:hover { opacity: 1; }

.jblbooking--tour-services--item .service-btn-choice { flex: 0 0 auto; margin: 0; padding: 0; background: #3c3e4d; border: 2px solid #fff; height: 1em; width: 1em; position: relative; display: block; border-radius: 2px; margin-right: calc(50px - 1em + 0.5rem); margin-bottom: 0.25rem; cursor: pointer; }

.jblbooking--tour-services--item .service-btn-choice, .jblbooking--tour-services--item .service-btn-choice:focus, .jblbooking--tour-services--item .service-btn-choice:before { user-select: none; box-shadow: none !important; }

.jblbooking--tour-services--item .service-btn-choice:before { content: ""; display: inline-block; height: 1.5em; width: 1.5em; background-repeat: no-repeat; background-size: contain; background-position: 50% 50%; position: absolute; bottom: -3px; left: -1px; }

.jblbooking--tour-services--item .service-btn-choice.selected:before { background-image: url(/assets/images/layout/green-check.png); }

.jblbooking--tour-services--item .qty { margin-left: 0.5ch; color: #e3c199; }

.jblbooking--tour-services .service-icon.baby-seat:before { background-image: svg-load("images/icons/extra-services/baby-seat.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.local-guide:before { background-image: svg-load("images/icons/extra-services/local-guide.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.mini-bar:before { background-image: svg-load("images/icons/extra-services/mini-bar.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.map-pin:before { background-image: svg-load("images/icons/extra-services/map-pin.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.sun:before { background-image: svg-load("images/icons/extra-services/sun.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.tickets:before { background-image: svg-load("images/icons/extra-services/tickets.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.vip-service:before { background-image: svg-load("images/icons/extra-services/vip-service.svg", fill=#d5a574); }

.jblbooking--tour-services .service-icon.wifi:before { background-image: svg-load("images/icons/extra-services/wifi.svg", fill=#d5a574); }

.jblbooking--buttons .btn { display: flex; align-items: center; justify-content: center; }

.jblbooking--buttons .btn--addtocart { border-color: #d9ae82; background: #d5a574; color: #fff; }

.jblbooking--buttons .btn--addtocart:hover { color: #fff; background: #e7b37d; }

.jblbooking--buttons .btn--booknow, .jblbooking--buttons .btn--fastcheckout { border-color: #9eb677; background: #93AE68; color: #fff; }

.jblbooking--buttons .btn--booknow:hover, .jblbooking--buttons .btn--fastcheckout:hover { color: #fff; background: #a6c970; }

.jblbooking--buttons .btn .icon { font-size: 1.35em; margin-right: 0.5ch; }

.jblbooking--buttons .btn + .btn { margin-top: 0.75rem; }

.jblbooking--cancellation-policy { margin-top: 0.39045rem; color: #fff; font-size: 0.75rem; font-weight: normal; letter-spacing: 0.5px; text-align: center; }

.jblbooking--creditcards { margin-top: 1.5618rem; display: flex; justify-content: center; }

.jblbooking--creditcards img { height: 26px; }

.jblbooking--creditcards img + img { margin-left: 5px; }

.jblbooking--creditcards img.securepayments--logos { height: 22px; margin-top: 2px; margin-left: 10px; }

.jblbooking--contact { margin-top: 0.7809rem; }

.jblbooking.tour-on-request .jblbooking--contact { margin-top: 0; padding-bottom: 2rem; }

.jblbooking--securepayments { margin-top: 0.5rem; text-align: center; }

.jblbooking--securepayments img { max-width: 160px; }

.jblbooking .contact-link { font-size: 0.85rem; }

.jblbooking .contact-link:hover { text-decoration: underline; }

.jblbooking--field--icon.icon-location, .jblbooking--field--icon.icon-calendar, .jblbooking--field--icon.icon-passenger, .jblbooking--field--icon.icon-user-shape, .jblbooking--field--icon.icon-map-pin, .jblbooking--field--icon.icon-ports, .jblbooking--field--icon.icon-profile, .jblbooking--field--icon.icon-boat, .jblbooking--field--icon.icon-envelope, .jblbooking--field--icon.icon-technology { text-indent: -1000em; display: block; width: 100%; height: 100%; background-position: 50% 50%; background-repeat: no-repeat; background-size: 60% 60%; }

#Footer { background: #404455 url(/assets/images/layout/footer-bg.png) 50% 100% no-repeat; background-size: 150% auto; color: #fff; flex: 0 0 auto; }

#Footer::after { display: block; clear: both; content: ""; }

@supports (display: flow-root) { #Footer { display: flow-root; }
  #Footer::after { content: none; } }

@media (min-width: 576px) { #Footer { background-size: 100% auto; } }

#Footer .footer__container { margin-bottom: 20%; }

#Footer .footer-logo { width: 3rem; margin-bottom: 1.5618rem; }

@media (min-width: 576px) { #Footer .footer-logo { margin-bottom: 0; margin-right: 3.1236rem; width: 10vw; min-width: 76px; max-width: 152px; }
  #Footer .footer-logo img { margin-top: -15%; } }

#Footer .social-wrapper { display: flex; justify-content: center; z-index: 1; }

@media (min-width: 576px) { #Footer .social-wrapper { justify-content: flex-start; } }

#Footer .social-item { border: solid 1px; border-radius: 100%; display: inline-flex; align-items: center; justify-content: center; transition: all .25s ease-in; border-color: rgba(255, 255, 255, 0.5); color: #404455; background: #fff; margin-top: 1.5618rem; font-size: 1.25rem; width: 2.5rem; height: 2.5rem; box-shadow: 0 0 0 2.25px #404455, 0 0 0 3.25px #fff; }

#Footer .social-item:hover { transition-timing-function: ease-out; box-shadow: 0 0 0 2.25px #404455, 0 0 0 3.25px #d5a574; border-color: #d5a574; background-color: #d5a574; color: #fff; text-shadow: 0 0 3px rgba(0, 0, 0, 0.25); }

#Footer .social-item + .social-item { margin-left: 1.17135rem; }

#Footer .social-item .icon { position: relative; }

#Footer .social-item .icon-facebook { top: -1px; left: -1px; }

#Footer .social-item .icon-instagram { top: -1px; left: 0; }

#Footer .social-item .icon-tripadvisor { font-size: 1.35em; top: -0.5px; left: 0; }

#Footer .footer-list .link-list { list-style: none; padding: 0; margin-bottom: 1.5618rem; }

@media (min-width: 576px) { #Footer .footer-list .link-list { margin-bottom: 0; } }

#Footer .footer-list .link-list--item { color: #fff; font-size: 0.85rem; text-align: center; margin-bottom: 0.39045rem; }

@media (min-width: 576px) { #Footer .footer-list .link-list--item { font-size: 0.9rem; margin-top: 0.7809rem; margin-bottom: 0; } }

#Footer .footer-list .link-list--item:first-child { margin-top: 0; }

#Footer .footer-list .link-list--item a { color: inherit; }

@media (min-width: 576px) { #Footer .footer-list .link-list--item a { align-items: center; display: flex; min-width: max-content; } }

#Footer .footer-list .link-list--item a:before { margin-top: -0.25ex; box-shadow: 0 0 0 2px #404455, 0 0 0 3px rgba(255, 255, 255, 0); display: none; }

@media (min-width: 576px) { #Footer .footer-list .link-list--item a:before { display: block; } }

#Footer .footer-list .link-list--item a:hover:before { box-shadow: 0 0 0 2px #404455, 0 0 0 3px white; }

#Footer .logos-list { display: flex; align-items: center; }

@media (min-width: 576px) { #Footer .logos-list { flex-direction: column; align-items: flex-end; } }

@media (min-width: 992px) { #Footer .logos-list { align-items: flex-start; } }

#Footer .logos-list--item { padding: 0 5px; }

@media (min-width: 576px) { #Footer .logos-list--item { padding: 0; margin-bottom: 1.17135rem; } }

#Footer .logos-list img { flex: 0 0 auto; max-width: 100%; object-fit: contain; height: 24px; }

@media (min-width: 992px) { #Footer .logos-list img { height: 34px; } }

#Closer { flex: 0 0 auto; background: #292C38; color: rgba(255, 255, 255, 0.75); font-size: 0.85rem; }

#Closer .wrapper { padding-top: 0.85rem; padding-bottom: 0.85rem; justify-content: space-around; align-items: center; display: flex; flex-direction: column; line-height: 2; }

@media (min-width: 992px) { #Closer .wrapper { flex-direction: row; line-height: 1.5618; } }

#Closer .owner { display: inline-block; font-family: 'TraPro-Bold', serif; color: #d5a574; margin-right: 0.5ch; text-transform: uppercase; }

#Closer .links { display: block; justify-content: center; font-size: 0.9em; word-spacing: -0.25ch; letter-spacing: -0.1px; }

@media (min-width: 576px) { #Closer .links { display: inline; font-size: 1em; word-spacing: -0.25ch; } }

@media (min-width: 992px) { #Closer .links:before { content: "–"; margin: 0 0.5ch 0 0.25ch; } }

#Closer .disclaimers { align-items: center; color: #fff; text-align: center; flex: 1 1 auto; display: flex; flex-direction: column; }

@media (min-width: 992px) { #Closer .disclaimers { text-align: left; flex-direction: row; } }

#Closer .disclaimers-item { color: #fff; margin: 0 0.5ch; }

#Closer .disclaimers-item:first-child { margin-left: 0; }

#Closer .disclaimers-item:last-child { margin-right: 0; }

#Closer .disclaimers-item.piva { display: block; color: #d5a574; }

@media (min-width: 992px) { #Closer .disclaimers-item.piva:before { content: "–"; margin: 0 0.5ch 0 -0.5ch; color: #fff; } }

#Closer .disclaimers-item.piva .vat { color: #fff; }

@media (min-width: 992px) and (max-width: 1199px) { #Closer .copyright { display: none; } }

#Closer .scroll-up { content: ""; flex: 0 0 auto; font-size: 4rem; width: 1em; height: 1em; cursor: pointer; background: svg-load("images/svg/scroll-up.svg") 50% 0% no-repeat; order: -1; margin: 0.7809rem 0; }

@media (min-width: 992px) { #Closer .scroll-up { order: 10; font-size: 3rem; } }

#Closer .scroll-up:hover { background-image: url(/assets/images/svg/scroll-up_gold.svg); }

#Closer a { color: inherit; }

#Closer a:hover { color: #fff; text-decoration: underline; }

#Contacts { flex-direction: column; align-items: center; }

@media (min-width: 576px) { #Contacts { align-items: flex-start; flex-direction: row; } }

#Contacts .contact-group-item { margin: 0; color: #d5a574; word-spacing: -0.125ch; letter-spacing: -0.1px; }

@media (min-width: 992px) { #Contacts .contact-group-item { word-spacing: 0; letter-spacing: 0; } }

#Contacts .contact-group-item + .contact-group-item { margin-top: 0.5rem; }

#Contacts .contact-group-item--label:first-of-type { flex: 0.1 0 22.5%; min-width: 76px; max-width: 90px; opacity: 0.9; }

#Contacts .contact-group-item .contact-element + .contact-element { margin-left: 1ch; }

#Contacts .contact-group-item > .icon { flex: 0 0 auto; content: ""; display: inline-block; width: 1.125em; height: 1.125em; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain; margin-right: 0.5em; }

#Contacts .contact-group-item.address > .icon { width: 1.25em; background-image: svg-load("images/svg/address.svg", fill=#fff); }

#Contacts .contact-group-item.mobile > .icon, #Contacts .contact-group-item.phones > .icon { background-image: svg-load("images/svg/phones.svg", fill=#fff); }

#Contacts .contact-group-item.mail > .icon { width: 1em; background-image: svg-load("images/svg/email.svg", fill=#fff); }

#Contacts .contact-group-item.organization a { color: #fff; font-size: 1.3276777481em; }

#Contacts .contact-element { flex: 1 0 auto; width: fit-content; font-weight: normal; color: #fff; }

#Contacts .contact-element small, #Contacts .contact-element .text-small { opacity: 0.85; font-style: italic; }

#Contacts .contact-element strong { font-weight: normal; }

.card-wrapper { display: flex; flex-wrap: wrap; margin-left: -0.03125em; margin-right: -0.03125em; }

.card-item { display: flex; flex-direction: column; padding: 0 0.03125em; margin-bottom: 0.0625em; flex: 1 0 auto; width: 50%; }

@media (min-width: 992px) { .card-item { width: 25%; } }

.card-item_photo { flex: 0 0 auto; overflow: hidden; border-bottom: 6px solid #d5a574; }

.card-item_photo-img { width: 100%; height: 200px; overflow: hidden; object-fit: cover; }

.card-item_photo-img--height-auto { height: auto; object-fit: none; }

.card-item_photo-img.no-photo { padding: 5vh 0; background: #d5a574; object-fit: contain; }

.card-item_content { flex: 1 0 auto; display: flex; flex-direction: column; background: #fff; color: #a9a9a9; padding: 0.0625em 0.0625em; }

.card-item_content-title { margin-top: 0; font-size: 0.3125em; }

.card-item--no-grow, .card-item_content--no-grow { flex-grow: 0; }

.featured-homedeal-infobox { margin-top: calc(1.5rem + 1.5vw + 1.5vh); margin-bottom: calc(1.5rem + 1.5vw + 1.5vh); }

.featured-homedeal-infobox .col { height: 100%; }

.featured-homedeal-infobox .col:first-child { padding-left: 0; }

.featured-homedeal-infobox .col:last-child { padding-right: 0; }

.featured-homedeal-infobox .col.slide-infobox { flex-grow: 0.7; }

@media (min-width: 1400px) { .featured-homedeal-infobox .col.slide-infobox { flex-grow: 0.65; } }

.infobox--photo:after { content: ""; position: absolute; top: 1rem; left: 1rem; width: calc(100% - 2rem); height: 6px; background: #d5a574; z-index: 10; }

.infobox--photo { flex: 1 0 auto; max-height: 55%; position: relative; }

.infobox--photo img { width: 100%; height: 100%; object-fit: cover; }

.infobox--heading { margin: 1.5rem 0 0.5rem 0; color: #d5a574; line-height: 1.4; }

.infobox--body { font-size: 15.5px; line-height: 1.5; }

.infobox--body p { font-size: inherit; }

.infobox--btn-wrapper { margin-top: 1rem; }

#BoxesHome .box-item { margin-top: 2rem; margin-bottom: 30px; display: flex; flex-direction: column; }

#BoxesHome .box-item-photo { position: relative; background: #d5a574; padding: 4px 1px 0 1px; }

#BoxesHome .box-item-photo img { background: #292C38; transition: all, 1.5s; transition-timing-function: cubic-bezier(easeOutQuint); -webkit-transform-origin: 25% 25% 50%; -moz-transform-origin: 25% 25% 50%; -ms-transform-origin: 25% 25% 50%; -o-transform-origin: 25% 25% 50%; transform-origin: 25% 25% 50%; }

#BoxesHome .box-item-content-title { font-size: 1.4592295825rem; color: #d5a574; border: 1px solid currentColor; border-top: 0; border-bottom-width: 4px; margin: 0; padding: 0.7809rem 1.5618rem; background-color: #fff; }

#BoxesHome .box-item-icon { position: absolute; top: -2rem; left: 2rem; width: 4.75rem; height: 4.75rem; border-radius: 50%; background-color: #404455; box-shadow: 0 0 0 2px #fff, 0 0 0 5px #d5a574; }

#BoxesHome .box-item-icon .icon { width: 100%; height: 100%; margin: 0.75rem; }

#BoxesHome .box-item-icon .icon.icon-type-tour { margin: 0.5rem; }

#BoxesHome .box-item-icon .icon.icon-type-guide, #BoxesHome .box-item-icon .icon.icon-type-cruise { margin: 1rem; }

.instagramphotos { position: relative; background-color: #404455; padding: 30px 0 15px 0; }

@media (min-width: 576px) { .instagramphotos { padding: 3.1236rem; } }

.instagramphotos--title { position: relative; color: #d5a574; margin: 0 0 1.5rem 0; line-height: 1; }

.instagramphotos--title span { color: #fff; }

.instagramphotos--wrapper { padding: 0.195225rem; margin-top: -0.39045rem; margin-bottom: -0.39045rem; }

@media (min-width: 576px) { .instagramphotos--wrapper { margin-bottom: 0; } }

.instagramphotos--item { padding: 0.13015rem; position: relative; width: 33.3333333333%; }

@media (min-width: 992px) { .instagramphotos--item { width: 16.6666666667%; padding: 0.195225rem; } }

.instagramphotos--item--link { padding-top: 100%; background: #fff; box-shadow: 0 0 7.5px -4px rgba(0, 0, 0, 0.5); }

.instagramphotos--item img { filter: saturate(118%) brightness(108%) brightness(102%); background: #292C38; object-fit: cover; }

.instagramphotos--item--count { position: absolute; top: 3px; left: 3px; bottom: 3px; right: 3px; display: flex; justify-content: center; align-items: center; background: rgba(0, 0, 0, 0.35); font-weight: bold; color: rgba(250, 250, 250, 0.65); padding-right: 5px; font-size: 2rem; }

@media (min-width: 576px) { .instagramphotos--item--count { font-size: 3rem; } }

.instagramphotos--item--likes { display: block; line-height: 1; color: #fff; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 0, 0, 0.75), 1px 1px 5px rgba(0, 0, 0, 0.5); opacity: 0.9; position: absolute; left: 0; bottom: 5px; text-align: center; width: 100%; }

@media (min-width: 576px) { .instagramphotos--item--likes { bottom: 0.8rem; left: 1rem; text-align: left; width: auto; } }

.instagramphotos--item--likes .heart { display: inline-block; margin-right: 0.5ch; }

#BestSellers { position: relative; background-color: #d5a574; margin-top: 2.1875rem; padding-top: 2.1875rem; }

#BestSellers:before { content: ""; position: absolute; background: svg-load("images/svg/best-seller-star.svg") 50% 50% no-repeat; background-size: contain; width: 4.375rem; height: 4.375rem; top: 7px; left: 50%; transform: translateX(-50%) translateY(-50%); filter: drop-shadow(0px 0px 1px #d5a574); }

#BestSellers.trips .trips--list.content { flex-wrap: nowrap; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

@media (min-width: 992px) { #BestSellers.trips .trips--list.content { flex-wrap: wrap; overflow-x: auto; overflow-y: auto; } }

@media (max-width: 991px) { #BestSellers.trips .trips--list.content .row { flex-wrap: nowrap; margin-left: -10px; margin-right: 0; }
  #BestSellers.trips .trips--list.content .row .trip-item { max-width: 80vw; padding-left: 0; } }

#BestSellers.trips .trips--container.row { /* .trip-item.col-12{ max-width: 85vw; + .trip-item{ margin-left: $grid-gutter-width / -2; @media(min-width: $screen-sm){ margin-left: 0; } } }*/ }

.intro-tripadvisor { flex: 1 0 auto; }

@media (min-width: 768px) { .intro-tripadvisor { flex-grow: 0; } }

.intro-tripadvisor-wrapper { background: #f7f7f7; position: relative; width: 100%; max-width: 100%; margin-top: 3.1236rem; box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.25), 1px 1px 1px 1px rgba(0, 0, 0, 0.05); padding: 3.1236rem; }

@media (min-width: 768px) { .intro-tripadvisor-wrapper { width: auto; } }

.intro-tripadvisor-wrapper .title { color: rgba(64, 68, 85, 0.75); text-align: center; }

.intro-tripadvisor-wrapper .phone-number { font-size: 1.5625rem; color: #00AF87; }

@media (max-width: 991.98px) { .intro-tripadvisor-wrapper .phone-number { font-size: 1.375rem; } }

.intro-tripadvisor-wrapper .title-small { color: #00AF87; }

.intro-tripadvisor-wrapper hr { border-color: rgba(195, 195, 195, 0.5); width: 100%; }

.intro-tripadvisor-wrapper:before { content: ""; position: absolute; background: url(/assets/images/svg/green-phone.svg) no-repeat center center; background-size: contain; width: 4.2em; height: 4.2em; top: -2.1em; left: calc(50% - 2.1em); z-index: 10; font-size: 1.2rem; }

@media (min-width: 768px) { .intro-tripadvisor-wrapper:before { font-size: 1rem; } }

.intro-tripadvisor-wrapper:after { content: ""; position: absolute; top: 0.375rem; bottom: 0.375rem; left: 0.375rem; right: 0.375rem; border: 1px solid #C3C3C3; z-index: 1; }

#FeaturedReviews { background-color: #f7f7f7; }

#FeaturedReviews .wrapper-featured-review.flex.wrap { flex-wrap: nowrap; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

@media (min-width: 576px) { #FeaturedReviews .wrapper-featured-review.flex.wrap { flex-wrap: wrap; overflow-x: auto; overflow-y: auto; } }

#FeaturedReviews .wrapper-featured-review.flex.wrap .featured-review { max-width: 80vw; }

#FeaturedReviews .wrapper-featured-review.flex.wrap .featured-review + .featured-review { margin-left: -15px; }

@media (min-width: 576px) { #FeaturedReviews .wrapper-featured-review.flex.wrap .featured-review + .featured-review { margin-left: 0; } }

#FeaturedReviews .featured-review-wrapper { box-shadow: 0 0 7.5px -4px rgba(0, 0, 0, 0.125); background-color: #fff; padding: 2.3427rem; height: 100%; }

#FeaturedReviews .featured-review-wrapper.facebook { border-top: 3px solid #3b5998; border-bottom: 3px solid #3b5998; }

#FeaturedReviews .featured-review-wrapper.tripadvisor { border-top: 3px solid #00AF87; border-bottom: 3px solid #00AF87; }

#FeaturedReviews .featured-review-wrapper.web_site { border-top: 3px solid #d5a574; border-bottom: 3px solid #d5a574; }

#FeaturedReviews .featured-review-wrapper .svg-embed { align-self: center; line-height: 0.5; font-size: 3vw; }

@media (min-width: 2000px) { #FeaturedReviews .featured-review-wrapper .svg-embed { font-size: 60px; } }

@media (max-width: 1199.98px) { #FeaturedReviews .featured-review-wrapper .svg-embed { font-size: 36px; } }

#FeaturedReviews .featured-review-wrapper .post-info { text-align: center; color: #404455; margin: 0.7809rem 0; }

#FeaturedReviews .featured-review-wrapper .post-info cite { font-style: normal; }

#FeaturedReviews .featured-review-wrapper .description { font-size: 0.75rem; }

.trips--container { display: flex; flex-wrap: wrap; }

.trips--container.fluid { margin: 0 -1.5625rem; }

.trips-list { margin-top: 0; margin-bottom: 2rem; }

.trips .trip-item { display: flex; flex-direction: column; margin-bottom: 30px; }

.trips .trip-item:before { content: ""; display: block; position: absolute; background: #fff; top: 0; left: 15px; width: calc(100% - 30px); height: 100%; z-index: 0; box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.25), 1px 1px 1px 1px rgba(0, 0, 0, 0.05); pointer-events: none; }

.trips .trip-item--photo, .trips .trip-item--detail { position: relative; z-index: 1; }

.trips .trip-item--photo { order: 1; border-bottom: 4px solid #d5a574; background: #404455; }

.trips .trip-item--detail { order: 2; flex: 1 1 60%; display: flex; flex-direction: column; padding: 1.17135rem; font-size: 0.875rem; line-height: 1.3; background: #f7f7f7; }

.trips .trip-item--title { flex: 0 0 auto; font-size: 1.2079857543rem; color: #404455; line-height: 1.1; margin: 0; }

.trips .trip-item--duration { flex: 0 0 auto; color: #d5a574; margin-top: 0.195225rem; }

.trips .trip-item--intro { flex: 100 0 auto; }

@media (min-width: 1200px) { .trips .trip-item--intro { flex-grow: 0; margin-top: 0.7809rem; } }

.trips .trip-item--footer { display: flex; flex: 1 0 auto; flex-direction: column; }

@media (min-width: 1200px) { .trips .trip-item--footer { margin-top: 0; flex-direction: row; } }

.trips .trip-item--btn-wrapper { flex: 1 0 auto; display: flex; flex-direction: column; }

@media (min-width: 1200px) { .trips .trip-item--btn-wrapper { flex-grow: 0.75; width: fit-content; justify-content: flex-end; } }

.trips .trip-item--price { flex: 0.25 0 auto; color: #A1A1A1; line-height: 1; text-transform: uppercase; font-size: 0.75rem; order: -1; margin-top: 0; }

@media (min-width: 1200px) { .trips .trip-item--price { order: 2; } }

.trips .trip-item--price .price { font-weight: 700; color: #d5a574; font-size: 1.25rem; margin-top: 0.195225rem; }

.trips .trip-item--button { flex: 0; text-transform: uppercase; }

@media (min-width: 1200px) { .trips .trip-item--button { width: fit-content; } }

.trips-categories { padding-top: calc(0.5rem + 0.5vw + 0.5vh); }

.trips-categories .categories-tabs { padding: 0; list-style: none; border: 0; display: flex; justify-content: center; }

.trips-categories .categories-tabs-item_link { background: #fff; padding: 0.5rem 1.5rem; font-weight: bold; border-radius: 1rem; }

.trips-categories .categories-tabs-item_link + .categories-tabs-item_link { margin-left: 1rem; }

.trips-categories .categories-tabs-item + .categories-tabs-item { margin-left: 1rem; }

.trips-categories .categories-tabs-item_link:hover, .trips-categories .categories-tabs-item.active .categories-tabs-item_link { color: #d5a574; }

.trip_detail { background-image: linear-gradient(to top, #404455, #404455); background-repeat: no-repeat; background-size: 100% calc((100vw * 0.6666666667) + 8ex + 6ex - 4px); }

@media (min-width: 992px) { .trip_detail { background-size: 100% calc(((100vw * 0.583333333333) * 0.6666666667) + 8ex + 6ex - 4px); } }

@media (min-width: 1200px) { .trip_detail { background-size: 100% calc(((100vw * 0.666666666667) * 0.6666666667) + 8ex + 6ex - 10px); } }

@media (min-width: 1600px) { .trip_detail { background-size: 100% calc(((1400px * 0.666666666667) * 0.6666666667) + 8ex + (1.5618rem + 6ex) + 4px); } }

.trip_detail--top { color: #fff; background: #404455; }

.trip_detail--heading { position: relative; min-height: 8ex; align-items: flex-end; margin-left: -15px; margin-right: -15px; }

@media (min-width: 992px) { .trip_detail--heading { margin-left: 0; margin-right: 0; } }

.trip_detail--icon .icon { flex: 0 0 auto; margin: 0; margin-right: 0.7809rem; width: 4rem; height: 4rem; display: flex; justify-content: center; align-items: center; }

.trips--list .trip_detail--icon .icon { background-color: #404455; border-radius: 50%; background-size: 75% 75%; box-shadow: 0 0 0 2px #fff, 0 0 0 5px #d9ae82; }

.trips--list .trip_detail--icon .icon.icon-type-boat { background-size: 70% 70%; }

.trips--list .trip_detail--icon .icon.icon-type-cruise { background-size: 50% 50%; }

.trip_detail--heading .trip_detail--icon .icon.icon-type-cruise { background-size: 75% 75%; }

.trip-item--detail .trip_detail--icon { top: 0px; left: -0.125rem; margin: 0; margin-top: -3.17135rem; margin-bottom: 1.17135rem; justify-content: flex-start; }

.trip-item--detail .trip_detail--icon.icon-type-cruise { background-size: 75% 75%; }

.trip_detail--icon .icon { color: #fff; font-size: 1.75rem; }

.trip_detail--titles { flex: 1 1 auto; line-height: 1.2; justify-content: center; padding-bottom: 4px; color: #fff; padding-right: 0.39045rem; }

.trip_detail--titles .title { line-height: 1; margin: 0; margin-bottom: 0.25rem; }

.trip_detail--titles .subtitle { color: #fff; margin: 0; }

.trip_detail--type { text-transform: uppercase; color: #a9a9a9; font-size: 0.9rem; margin: 0; }

.bg-blujoe .trip_detail--type { color: rgba(255, 255, 255, 0.5); }

.trip_detail--duration { text-transform: uppercase; font-size: 0.9rem; color: #d5a574; margin-bottom: 0.25rem; }

.bg-blujoe .trip_detail--duration { color: rgba(255, 255, 255, 0.8); }

.trip_detail--price { flex: 0 0 auto; color: #fff; padding: 0.39045rem; background-color: #d5a574; display: flex; flex-direction: column; justify-content: flex-end; align-items: center; position: absolute; top: calc(100% + 1.5618rem); border-radius: 0.5rem 0 0 0.5rem; right: 0; z-index: 10; font-size: 0.75rem; line-height: 1.2; box-shadow: -1px 0 0 1px #c09568; }

@media (min-width: 992px) { .trip_detail--price { position: static; right: 0; top: 0; font-size: 1rem; border-radius: 1rem 1rem 0 0; padding: 0.39045rem 0.7809rem; box-shadow: none; } }

.trip_detail--price .price { margin-bottom: -0.25ex; font-weight: 500; }

.trip_detail--price .big { font-size: 1.8em; }

.trip_detail--price .intro { white-space: nowrap; }

@media (min-width: 992px) { .trip_detail--price .intro { font-size: 0.9rem; margin-top: 0.75ex; } }

.trip_detail--photo { margin-left: -0.7809rem; margin-right: -0.7809rem; /* &:before{ content: ""; pointer-events: none; position: absolute; bottom: 0px; left: -50%; height: 200%; width: 200vw; background: $color_lavagna; } */ }

@media (min-width: 992px) { .trip_detail--photo { margin-left: 0; margin-right: 0; padding-bottom: calc(1.5618rem + 6ex); } }

.trip_detail--photo .embed-responsive { box-shadow: 0 0px 7.5px -3px rgba(0, 0, 0, 0.9); }

.trip_detail--photo .view-more-photo { position: absolute; right: 1rem; bottom: 1rem; background: rgba(41, 44, 56, 0.8); padding: 0.5rem 1rem; border-radius: 1ex; align-items: center; transition: all 0.25s; display: none; color: rgba(255, 255, 255, 0.8); }

@media (min-width: 992px) { .trip_detail--photo .view-more-photo { display: flex; } }

.trip_detail--photo .view-more-photo .icon { margin-right: 0.5rem; font-size: 1.8rem; color: rgba(255, 255, 255, 0.6); transition: all 0.25s; }

.trip_detail--photo:hover figcaption { background: #292C38; }

.trip_detail--photo:hover figcaption .icon { color: rgba(255, 255, 255, 0.8); }

.trip_detail--services { font-size: 0.95rem; }

.trip_detail--description ul, .trip_detail--description old { padding-left: 20px; }

.trip_detail--description ul li + li, .trip_detail--description old li + li { margin-top: 5px; }

.trip_detail--description *:last-child { margin-bottom: 0; }

.trip_detail--details { background: #404455; color: #fff; padding-left: 15px; padding-right: 15px; padding-top: 1.5618rem; margin-left: -1.5618rem; margin-right: -1.5618rem; align-items: center; }

@media (min-width: 992px) { .trip_detail--details { margin-left: 0; } }

.trip_detail--details .detail { margin: 0 0 1.5618rem 0; line-height: 1.2; }

.trip_detail--details .detail .icon { color: #d5a574; font-size: 2.1875rem; margin-right: 0.625rem; align-self: center; }

.trip_detail--details .detail figure.icon { width: 1em; height: 1em; }

.trip_detail--details .detail figure.icon svg { object-fit: cover; }

.trip_detail--details .detail .title { color: #d5a574; font-size: 0.75rem; text-transform: uppercase; white-space: nowrap; letter-spacing: -0.1px; }

.trip_detail--details .detail.detail-price-from .value:before { content: "€"; display: inline-block; margin-right: 0.5ch; }

.trip_detail--sidebar { background-color: #292C38; display: none; }

@media (min-width: 992px) { .trip_detail--sidebar { display: flex; } }

.trip_detail--sidebar hr { height: 2px; background: none; border-top: 1px solid #D6D6D6; border-bottom: 1px solid #D6D6D6; display: table; width: 100%; margin: 1.5rem 0; flex: 0 0 auto; }

@media (min-width: 1400px) { .trip_detail--sidebar { padding-left: calc(1.5rem + 1.5vw) !important; padding-right: calc(1.5rem + 1.5vw) !important; } }

.trip_detail--sidebar .btn-wrapper { margin: 1.5rem 0 3rem 0; }

.trip_detail--sidebar .btn-wrapper .btn { width: 100%; box-shadow: 0 0 7.5px -4px rgba(0, 0, 0, 0.5); }

@media (min-width: 768px) and (max-width: 1199.98px) { .trip_detail--sidebar .btn-wrapper .btn { font-size: 12px; } }

.trip_detail--tabs { height: 6ex; margin-top: -6ex; margin-left: -1.5618rem; margin-right: -1.5618rem; overflow: hidden; }

@media (min-width: 992px) { .trip_detail--tabs { margin-right: 0; margin-left: 0; } }

.trip_detail--tabs .nav { flex-wrap: nowrap; padding: 0; height: 6ex; flex-wrap: nowrap; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch; flex: 0 0 calc(100vw - 3.1236rem - 4ch); margin-left: 0; margin-right: 0; flex: 1 1 auto; }

@media (max-width: 991px) { .trip_detail--tabs .nav { scroll-snap-type: mandatory; scroll-snap-points-x: repeat(calc(100vw - 3.1236rem)-4ch); scroll-snap-type: x mandatory; } }

@media (min-width: 992px) { .trip_detail--tabs .nav { overflow-x: unset; overflow-y: unset; margin-left: 0; margin-right: 0; } }

.trip_detail--tabs .nav-arrow { top: 0px; color: #fff; font-weight: bold; font-size: 1.5rem; height: 100%; display: flex; justify-content: center; align-items: center; flex: 0 0 calc(1.5618rem + 1ch); position: relative; z-index: 1; }

.trip_detail--tabs .nav li { flex-basis: 0%; display: inline-flex; padding-right: 0.46875em; }

@media (max-width: 991px) { .trip_detail--tabs .nav li { flex: 0 0 calc(100vw - 3.1236rem); scroll-snap-align: start; flex: 0 0 100%; width: stretch; padding-right: 0; } }

.trip_detail--tabs .nav li:first-of-type { padding-left: 0; }

.trip_detail--tabs .nav li:last-of-type { padding-right: 0; }

.trip_detail--tabs .nav li:hover, .trip_detail--tabs .nav li.active { z-index: 100; }

.trip_detail--tabs .nav li a { position: relative; display: block; flex-grow: 1; display: inline-flex; justify-content: center; align-items: center; text-align: center; font-size: 1.2079857543rem; font-family: 'TraPro-Bold', serif; margin: 0; background: #d5a574; color: #fff; border-radius: 1ex 1ex 0 0; padding: 0 2ch; padding-top: 0.3ex; transition: 0.25s; transition-property: color, background; white-space: nowrap; }

.trip_detail--tabs .nav li a.active, .trip_detail--tabs .nav li a:hover { background: #fff !important; color: #d5a574 !important; box-shadow: none; border-bottom: none; }

.trip_detail--gallery { background-color: #404455; padding: 1.5618rem 0; position: relative; z-index: 4; }

.trip_detail--gallery img { background: #fff; box-shadow: 0 0 7.5px -4px rgba(0, 0, 0, 0.5); }

.trip_detail--sideinfo { margin-left: -1.5618rem; margin-right: -1.5618rem; background-color: #292C38; border-top: 3px solid #292C38; padding-top: calc(8ex + 0.7809rem); padding-bottom: calc(6ex + 1.5618rem); position: relative; /* &:after{ content: ""; display: block; bottom: 100%; left: 0; height: 50%; width: 100%; background: #f00; position: absolute; background: linear-gradient(to top, $color_midnight 50%, rgba($color_midnight, 0.1) 100%); @media(min-width: $screen-lg){ content: initial; display: none; } } */ }

@media (max-width: 991px) { .trip_detail--sideinfo { flex-basis: auto; max-width: none; width: auto; margin-top: -15px; } }

@media (min-width: 992px) { .trip_detail--sideinfo { padding-top: calc(8ex + 1.5618rem); border-top: 0; margin-top: 0; margin-left: 0; margin-right: 0; padding-bottom: 0; z-index: 5; } }

.trip_detail--sideinfo:before { content: ""; display: block; width: 100%; height: 0; padding-top: 27%; margin-left: -1px; -webkit-mask-image: url(/assets/images/svg/booking-text.svg); mask-image: url(/assets/images/svg/booking-text.svg); mask-repeat: no-repeat; background: #404455; position: absolute; top: 0px; left: 0px; pointer-events: none; }

.trip_detail--extra--title { font-size: 1.4592295825rem; display: flex; align-items: center; margin: 0 0 0.7809rem 0; color: #d5a574; }

.trip_detail--extra ul { padding: 0; margin: 0; list-style: none; padding-left: 2px; line-height: 1; }

.trip_detail--extra ul li { position: relative; display: flex; align-items: baseline; line-height: 1.2; }

.trip_detail--extra ul li:before { font-family: 'icomoon' !important; font-size: 1.1em; margin-right: 0.5ch; }

.trip_detail--extra ul li + li { margin-top: 0.7809rem; }

.trip_detail--extra.__included { color: #404455; }

.trip_detail--extra.__included li:before { content: ""; color: #7FC301; transform: translateY(0.25ex); }

.trip_detail--extra.__notincluded { color: rgba(64, 68, 85, 0.54); }

.trip_detail--extra.__notincluded li:before { font-size: 1em; content: ""; color: rgba(64, 68, 85, 0.38); }

.trip_detail--itinerary--places { padding-left: 0; display: flex; flex-wrap: wrap; list-style: none; margin: 0; padding: 0; }

.trip_detail--itinerary--places .place { flex: 0 0 auto; }

.trip_detail--itinerary--places .place:after { content: ", "; margin-right: 0.5ch; }

.trip_detail--itinerary--places .place:last-child:after { content: initial; display: none; }

.trip_detail--itinerary .google_map > span.address { display: none; }

.trip_detail--itinerary .google_map .marker-infowindow { font-size: 16px; padding: 0 1ch; }

.trip_detail--map { background: #E5E3DF; height: 40vh; min-height: 400px; }

.trip_detail--reviews { display: flex; flex-wrap: wrap; align-items: center; }

.trip_detail--reviews .review { position: relative; margin-bottom: 1.5618rem; float: left; font-size: 0.9rem; }

.trip_detail--reviews .review--wrapper { position: relative; display: flex; flex-direction: column; align-items: center; box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35); padding: 2rem; overflow: hidden; }

.trip_detail--reviews .review--wrapper:before { position: absolute; top: -0.25rem; left: -0.25rem; font-family: 'icomoon' !important; content: ""; color: #F4F4F4; font-size: 3.75rem; line-height: 1; z-index: -1; }

.trip_detail--reviews .review--wrapper:after { position: absolute; bottom: -0.25rem; right: -0.25rem; font-family: 'icomoon' !important; content: ""; color: #F4F4F4; font-size: 3.75rem; line-height: 1; z-index: -1; }

.trip_detail--reviews .review--reviewer-name { font-weight: bold; font-style: normal; margin: 0; font-size: 1.375em; }

.trip_detail--reviews .review--reviewer-city { opacity: 0.85; font-size: 1em; }

.trip_detail--reviews .review--message { font-style: italic; margin-top: 1rem; text-align: center; font-size: 1em; color: #535766; }

.trip_detail--reviews .review:before { position: absolute; top: 1.2rem; left: 2rem; font-family: 'icomoon' !important; content: ""; color: rgba(163, 163, 163, 0.5); font-size: 1.875rem; line-height: 1; }

.trip_detail--reviews .review:after { position: absolute; bottom: 1.2rem; right: 2rem; font-family: 'icomoon' !important; content: ""; color: rgba(163, 163, 163, 0.5); font-size: 1.875rem; line-height: 1; }

.groupings .group-item { margin-bottom: 1.875rem; }

.groupings .group-item:hover img { transform: scale(1.05) perspective(1px); }

.groupings .group-item:hover figcaption { bottom: 50%; margin-bottom: -1.875rem; }

.groupings .group-image { border-top: 2px solid #d5a574; position: relative; }

.groupings .group-image img { transition: all, 1.5s; transition-timing-function: cubic-bezier(easeOutQuint); -webkit-transform-origin: 25% 25% 50%; -moz-transform-origin: 25% 25% 50%; -ms-transform-origin: 25% 25% 50%; -o-transform-origin: 25% 25% 50%; transform-origin: 25% 25% 50%; }

.groupings .group-title { background-color: #404455; background-image: linear-gradient(135deg, #404455 0%, #404455 50%, #535766, #333644 100%); background-repeat: no-repeat; background-position: 0% 50%; background-size: 500% 400%; border-bottom: 4px solid #d5a574; transition: background 0.5s ease-in; transition-delay: 0.15s; }

.groupings .group-title--icon { color: #d5a574; font-size: 2.5rem; width: 1em; height: 1em; }

.groupings .group-title--icon.icon-type-tour { transform: scale(1.4); }

.groupings .group-title--text { font-size: 1.3rem; letter-spacing: -0.5px; line-height: 1.1; }

.groupings .group-item:hover .group-title { background-position: 100% 100%; transition-timing-function: ease-out; transition-delay: 0; }

#TopReviews .topreview-title { font-size: 1.175rem; font-style: italic; text-align: center; margin: 0; margin-bottom: 1.5rem; }

#TopReviews .review { padding: 0; width: auto; }

#TopReviews .review:last-of-type { margin-bottom: 0; }

#RandomTrips .intro { font-size: 1.5rem; margin-bottom: 0.7809rem; }

@media (min-width: 992px) { #RandomTrips .intro { font-size: 1.66558526rem; } }

#RandomTrips .trips--list { background-image: linear-gradient(to top, #d5a574 0%, #d5a574 40%, transparent 40%); padding-bottom: 1.5618rem; }

@media (min-width: 992px) { #RandomTrips .trips--list { background: transparent; padding-bottom: 0; } }

#RandomTrips .trips--container { margin-bottom: -30px; flex-wrap: nowrap; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

@media (min-width: 992px) { #RandomTrips .trips--container { flex-wrap: wrap; overflow-x: unset; overflow-y: unset; } }

@media (max-width: 991px) { #RandomTrips .trips--container.row { margin-left: -1.5618rem; margin-right: -1.5618rem; }
  #RandomTrips .trips--container .trip-item + .trip-item { margin-left: -15px; } }

#RandomTrips .trips--container .trip-item { max-width: 80vw; }

@media (min-width: 992px) { #RandomTrips .trips--container .trip-item { max-width: inherit; } }

#RandomTrips.cart-related { background: #f7f7f7; }

#RandomTrips.cart-related .trip-item--detail { background: #fff; }

#RandomTrips.cart-related .trip_detail--icon { margin-top: -2rem; margin-bottom: 0.5rem; width: 4rem; height: 4rem; border-width: 4px; }

#RandomTrips.cart-related .trip_detail--icon .icon { font-size: 1.75rem; }

#RandomTrips.cart-related .trip_detail--icon .icon.icon-sun { font-size: 1.9rem; }

.trip-faq--question { color: #d5a574; font-weight: 400; font-size: 1.2rem; margin: 0 0 0.39045rem 0; }

.trip-faq--answer { margin: 0; font-size: 0.9rem; }

.trip-faq--answer p, .trip-faq--answer ul, .trip-faq--answer li { font-size: inherit; }

.trip-faq--item + .trip-faq--item { margin-top: 1.5rem; padding-top: 1.5rem; border-top: 1px solid #D6D6D6; }

.trip-faq--list + .fragment-faq { margin-top: 3rem; padding-top: 3rem; border-top: 4px double #D6D6D6; }

#FormRequestTrip .textarea-note label { font-size: 0.9em; font-weight: 500; margin-bottom: 0; }

#FormRequestTrip #captcha-element img { filter: brightness(90%) saturate(190%) contrast(105%); }

.body-trip-detail .ui-datepicker .tip-availabilities { display: block; width: 280px; font-size: 12px; text-align: right; }

.body-trip-detail .ui-datepicker .tip-availabilities:before { content: "■"; color: #479900; margin-right: 1ch; display: inline-block; }

.body-trip-detail .ui-datepicker .tip-availabilities:after { content: "available dates"; display: inline-block; }

.body-trip-detail .ui-datepicker table td a, .body-trip-detail .ui-datepicker table td span.ui-state-default { color: #090; }

.body-trip-detail .ui-datepicker table td .ui-state-active, .body-trip-detail .ui-datepicker table td .ui-state-hover, .body-trip-detail .ui-datepicker table td span.ui-state-hover { color: #fff; }

.body-trip-detail .ui-datepicker table td.ui-state-disabled .ui-state-default, .body-trip-detail .ui-datepicker table td.ui-datepicker-unselectable .ui-state-default { color: #999; }

.body-trip-detail .ui-datepicker table td.ui-state-disabled.fullybooked-date .ui-state-default, .body-trip-detail .ui-datepicker table td.ui-datepicker-unselectable.fullybooked-date .ui-state-default { cursor: default; }

#TripBooking.jblbooking { color: #fff; }

#TripBooking.jblbooking .sliding-wrapper { margin-top: 1rem; }

#TripBooking.jblbooking .sliding-wrapper .slide-enter-active { overflow: hidden; }

#TripBooking.jblbooking .sliding-wrapper .slide-enter-to, #TripBooking.jblbooking .sliding-wrapper .slide-leave { max-height: 500px; }

#TripBooking.jblbooking .sliding-wrapper .jblbooking--tour-services .slide-enter-to, #TripBooking.jblbooking .sliding-wrapper .jblbooking--tour-services .slide-leave { max-height: 250px; }

#TripBookingBar { box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); border-top: 4px solid #d5a574; background: #242731; color: #fff; text-align: center; position: fixed; bottom: 0px; left: 0px; width: 100%; z-index: 90; transform: translateY(100%); transition: transform 0.15s ease-in; }

@supports (padding-bottom: constant(safe-area-inset-bottom)) { #TripBookingBar { --safe-area-inset-bottom: constant(safe-area-inset-bottom); border-bottom: var(--safe-area-inset-bottom, 0) solid #242731; } }

@supports (padding-bottom: env(safe-area-inset-bottom)) { #TripBookingBar { --safe-area-inset-bottom: env(safe-area-inset-bottom); border-bottom: var(--safe-area-inset-bottom, 0) solid #242731; } }

#TripBookingBar.visible { transform: translateY(0%); transition-timing-function: ease-out; }

#TripBookingBar.hidden { display: none; }

@media (min-width: 992px) { #TripBookingBar { box-shadow: none; margin: 4px 0; border-top: 4px double #404455; border-bottom: 4px double #404455; display: none; top: 0px; bottom: auto; left: auto; width: auto; }
  #TripBookingBar.visible { display: block; } }

#TripBookingBar .barprice { display: flex; justify-content: center; align-items: baseline; font-size: 0.875rem; text-transform: uppercase; line-height: 1; color: #c09568; text-transform: uppercase; }

#TripBookingBar .barprice--amount { line-height: 1; font-size: 2.25em; color: #d5a574; }

#TripBookingBar .barprice--footer { font-size: 0.85rem; opacity: 0.78; }

@media (max-width: 991px) { .body-local-tours #SliderWrapper, .body-shore-excursions #SliderWrapper, .body-boat-tours #SliderWrapper, .body-our-boats #SliderWrapper { min-height: 200px; height: 20vh; max-height: 320px; } }

.swal-trips-extra-services-details { text-align: left; font-size: 0.9rem; line-height: 1.4; }

.swal-trips-extra-services-details p { margin-bottom: 0.39045rem; }

.swal-trips-extra-services-details img { display: block; max-width: 100%; height: auto; }

.vue-portal-target { display: contents; }

#BoatCalendarBooking { display: grid; position: relative; z-index: 950; min-height: 100%; transform: translateY(0vh); opacity: 1; animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); animation-fill-mode: forwards; /* position: absolute; top: calc(0px + var(--boat-calendar-offset-y, 0px)); left: 0px; height: auto; width: 100%; z-index: 10; @media(min-width: $screen-lg){ height: calc(100% - var(--boat-calendar-offset-y, 0px));  } */ }

#BoatCalendarBooking .close-booking { position: absolute; bottom: 100%; right: 0px; background: #fff; border: 0; appearance: none; padding: 0.5em 0.5em; cursor: pointer; }

@media (min-width: 992px) { #BoatCalendarBooking .close-booking { padding: 0.5em 1em; } }

#BoatCalendarBooking .content { height: 100%; }

@media (max-width: 991px) { #BoatCalendarBooking .content { padding: 0; height: auto; } }

#BoatCalendarBooking .inner { box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.5), 0px -2px 3px 1px rgba(0, 0, 0, 0.25); height: 100%; }

#BoatCalendarBooking.visible { animation-name: boatCalendarEnter; animation-duration: 0.5s; }

#BoatCalendarBooking.closing { animation-name: boatCalendarLeave; animation-duration: 0.75s; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker { font-size: 12px; width: 100% !important; box-shadow: none; border: 1px solid #ccc; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker.ui-datepicker-inline { position: static !important; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker-group { padding: 0; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker-header { margin: 0; padding: 10px; background: none; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker-title { color: #7F7F7F; font-size: 0.75rem; font-family: 'MontRat', sans-serif; }

#BoatCalendarBooking .boatcalendar-dates .ui-datepicker-prev::before, #BoatCalendarBooking .boatcalendar-dates .ui-datepicker-next::before { color: #7F7F7F; font-size: 20px; line-height: 24px; }

#BoatCalendarBooking .boatcalendar-dates table td { padding: 0; height: 26px; }

#BoatCalendarBooking .boatcalendar-dates table td span, #BoatCalendarBooking .boatcalendar-dates table td a, #BoatCalendarBooking .boatcalendar-dates table td .ui-state-default, #BoatCalendarBooking .boatcalendar-dates table td .ui-state-active { font-size: 0.75em; width: auto; border-radius: 0 !important; height: 26px !important; line-height: 26px !important; }

#BoatCalendarBooking .boatcalendar-dates table td.ui-dp-daterange:not(.ui-datepicker-unselectable) { background: #d4f3d4; }

#BoatCalendarBooking .boatcalendar-dates table td.ui-dp-daterange.ui-datepicker-unselectable { background: #f3c3c3; }

#BoatCalendarBooking .boatcalendar-dates table td.ui-dp-daterange-disabled { background: #f3c3c3 !important; }

#BoatCalendarBooking .boatcalendar-dates table td.ui-state-highlight .ui-state-default { background-color: #d5a574; color: #fff; }

#BoatCalendarBooking .boatcalendar-dates table td.unavailable-date .ui-state-default { color: #FF3943 !important; font-weight: bold; }

#BoatCalendarBooking .mx-datepicker-inline { width: 100%; }

#BoatCalendarBooking .mx-datepicker-inline .mx-range-wrapper { display: grid; grid-template-columns: 1fr 1fr; }

#BoatCalendarBooking .mx-calendar { width: auto; padding: 6px; }

#BoatCalendarBooking .mx-calendar-content .cell { color: #404455; }

#BoatCalendarBooking .mx-calendar-content .cell.disabled { color: #ae1b1b; background: #fdf4f4; }

#BoatCalendarBooking .mx-calendar-content .cell.active { color: #fff; background: #d5a574; }

#BoatCalendarBooking .mx-calendar-content .cell:hover { background: #f9f2ea; color: #333; }

#BoatCalendarBooking .mx-table-date thead th { font-weight: 700; color: #404455; border-bottom: 1px solid #ebebeb; }

#BoatCalendarBooking .mx-btn { font-weight: 700; color: #404455; }

#BoatCalendarBooking .mx-btn:hover { color: #d5a574; }

#BoatCalendarBooking .mx-btn-icon-double-left, #BoatCalendarBooking .mx-btn-icon-double-right { display: none; }

#BoatCalendarBooking .mx-btn-current-year { pointer-events: none; }

#BoatCalendarBooking .mx-table-date .cell.not-current-month { visibility: hidden; }

#BoatCalendarBooking .datepicker-loader { display: flex; justify-content: center; align-items: center; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.75); cursor: progress; }

#BoatCalendarBooking .datepicker-loader .progress { display: block; width: 4em; height: 4em; filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.5)); }

@media (min-width: 992px) { body.jbl-boat-calendar .trip_detail--price { transition: transform 0.4s ease-in-out; transform: translateY(0%); z-index: 0; } }

@media (min-width: 992px) { body.jbl-boat-calendar--visible .trip_detail--price { transform: translateY(100%); } }

.boatcalendar-item { display: grid; grid-template-columns: 1fr; grid-gap: 0.7809rem; margin-bottom: 6.2472rem; }

@media (min-width: 992px) { .boatcalendar-item { grid-gap: 1.5618rem; grid-template-columns: minmax(300px, 1fr) minmax(360px, auto) 1fr; } }

@media (min-width: 1200px) { .boatcalendar-item { grid-gap: 1.5618rem; grid-template-columns: minmax(360px, 1fr) 1fr 30%; } }

@media (min-width: 1600px) { .boatcalendar-item { grid-template-columns: minmax(0px, 380px) minmax(476px, 1fr) 1fr; grid-gap: 2.3427rem; } }

@media (min-width: 1800px) { .boatcalendar-item { grid-template-columns: minmax(30%, 420px) minmax(420px, 1fr) minmax(auto, 30%); } }

.boatcalendar-photo { width: 100%; gap: 0.5rem; }

.boatcalendar-photo a[data-gallery] { display: contents; }

.boatcalendar-photo .embed-responsive { padding-top: 56.25%; }

.boatcalendar-photo .embed-responsive:before { content: initial; display: none; }

@media (min-width: 992px) { .boatcalendar-photo .embed-responsive { padding-top: 0; }
  .boatcalendar-photo .embed-responsive:before { display: none; }
  .boatcalendar-photo .embed-responsive img { position: relative; height: auto; object-fit: contain; object-position: top center; } }

.boatcalendar-gallery { display: flex; flex-direction: column; width: 100%; }

.boatcalendar-gallery .thumb-carousel { width: 100%; display: flex; justify-content: space-between; align-items: center; gap: 0.25rem; }

.boatcalendar-gallery .thumb-carousel .carousel-container { width: 100%; display: grid; grid-auto-flow: column; grid-auto-columns: 25%; scroll-snap-type: x mandatory; scrollbar-width: none; overflow: hidden; gap: 0; }

.boatcalendar-gallery .thumb-carousel .carousel-arrow { display: flex; background: none; border: 0; padding: 0; box-shadow: none; outline: none; stroke: currentColor; font-size: 1rem; color: rgba(64, 68, 85, 0.9); }

.boatcalendar-gallery .thumb-carousel .carousel-arrow[disabled] { opacity: 0.25; }

.boatcalendar-gallery > figure { display: flex; width: 100%; padding: 0 0.25rem; }

.boatcalendar-gallery > figure a[data-gallery] { display: contents; }

.boatcalendar-gallery > figure img { flex: 1 0 100%; background-color: #ccc; width: 100%; height: auto; }

.boatcalendar-gallery > figure img._active { border: 2px solid #d5a574; }

.boatcalendar-gallery-thumb { display: grid; }

.boatcalendar-gallery-thumb > img, .boatcalendar-gallery-thumb a > img { width: 100%; height: 80px; padding: 5px; }

.boatcalendar-details { display: contents; }

@media (min-width: 992px) { .boatcalendar-details { display: flex; flex-direction: column; } }

.boatcalendar-info .info-label { opacity: 0.875; font-size: 0.9rem; }

.boatcalendar-info .info:not(:last-child) .info-value:after { content: ", "; margin-right: 0.25ch; }

.boatcalendar-description { font-size: 0.875rem; }

@media (min-width: 992px) { .boatcalendar-description { display: contents; font-size: 0.785rem; } }

.boatcalendar-description summary { display: block; text-align: center; text-transform: uppercase; font-size: 1.2079857543rem; }

.boatcalendar-description summary:before { content: attr(data-closed-text); }

@media (min-width: 992px) { .boatcalendar-description summary { display: none; } }

.boatcalendar-description[open] summary { margin-bottom: 0.7809rem; }

.boatcalendar-description[open] summary:before { content: attr(data-opened-text); }

.boatcalendar-title { border-bottom: 1px solid #d5a574; font-size: 1.66558526rem; order: -1; }

@media (min-width: 992px) { .boatcalendar-title { font-size: 1.4592295825rem; } }

@media (max-width: 991px) { .boatcalendar-buttons .btn--addtocart > span { transform: scale(1.12); } }

.boatcalendar-price { color: #d5a574; font-weight: 500; font-size: 1.8rem; line-height: 1; }

@media (min-width: 992px) { .boatcalendar-price { font-size: 2rem; } }

@keyframes boatCalendarEnter { from { transform: translateY(300px);
    opacity: 0; }
  to { transform: translateY(0vh);
    opacity: 1; } }

@keyframes boatCalendarLeave { from { transform: translateY(0%);
    opacity: 1; }
  to { transform: translateY(300px);
    opacity: 0; } }

@keyframes tripPriceLeave { from { transform: translateY(0%);
    opacity: 0; }
  to { transform: translateY(100%);
    opacity: 1; } }

.youtube-container img ~ iframe { display: none; }

.body-transfers #SliderWrapper { min-height: 35.7vh; height: auto; max-height: none; }

.body-transfers #SliderWrapper:before { background: rgba(64, 68, 85, 0.5); }

.body-transfers #SliderWrapper.slider-transfer-booking { background: #404455; }

.body-transfers #SliderWrapper.slider-transfer-booking:before { content: initial; display: none; }

.body-transfers #SliderWrapper .slider--content { flex-wrap: wrap; padding-top: 1.5618rem; padding-bottom: 1.5618rem; }

@media (min-width: 992px) { .body-transfers #SliderWrapper .slider--content { padding-top: 3.1236rem; padding-bottom: 3.1236rem; } }

.body-transfers #SliderWrapper .slider--content .slider--header { flex: 1 0 auto; position: relative; }

.body-transfers #SliderWrapper .slider--content .slider--header .icon { position: absolute; left: 0px; bottom: 100%; }

@media (max-width: 991px) { .body-transfers #SliderWrapper .slider--content .intro-transfers { margin: 0 -15px; background: rgba(0, 0, 0, 0.35); margin-bottom: -1.5618rem; padding: 1.5618rem; width: 100vw; margin-top: 1.5618rem; backdrop-filter: blur(10px); border-top: 4px solid #d5a574; border-bottom: 4px solid #d5a574; } }

@media (min-width: 992px) { .body-transfers #SliderWrapper .slider--content .intro-transfers { margin-bottom: 1.5618rem; } }

.body-transfers #SliderWrapper .slider--content .intro-transfers p { position: relative; display: flex; }

.body-transfers #SliderWrapper .slider--content .intro-transfers p:first-child { font-size: 1.4592295825rem; }

.body-transfers #SliderWrapper .slider--content .intro-transfers p:not(:first-child):before { margin-top: 0.25ex; box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); }

.body-transfers #SliderWrapper .slider--header--title { line-height: 1; margin: 1.5rem 0 0 0; }

.body-transfers #SliderWrapper .block-text, .body-transfers #SliderWrapper .block-booking { padding: 0; }

.body-transfers #SliderWrapper .block-text { order: 3; position: relative; }

@media (min-width: 992px) { .body-transfers #SliderWrapper .block-text { padding-right: 4.6854rem; order: -1; }
  .body-transfers #SliderWrapper .block-text:after { content: ""; position: absolute; top: 0; right: 3.1236rem; width: 1px; height: 100%; background: rgba(255, 255, 255, 0.5); } }

.transfer-icons { overflow-y: hidden; overflow-x: scroll; -webkit-overflow-scrolling: touch; background: #f7f7f7; margin-left: -1.5618rem; margin-right: -1.5618rem; width: 100vw; }

@media (min-width: 768px) { .transfer-icons { margin-right: 0; margin-left: 0; padding: 0; background: none; overflow-x: visible; overflow-y: visible; width: auto; } }

@media (min-width: 992px) { .transfer-icons { margin-top: 2.3427rem; } }

.transfer-icons--list { list-style: none; margin: 0; padding: 0; display: flex; flex-wrap: nowrap; padding: 1.5618rem; }

@media (min-width: 576px) { .transfer-icons--list { padding: 0; } }

.transfer-icons--item { flex: 0 0 calc(25% + 1.5618rem); display: flex; flex-direction: column; align-items: center; justify-content: flex-start; padding-right: 1.5618rem; }

@media (min-width: 992px) { .transfer-icons--item { flex: 1 0 25%; padding-right: 0; } }

.transfer-icons--icon { box-shadow: 0 0 0 4px #d5a574; width: 8.75rem; border: 1px solid white; border-radius: 100%; margin-bottom: 1rem; }

.transfer-icons--icon img { object-fit: contain; }

.transfer-icons--text { width: 8.75rem; color: #d5a574; font-weight: normal; font-size: -0.0625rem; text-align: center; line-height: 1.2; }

@media (max-width: 991px) { .transfer-tripadvisor { padding-left: 0; margin-bottom: -3.1236rem; }
  .transfer-tripadvisor .reviews-tripadvisor { box-shadow: 0 0 0 1rem #00AF87; } }

.transfer-tripadvisor #TripAdvisorReviews .TA_selfserveprop { width: 100%; margin-right: 0; margin-bottom: -1px; }

.transfer-tripadvisor #TripAdvisorReviews .TA_cdswritereviewlg { width: 100%; }

.transfer-tripadvisor #TripAdvisorReviews a { cursor: pointer; pointer-events: none !important; }

.body-transfers .ui-datepicker .tip-availabilities { display: block; width: 280px; font-size: 12px; text-align: right; }

.body-transfers .ui-datepicker .tip-availabilities:before { content: "■"; color: #479900; margin-right: 1ch; display: inline-block; }

.body-transfers .ui-datepicker .tip-availabilities:after { content: "available dates"; display: inline-block; }

.body-transfers .ui-datepicker table td a, .body-transfers .ui-datepicker table td span.ui-state-default { color: #090; }

.body-transfers .ui-datepicker table td .ui-state-active, .body-transfers .ui-datepicker table td .ui-state-hover, .body-transfers .ui-datepicker table td span.ui-state-hover { color: #fff; }

.body-transfers .ui-datepicker table td.ui-state-disabled .ui-state-default, .body-transfers .ui-datepicker table td.ui-datepicker-unselectable .ui-state-default { color: #999; }

.body-transfers .ui-datepicker table td.ui-state-disabled.fullybooked-date .ui-state-default, .body-transfers .ui-datepicker table td.ui-datepicker-unselectable.fullybooked-date .ui-state-default { cursor: default; }

#TransferBooking label { user-select: none; }

#TransferBooking .jblbooking--form { margin: 0 -15px; padding: 0; }

@media (min-width: 992px) { #TransferBooking .jblbooking--form { font-size: 0.9rem; } }

#TransferBooking .jblbooking--form > .jblbooking--group--title { margin-left: 15px; }

@media (min-width: 992px) { #TransferBooking .jblbooking--form > .jblbooking--group--title { margin-left: 0; margin-top: 15px; } }

#TransferBooking .jblbooking--group { margin-bottom: 15px; }

#TransferBooking .jblbooking--group--title { position: relative; padding-top: 0.39045rem; }

@media (min-width: 992px) { #TransferBooking .jblbooking--group--title { padding-top: 0; } }

#TransferBooking .jblbooking--group--swap { flex: 0 0 auto; display: flex; align-items: center; justify-content: center; }

#TransferBooking .jblbooking--group--swap._loading { opacity: 0.5; pointer-events: none; }

@media (min-width: 992px) { #TransferBooking .jblbooking--group--swap { margin: 0 -1rem; } }

#TransferBooking .jblbooking--group--swap .icon { fill: #fff; width: 2rem; height: 2rem; background: #d5a574; border-radius: 100%; padding: 3px; box-shadow: 0 0 0 2px #404455, 0 0 0 4px #d5a574; cursor: pointer; transform: rotate(90deg); }

@media (min-width: 992px) { #TransferBooking .jblbooking--group--swap .icon { transform: rotate(0deg); margin-top: 8px; } }

#TransferBooking .jblbooking--group--swap .icon svg { width: 100%; height: 100%; }

#TransferBooking .jblbooking--buttons { margin: 15px; margin-top: 1.5618rem; justify-content: center; }

#TransferBooking .jblbooking--buttons .btn.btn--booknow { width: 100%; }

@media (min-width: 992px) { #TransferBooking .jblbooking--buttons .btn.btn--booknow { width: auto; } }

#TransferBooking .group-field { flex-direction: column; }

@media (min-width: 992px) { #TransferBooking .group-field { flex-direction: row; margin-left: -15px; margin-right: -15px; } }

#TransferBooking .group-field .jblbooking--group { flex: 0 0 100%; }

@media (min-width: 992px) { #TransferBooking .group-field .jblbooking--group { flex: 1 0 50%; max-width: 50%; }
  #TransferBooking .group-field .jblbooking--group.col._col-45 { flex: 1 0 calc(50% - 2rem); max-width: calc(50% - 2rem); } }

@media (min-width: 992px) { #TransferBooking .group-field:has(.col._col-45) { justify-content: space-between; } }

#TransferBooking input#oneway ~ label { display: inline-flex; }

#TransferBooking input#oneway ~ label::before { margin-top: -1px; content: ""; }

#TransferBooking input#oneway:checked ~ label::before { content: ""; }

#TransferBooking .contact-link { color: #fff; line-height: 1; text-align: left; display: none; font-size: 12px; padding: 5px 7.5px; border-radius: 4px; background: rgba(0, 0, 0, 0.125); }

@media (min-width: 992px) { #TransferBooking .contact-link { display: block; } }

@media (min-width: 1200px) { #TransferBooking .contact-link { font-size: 14px; } }

#TransferBooking .contact-link:hover { text-decoration: underline; background: rgba(0, 0, 0, 0.875); color: #fff; }

@media (max-width: 991px) { body.slider-booking-steps #SliderWrapper { min-height: 100px; height: 10vh; max-height: none; } }

body.slider-booking-steps #SliderWrapper .slider--content { flex-direction: column; }

body.slider-booking-steps .booking-steps { display: none; }

@media (min-width: 992px) { body.slider-booking-steps .booking-steps { display: flex; } }

body.slider-booking-steps #modal-editbooking { margin-top: 3rem; }

body.slider-booking-steps #modal-editbooking .new-modal.modal-dialog .new-modal-content { background: rgba(0, 0, 0, 0.7); }

body.slider-booking-steps .modal-backdrop { background-color: #292C38; }

.transfer-summary .booking--payment-logos .credit-cards, #FormPurchase.transfers .booking--payment-logos .credit-cards { display: flex; justify-content: center; }

.transfer-summary .booking--payment-logos .credit-cards img, #FormPurchase.transfers .booking--payment-logos .credit-cards img { height: 26px; box-shadow: 0 0 1px rgba(0, 0, 0, 0.25); border-radius: 2px; }

.transfer-summary .booking--payment-logos .credit-cards img + img, #FormPurchase.transfers .booking--payment-logos .credit-cards img + img { margin-left: 5px; }

.transfer-summary .booking--payment-logos .credit-cards .securepayments--logos, #FormPurchase.transfers .booking--payment-logos .credit-cards .securepayments--logos { box-shadow: none; margin-left: 10px; }

.transfer-summary .booking--payment-logos .cancellation-policy, #FormPurchase.transfers .booking--payment-logos .cancellation-policy { color: #333; margin-top: 0.5rem; font-size: 0.86rem; text-align: center; }

.transfer-summary .booking--payment-logos .secure-payments, #FormPurchase.transfers .booking--payment-logos .secure-payments { margin-top: 0.5rem; text-align: center; }

.transfer-summary .booking--payment-logos .secure-payments img, #FormPurchase.transfers .booking--payment-logos .secure-payments img { width: 160px; }

.transfer-summary .edit-booking { position: absolute; top: 0; right: 0; border-radius: 1.35rem; font-size: 0.875rem; border-top-left-radius: 0; border-bottom-right-radius: 0; background: #404455; border-color: #404455; padding-top: calc($btn-padding-y + 10px); margin-top: -5px; }

.transfer-summary .edit-booking:hover { background: #404455; color: white; background: #5d606f; border-color: #5d606f; }

.transfer-summary--title { margin-top: -1rem; }

.transfer-summary--trip { margin: 1rem 0; }

.transfer-summary--trip--title { font-size: 1rem; text-transform: uppercase; color: rgba(64, 68, 85, 0.5); margin: 0 0 0.25rem 0; }

.transfer-summary--trip--details { font-size: 0.875rem; list-style: none; padding: 0; margin: 0; }

@media (min-width: 992px) { .transfer-summary--trip--details li { margin-top: 0.25ex; } }

.transfer-summary--trip--details li .remove-extra { cursor: pointer; color: #a03f4c; font-weight: bold; transition: color 0.15s ease-in-out; font-size: 0.875em; }

.transfer-summary--trip--details li .remove-extra:hover { color: #ff535b; }

.transfer-summary--trip--details li .price-extra { color: #d5a574; white-space: nowrap; }

.transfer-summary--trip--details li.transfer-extra { display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; }

.transfer-summary--trip--details li.transfer-extra .name { line-height: 1; flex: 1 0 100%; }

.transfer-summary--trip--details li.transfer-extra + li.transfer-extra { border-top: 1px solid #e2e2e2; padding-top: 5px; }

.transfer-summary--trip--details strong, .transfer-summary--trip--details span { color: #333; display: inline-block; }

.transfer-summary--trip--details strong { font-weight: 500; margin-right: 1ch; }

.transfer-summary--trip--details strong:after { content: ":"; }

.transfer-summary--trip.extra-stops .transfer-summary--trip--details { color: #404455; }

.transfer-summary--trip.extra-stops strong:after { content: initial; }

.transfer-summary--trip.extra-stops .stop-detail .name { line-height: 1.1; margin-bottom: 5px; }

.transfer-summary--trip.extra-stops .stop-detail .duration, .transfer-summary--trip.extra-stops .stop-detail .price { opacity: 0.75; font-weight: normal; font-size: 0.95em; line-height: 1.1; }

.transfer-summary--trip.extra-stops .price-stop { font-size: 1.2em; white-space: nowrap; }

.transfer-summary--trip + .transfer-summary--trip { margin-top: 1.5rem; }

.transfer-summary--carttotal { display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 0.875rem; text-transform: uppercase; line-height: 1; margin-left: -1.5618rem; margin-right: -1.5618rem; background: rgba(255, 255, 255, 0.5); padding: 1rem; border-top: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8; color: #c09568; text-shadow: 1px 1px 0 #fff; }

.transfer-summary--carttotal .price { padding: 0.5rem 0; font-size: 2.25em; color: #d5a574; }

.transfer-summary--carttotal small, .transfer-summary--carttotal .text-small { text-transform: none; color: #a0a2aa; }

.transfer-summary .booking--payment-logos { margin-top: 0.7809rem; }

#FormPurchase.transfers fieldset + fieldset { margin-top: 1.5rem; }

#FormPurchase.transfers fieldset + fieldset#fieldset-transfer_extraservices { margin-top: 0; margin-bottom: 3rem; }

#FormPurchase.transfers #fieldset-inbound_journey legend, #FormPurchase.transfers #fieldset-outbound_journey legend { margin-bottom: 0; border: 0; text-transform: capitalize; }

#FormPurchase.transfers #fieldset-inbound_journey .location-details, #FormPurchase.transfers #fieldset-outbound_journey .location-details { margin-bottom: 1rem; border-bottom: 1px solid #dbb389; text-transform: uppercase; color: #c09568; font-size: 0.875rem; }

#FormPurchase.transfers .form-control { width: 100%; }

#FormPurchase.transfers .form-group { margin-bottom: 1rem; }

#FormPurchase.transfers .form-group.button { text-align: right; }

#FormPurchase.transfers .form-group.textarea .form-control ~ label { display: block !important; }

#FormPurchase.transfers .form-group.textarea:not(.active) .form-control:not(:focus) ~ label, #FormPurchase.transfers .form-group.textarea:not(.filled) .form-control:not(:focus) ~ label { top: 1.5618em; }

#FormPurchase.transfers .form-group.textarea.filled .form-control ~ label, #FormPurchase.transfers .form-group.textarea .form-control:focus ~ label { top: 0 !important; }

#FormPurchase.transfers .button-block-wrapper { display: flex; flex-direction: column; margin-top: 0.7809rem; }

@media (min-width: 992px) { #FormPurchase.transfers .button-block-wrapper { align-content: center; justify-content: center; flex-direction: row; } }

#FormPurchase.transfers .button-block-wrapper .form-group.button { margin-bottom: 0; }

#FormPurchase.transfers .button-block-wrapper .separator { margin: 0.7809rem 0; display: flex; justify-content: center; font-size: 14px; color: #b9b9b9; }

@media (min-width: 992px) { #FormPurchase.transfers .button-block-wrapper .separator { margin: 0 1.5rem; align-items: center; background: linear-gradient(to bottom, #ccc 0%, #ccc 100%); background-repeat: no-repeat; background-position: 50% 50%; background-size: 1px 100%; } }

#FormPurchase.transfers .button-block-wrapper .separator > span { background: #fff; display: inline-block; line-height: 1; padding-bottom: 2px; }

#FormPurchase.transfers .btn { width: 100%; }

@media (min-width: 992px) { #FormPurchase.transfers .btn { width: auto; } }

#FormPurchase.transfers .btn.btn-addtocart { border-color: #d9ae82; background: #d5a574; }

#FormPurchase.transfers .btn.btn-addtocart:hover { background: #e7b37d; }

#FormPurchase.transfers .btn.btn-fastcheckout { border-color: #9eb677; background: #93AE68; color: #fff; }

#FormPurchase.transfers .btn.btn-fastcheckout:hover { color: #fff; background: #a6c970; }

#FormPurchase.transfers .booking--payment-logos { margin-top: 2rem; }

#FormPurchase.transfers .extra-service { display: flex; background: #f7f7f7; padding: 0.5rem 1rem; align-items: center; border: 2px solid #f2f2f3; }

#FormPurchase.transfers .extra-service + .extra-service { margin-top: 0.5rem; }

#FormPurchase.transfers .extra-service .field { order: -1; width: 4ch; display: flex; justify-content: center; align-items: center; margin-right: 1rem; }

#FormPurchase.transfers .extra-service .field input, #FormPurchase.transfers .extra-service .field select { display: block !important; position: relative; clip: auto; width: auto; height: auto; z-index: 1; }

#FormPurchase.transfers .extra-service .field select { background: #fff; border: 1px solid #ccc; line-height: 1; padding: 1px 6px 4px 6px; }

#FormPurchase.transfers .extra-service .service-icon { flex: 0 0 auto; order: 1; margin-right: 1rem; }

#FormPurchase.transfers .extra-service label { flex: 0 0 auto; order: 2; margin: 0; }

#FormPurchase.transfers .extra-service .price { flex: 1 0 auto; order: 100; color: #d5a574; display: flex; justify-content: flex-end; align-items: center; font-weight: 500; line-height: 1; }

#FormPurchase.transfers .extra-service .price > small, #FormPurchase.transfers .extra-service .price > .text-small { margin-left: 1ch; }

#FormPurchase.transfers .service-icon { display: flex; font-size: 1.25rem; }

#FormPurchase.transfers .service-icon:before { content: ""; display: inline-block; background: none 50% 50% no-repeat; background-size: contain; width: 1.5em; height: 1.5em; }

#FormPurchase.transfers .service-icon.baby-seat:before { background-image: svg-load("images/icons/extra-services/baby-seat.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.local-guide:before { background-image: svg-load("images/icons/extra-services/local-guide.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.mini-bar:before { background-image: svg-load("images/icons/extra-services/mini-bar.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.map-pin:before { background-image: svg-load("images/icons/extra-services/map-pin.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.sun:before { background-image: svg-load("images/icons/extra-services/sun.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.tickets:before { background-image: svg-load("images/icons/extra-services/tickets.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.vip-service:before { background-image: svg-load("images/icons/extra-services/vip-service.svg", fill=#d5a574); }

#FormPurchase.transfers .service-icon.wifi:before { background-image: svg-load("images/icons/extra-services/wifi.svg", fill=#d5a574); }

#TransferBookingSolution #FormTransferStops.solutions-form { margin-top: 1.5618rem; }

#FormTransferStops .transfer-stop { position: relative; flex: 0 0 100%; width: 100%; margin-bottom: 15px; }

@media (min-width: 992px) { #FormTransferStops .transfer-stop { flex-basis: calc(50% - 7.5px); width: calc(50% - 7.5px); } }

#FormTransferStops .transfer-stop:hover img { transform: scale(1.05) perspective(1px); }

#FormTransferStops .transfer-stop--photo { position: relative; box-shadow: 0 0 7.5px -4px rgba(0, 0, 0, 0.5); }

#FormTransferStops .transfer-stop--photo img { transition: all, 1.5s; transition-timing-function: cubic-bezier(easeOutQuint); -webkit-transform-origin: 25% 25% 50%; -moz-transform-origin: 25% 25% 50%; -ms-transform-origin: 25% 25% 50%; -o-transform-origin: 25% 25% 50%; transform-origin: 25% 25% 50%; }

#FormTransferStops .transfer-stop--body { position: absolute; bottom: 0; left: 0; right: 0; padding: 0.7rem 6.5rem 0.7rem 1rem; background: linear-gradient(to bottom, rgba(15, 15, 21, 0) 0%, rgba(15, 15, 21, 0.1) 15%, rgba(15, 15, 21, 0.5) 50%, rgba(15, 15, 21, 0.5) 75%, rgba(15, 15, 21, 0.75) 100%); color: #fff; transition: all 0.5s ease-in-out; text-shadow: 1px 1px 0 rgba(15, 15, 21, 0.5); }

#FormTransferStops .transfer-stop--body .duration { display: block; margin-top: 5px; }

#FormTransferStops .transfer-stop--body__title { color: #fff; margin: 0; line-height: 0.9; font-size: 1.2rem; }

#FormTransferStops .transfer-stop--body__title .icon { font-size: 3.375rem; margin-right: 1rem; margin-top: -2rem; }

#FormTransferStops .transfer-stop--body__btn-wrapper { position: absolute; right: 0.7rem; bottom: 0.7rem; }

#FormTransferStops .transfer-stop--body__btn-wrapper label { margin: 0; }

#FormTransferStops .transfer-stop--body__btn-wrapper input { position: absolute; top: 0; left: 0; }

#FormTransferStops .transfer-stop--details { margin-top: 0.5rem; display: block; color: #fff; text-decoration: underline; }

#FormTransferStops .transfer-stop--details:hover { color: #fff; font-style: italic; }

#FormTransferStops .transfer-stop--button { position: relative; background: #cf9860; border: 3px solid #ae7235; text-transform: uppercase; cursor: pointer; display: flex; flex-direction: column; align-items: center; }

#FormTransferStops .transfer-stop--button .label, #FormTransferStops .transfer-stop--button .value { padding: 0 0.7809rem; text-shadow: none; }

#FormTransferStops .transfer-stop--button.__stop-add .value { font-size: 1.5rem; background: #c27f3b; }

#FormTransferStops .transfer-stop--button.__stop-remove { padding-top: 0.3ex; background: #bc6c66; border-color: #b45b55; }

#FormTransferStops .transfer-stop--button, #FormTransferStops .transfer-stop--button .label, #FormTransferStops .transfer-stop--button .value { transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000); }

#FormTransferStops .transfer-stop--button:hover { transition-delay: 0; transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); }

#FormTransferStops .transfer-stop--button.__stop-add:hover { background: #c27f3b; }

#FormTransferStops .transfer-stop--button.__stop-remove:hover { background: #a74f49; }

#FormTransferStops .__stop-check ~ .__stop-remove { display: none; }

#FormTransferStops .__stop-check:checked ~ .__stop-add { display: none; }

#FormTransferStops .__stop-check:checked ~ .__stop-remove { display: flex; }

#TransferBookingSolution .booking--side > .booking--summary, #BookingSolutions .booking--side > .booking--summary { width: auto; position: relative !important; top: 0px !important; flex: 1 0 auto; }

#TransferBookingSolution .transfer-backdrop, #BookingSolutions .transfer-backdrop { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #292C38; opacity: 0.5; display: flex; align-items: center; flex-direction: column; padding-top: 10rem; }

#TransferBookingSolution .transfer-backdrop .transfer-loader, #BookingSolutions .transfer-backdrop .transfer-loader { animation: spin 7s infinite; font-size: 7.5em; width: 1em; height: 1em; line-height: 1; z-index: 101; }

#TransferBookingSolution .transfer-backdrop .transfer-loader.change, #BookingSolutions .transfer-backdrop .transfer-loader.change { top: 20%; }

#TransferBookingSolution .transfer-backdrop span, #BookingSolutions .transfer-backdrop span { color: #fff; font-size: 2rem; }

@media (min-width: 992px) { #TransferBookingSolution .booking--solutions { padding-right: 1.5618rem; } }

@media (min-width: 1200px) { #TransferBookingSolution .booking--solutions { padding-right: 0.7809rem; } }

@media (min-width: 1400px) { #TransferBookingSolution .booking--solutions { padding-right: 0; } }

#TransferBookingSolution .solutions-form { margin-top: 3.1236rem; }

#TransferBookingSolution .solutions-item { background: #f7f7f7; box-shadow: 0 0 0 3px #f2f2f3; border-radius: 1rem 1rem 1rem 3rem; justify-content: space-between; margin-bottom: 3.1236rem; position: relative; display: flex; flex-wrap: wrap; flex-grow: 1; max-width: none; padding-top: 0.7809rem; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item { border-radius: 1rem; padding-top: 0; width: auto; flex-grow: 1; max-width: inherit; flex-wrap: nowrap; } }

#TransferBookingSolution .solutions-item--photo { flex: 0 0 40%; position: relative; padding-left: 0.7809rem; margin-bottom: 1.5618rem; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item--photo { flex: 0 0 30%; width: 30%; max-width: 302px; max-height: 148px; margin-top: 0.39045rem; margin-bottom: 0.39045rem; } }

@media (min-width: 1200px) { #TransferBookingSolution .solutions-item--photo { flex: 0 0 35%; width: 35%; max-width: 302px; max-height: 148px; } }

#TransferBookingSolution .solutions-item--photo .embed-responsive { padding-top: 49%; }

#TransferBookingSolution .solutions-item--photo .embed-responsive img { object-fit: contain; }

#TransferBookingSolution .solutions-item--photo:after { content: "or similar"; position: absolute; bottom: 0px; right: 0.5em; color: #747474; font-size: 0.85em; z-index: 100; font-weight: bold; font-style: italic; text-shadow: 3px 0 0 #fff,-3px 0 0 #fff,0 3px 0 #fff,0 -3px 0 #fff,2px 2px #fff,-2px -2px 0 #fff,2px -2px 0 #fff,-2px 2px 0 #fff; }

#TransferBookingSolution .solutions-item--vehicledetail { flex: 0 0 60%; display: flex; flex-direction: column; justify-content: center; margin-bottom: 1.5618rem; padding-left: 0.7809rem; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item--vehicledetail { flex: 0.5 0 auto; margin-bottom: 0; padding: 0 1rem 0 0.25rem; } }

#TransferBookingSolution .solutions-item--vehicledetail--title { font-size: 1.25rem; color: #d5a574; margin: 0 0 0.39045rem 0; width: min-content; margin-top: -1px; max-width: 35vw; text-overflow: ellipsis; overflow: hidden; letter-spacing: -0.5px; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item--vehicledetail--title { font-size: 1.15rem; letter-spacing: 0; } }

#TransferBookingSolution .solutions-item--vehicledetail ul { margin: 0; padding: 0 0 0 0.5rem; list-style: square; list-style-position: inside; font-size: 0.875rem; color: #d5a574; }

#TransferBookingSolution .solutions-item--vehicledetail ul span { color: #404455; }

#TransferBookingSolution .solutions-item--vehicledetail ul span:first-child { margin-left: -5px; }

#TransferBookingSolution .solutions-item--vehicledetail ul .label:after { content: ":"; }

#TransferBookingSolution .solutions-item--vehicledetail ul .value { font-weight: bold; }

#TransferBookingSolution .solutions-item--vehicledetail--info { color: #404455; position: absolute; top: 100%; font-size: 11px; margin-top: 0.5rem; text-transform: uppercase; display: flex; align-items: center; line-height: 1; }

#TransferBookingSolution .solutions-item--vehicledetail--info a { color: inherit; display: inline-block; padding-bottom: 1px; }

#TransferBookingSolution .solutions-item--vehicledetail--info:before { width: 1.25em; height: 1.25em; margin-right: 5px; }

#TransferBookingSolution .solutions-item--withdrawal { position: relative; flex: 0 0 86px; min-width: 86px; color: #b58c63; font-size: 0.75rem; text-transform: uppercase; }

#TransferBookingSolution .solutions-item--withdrawal .wrapper { position: absolute; top: 0px; left: 0px; background: #fff; border-radius: 50%; display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: center; align-items: center; line-height: 1; padding-top: 0.25rem; box-shadow: 0 0 0 3px #d5a574, 0 0 0 7px #fff, 0 0 0 8px #f2f2f2; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item--withdrawal .wrapper { box-shadow: 0 0 0 2px #FFF, 0 0 0 11px #f7f7f7, 0 0 0 15px #FFF, 2px 0 0 17px #f2f2f3; } }

#TransferBookingSolution .solutions-item--withdrawal .time { display: inline-block; text-transform: none; font-weight: bold; color: #d9ae82; line-height: 1; font-size: 1.75em; margin-top: 0.25rem; }

#TransferBookingSolution .solutions-item--withdrawal:before { content: ""; display: block; height: 0; width: 100%; padding-top: 100%; }

#TransferBookingSolution .solutions-item--bookprice { flex: 1 0 auto; display: flex; flex-direction: column; margin-left: -2rem; color: #fff; }

@media (min-width: 992px) { #TransferBookingSolution .solutions-item--bookprice { flex: 0.25 0 auto; } }

#TransferBookingSolution .solutions-item--bookprice .solution-price { flex: 0 0 50%; background: #404455; background: linear-gradient(to right, #404455 0%, #535766 calc(25% + 2rem), #404455 100%); display: flex; flex-direction: column; justify-content: center; align-items: center; padding-left: 2rem; border-top-right-radius: 1rem; text-transform: uppercase; font-size: 0.85rem; line-height: 1; color: #fff; }

#TransferBookingSolution .solutions-item--bookprice .solution-price .price { color: #dbb389; font-size: 1.5em; margin-top: 4px; margin-bottom: -1.5px; }

#TransferBookingSolution .solutions-item--bookprice .cta { flex: 0 0 50%; background: #d5a574; background: linear-gradient(to right, #d5a574, #d7aa7b calc(25% + 2rem), #d5a574 100%); display: flex; padding-left: 2rem; border-bottom-right-radius: 1rem; text-transform: uppercase; }

#TransferBookingSolution .solutions-item--bookprice .cta:hover { background: #d9ae82; background: linear-gradient(to right, #dbb389 0%, #d9ae82 calc(25% + 2rem), #dbb389 100%); }

#TransferBookingSolution .solutions-item--bookprice .cta .btn { display: flex; justify-content: center; align-items: center; flex: 1 1 auto; background: transparent; border-radius: 0; border: 0; }

#TransferBookingSolution .fragment-faq { margin-top: 2rem; padding-top: 3rem; border-top: 1px solid #D6D6D6; }

.popular-routes { border-top: 1px solid #D6D6D6; }

.popular-routes-list { display: grid; grid-template-columns: 1fr; gap: 1rem; list-style: none; margin: 0.25rem 0 0 0; padding: 0; font-size: 1.125rem; gap: 0.75rem; }

@media (min-width: 992px) { .popular-routes-list { margin-top: 0.5rem; grid-template-columns: repeat(2, minmax(0, auto)); justify-content: space-between; gap: 0.75rem 1.5rem; } }

.popular-routes-list li { list-style: none; margin: 0; padding: 0; font-size: 1.125rem; }

.popular-routes-list a { color: #d5a574; font-weight: 500; }

.popular-routes-list a .text { text-decoration: underline; text-underline-offset: 4px; }

.popular-routes-list a:hover { color: #d5a574; font-weight: 700; }

#BookingSolutions .transfer--route-itinerary, #TransferBookingSolution .transfer--route-itinerary { background: #f7f7f7; margin-top: 1.5618rem; border-radius: 1.5rem; padding: 0.7809rem; }

#BookingSolutions .transfer--route-itinerary--title, #TransferBookingSolution .transfer--route-itinerary--title { text-align: center; margin-top: 0.7809rem; margin-bottom: 0.39045rem; }

#BookingSolutions .transfer--route-itinerary p, #TransferBookingSolution .transfer--route-itinerary p { font-size: 0.875rem; margin: 0rem 0 1rem 0; text-align: center; line-height: 1.4; }

#BookingSolutions .transfer--route-itinerary p small, #BookingSolutions .transfer--route-itinerary p .text-small, #TransferBookingSolution .transfer--route-itinerary p small, #TransferBookingSolution .transfer--route-itinerary p .text-small { opacity: 0.8; }

#BookingSolutions .transfer--route-itinerary img, #TransferBookingSolution .transfer--route-itinerary img { border-radius: 0.75rem; background: #d5a574; color: transparent; }

#TransferStopDetail .transferstop-description .duration { font-size: 1.1rem; }

#TransferStopDetail .transferstop-description p { margin-bottom: 1rem; }

#TransferStopDetail .transferstop-header { color: #d5a574; }

#TransferStopDetail .transferstop-photo { background-color: #d5a574; margin: 1.5rem 0; position: relative; }

#TransferStopDetail .transferstop-gallery { margin: 0.25rem 0 0 0; padding-left: 2rem; }

#TransferStopDetail .transferstop-gallery.gallery__grid { grid-template-columns: repeat(3, 1fr); }

#TransferStopDetail .transferstop-gallery.gallery__grid .gallery-item:first-child { grid-row: span 3; }

.transfer-faq-footer-wrapper { background: #f7f7f7; padding-left: 1rem; padding-right: 1rem; }

.transfer-faq-footer-wrapper .transfer-faq { justify-content: center; }

#TransferBookingBar { box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); border-top: 4px solid #d5a574; background: #242731; color: #fff; text-align: center; position: fixed; bottom: 0px; left: 0px; width: 100%; z-index: 90; }

@supports (padding-bottom: constant(safe-area-inset-bottom)) { #TransferBookingBar { --safe-area-inset-bottom: constant(safe-area-inset-bottom); border-bottom: var(--safe-area-inset-bottom, 0) solid #242731; } }

@supports (padding-bottom: env(safe-area-inset-bottom)) { #TransferBookingBar { --safe-area-inset-bottom: env(safe-area-inset-bottom); border-bottom: var(--safe-area-inset-bottom, 0) solid #242731; } }

@media (min-width: 992px) { #TransferBookingBar { background: transparent; position: static; width: auto; border: 0; flex-direction: column; color: currentColor; box-shadow: none; } }

@media (max-width: 991px) { #TransferBookingBar .transfer-summary--carttotal { background: none; border: 0; margin: 0; padding: 0; text-shadow: none; }
  #TransferBookingBar .transfer-summary--carttotal .price { line-height: 1; padding: 0; } }

.transfer-map-reviews #TripAdvisorReviews .TA_selfserveprop { width: 100% !important; margin-right: 0; margin-bottom: -1px; }

.transfer-map-reviews #TripAdvisorReviews .TA_cdswritereviewlg { width: 100%; }

.transfer-map-reviews #TripAdvisorReviews a { cursor: pointer; pointer-events: none !important; }

.body-reviews .section-header, .body-reviews .section-subheader { text-align: center; }

.body-reviews .reviews-tripadvisor { background: #fff; padding-bottom: calc(1rem + 1vw + 1vh); }

.body-reviews #Gallery { padding-top: calc(1rem + 1vw + 1vh); padding-bottom: calc(1rem + 1vw + 1vh); }

#TripAdvisorReviews { display: flex; flex-direction: row; }

#TripAdvisorReviews .TA_selfserveprop, #TripAdvisorReviews .TA_cdswritereviewlg { flex: 1 1 auto; display: flex; background: #fff; border: 3px solid #00AF87; padding: 2px; }

#TripAdvisorReviews .TA_selfserveprop a, #TripAdvisorReviews .TA_cdswritereviewlg a { cursor: pointer; pointer-events: none !important; }

#TripAdvisorReviews .TA_selfserveprop > img, #TripAdvisorReviews .TA_cdswritereviewlg > img { display: none !important; }

#TripAdvisorReviews .TA_selfserveprop .widSSP, #TripAdvisorReviews .TA_cdswritereviewlg .widWRL { display: flex; width: 100% !important; max-width: none !important; height: inherit !important; flex: 1 1 auto; }

#TripAdvisorReviews .TA_selfserveprop .widSSP .widSSPData { width: 100%; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRL .widWRLData { flex: 1 1 auto; width: 100% !important; height: 100% !important; max-height: none !important; }

#TripAdvisorReviews .TA_selfserveprop { width: calc(70% - 2em); min-height: 400px; }

#TripAdvisorReviews .TA_selfserveprop .widSSPBranding { border: 0 !important; }

#TripAdvisorReviews .TA_selfserveprop .widSSPTagline { display: none !important; }

#TripAdvisorReviews .TA_cdswritereviewlg { width: 30%; min-height: 315px; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRLLink { display: none !important; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRL .widWRLData { display: flex; flex-direction: column; padding-bottom: 10px; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRL .widWRLData form { display: flex; flex-direction: column; flex: 1 0 auto; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRL .widWRLData form .widWRLReview { flex: 1 0 auto; display: flex; height: 77%; max-height: none; min-height: 0; }

#TripAdvisorReviews .TA_cdswritereviewlg .widWRL .widWRLData form .widWRLReview textarea { flex: 1 0 auto; height: auto; min-width: 0 !important; min-height: 0 !important; max-width: none !important; max-height: none !important; width: 100% !important; }

#TripAdvisorReviews .widSSPReviews .widSSPOneReview .widSSPBullet > li { margin-top: 11px !important; }

#TripAdvisorReviews .widSSPReviews .widSSPOneReview .widSSPBullet > li a { display: none !important; }

#TripAdvisorReviews .widSSPReviews .widSSPOneReview .widSSPBullet .widSSPDate { font-size: 13px !important; letter-spacing: 0 !important; line-height: 1.4 !important; color: #666; }

#TripAdvisorReviews .widSSPReviews .widSSPOneReview .widSSPBullet .widSSPQuote { font-family: 'MontRat', sans-serif !important; font-size: 14px !important; letter-spacing: 0 !important; line-height: 1.4 !important; }

.section-contacts { padding-bottom: 0; }

.body-contacts .ajax-request.content { margin-bottom: 3.1236rem; }

.section-bookings { padding-bottom: 0; }

.section-bookings .content { display: flex; }

.section-bookings .bookings-content, .section-bookings .bookings-photo { flex: 1 1 50%; }

.section-bookings .bookings-content { padding-top: calc(1rem + 1vw + 1vh); }

.contacts-block { background-color: rgba(255, 255, 255, 0.9); border-top: 4px solid #d5a574; }

.contacts-block--title { font-size: 1.2079857543rem; line-height: 1.2; color: #c09568; }

.contacts-block--list { list-style: none; margin: 0; padding: 0; margin: 0; }

.contacts-block--list-item { display: flex; align-items: center; margin-bottom: 2px; }

.contacts-block--list-item:last-child { margin-bottom: 0; }

.contacts-block--list-item span { font-weight: 500; }

#MultiForm .nav.nav-tabs { padding: 0; margin-top: 0; margin-bottom: 0; border: 0; }

#MultiForm .nav.nav-tabs .tab-item { padding: 1rem calc(2rem + 1vw); background: #d5a574; color: #fff; border-top: 8px solid #fff; font-size: 1.3rem; }

#MultiForm .nav.nav-tabs .tab-item + .tab-item { margin-left: 1rem; }

#MultiForm .nav.nav-tabs .tab-item.active { background: #fff; color: #d5a574; border-color: #d5a574; }

#MultiForm .tabs-contact { padding-top: calc(1.5rem + 1.5vw + 1.5vh); background-color: #d5a574; }

#MultiForm .multiform-form-container { background: #fff; padding: calc(1rem + 1vw + 1vh) 0; }

.contacts-slider { background: #d5a574; }

#GuestbookMessages { position: relative; }

#GuestbookMessages .wrapper_pagination { margin-left: auto; margin-right: auto; }

.message-guestbook { position: relative; z-index: 1; background: #fff; margin-bottom: 0.9375em; margin-top: 0.9375em; padding-top: 0.9375em; border-top: double 4px #dbdbdb; }

.message-guestbook .wrapper { position: relative; max-width: 100%; }

.message-guestbook .wrapper > .post-info { max-width: 100%; margin-bottom: 1em; }

.message-guestbook .photo-block { width: 18.75em; position: relative; border: solid white 0.25em; max-height: 25em; }

.message-guestbook .photo-block a { display: block; width: 100%; overflow: hidden; }

.message-guestbook .photo-block img { width: 100%; height: 12.5em; object-fit: cover; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.message-guestbook .post-info { position: relative; font-size: 1.2079857543rem; padding-top: 1.5618rem; padding-bottom: 1.5618rem; color: #404455; }

.message-guestbook .post-info > small, .message-guestbook .post-info > .text-small { font-size: 0.85em; color: #d5a574; position: relative; }

.message-guestbook .post-info .post-author { position: relative; display: block; }

.message-guestbook .description { width: 100%; margin-top: 0.5em; margin-bottom: 0; }

.message-guestbook .reply { width: 100%; background: #fff; font-size: 0.078125em; border-color: #d5a574; margin-bottom: 0; margin-top: 1em; }

.message-guestbook .reply .author-reply { color: #d5a574; font-style: italic; }

.message-guestbook .reply .author-reply { font-style: italic; }

.message-guestbook .reply .staff { font-variant: small-caps; font-style: italic; }

.guestbook-form-wrapper { background: rgba(255, 255, 255, 0.9); padding: 30px; height: 100%; }

#FormGuestbook { margin-top: 1.5618rem; width: 100%; }

#FormGuestbook .text-telephone { display: none; }

#LeaveReview { background-color: #404455; box-shadow: 0px -10px 20px -10px rgba(38, 38, 38, 0.5), inset 0px -10px 30px -10px rgba(38, 38, 38, 0.5); padding-bottom: 1.875em; }

.yachts__gallery-blended .yacht-item { background-color: #f7f7f7; }

@media (max-width: 991px) { .yachts__gallery-blended .yacht-item .content { padding-right: 0; padding-left: 0; } }

.yachts__gallery-blended .yacht-item:nth-child(even) { background-color: #ebebeb; }

.yachts__gallery-blended .yacht-item-title { color: #d5a574; border-bottom: 2px solid #d5a574; padding-bottom: 0.4rem; margin-bottom: 0.75rem; }

@media (min-width: 992px) { .yachts__gallery-blended .yacht-item-title { padding-bottom: 0.8rem; margin-bottom: 1.5rem; } }

.yachts__gallery-blended .yacht-item-description { font-size: 0.85rem; }

.yachts__gallery-blended .yacht-item-description p, .yachts__gallery-blended .yacht-item-description p + ul, .yachts__gallery-blended .yacht-item-description p + ol { margin-bottom: 0.7809rem; }

.yachts__gallery-blended .yacht-item-description p:last-child, .yachts__gallery-blended .yacht-item-description ul:last-child, .yachts__gallery-blended .yacht-item-description ol:last-child { margin-bottom: 0; }

.yachts__gallery-blended .yacht-item-description strong { font-weight: 500; }

.yachts__gallery-blended .yacht-item--btn-wrapper { flex: 0 0 100%; width: 100%; display: flex; justify-content: flex-end; flex-direction: column; margin-top: 1.5618rem; }

@media (min-width: 992px) { .yachts__gallery-blended .yacht-item--btn-wrapper { flex: 0 0 auto; width: fit-content; } }

.yachts__gallery-blended .yacht-item--button { flex: 0; width: fit-content; text-transform: uppercase; }

.yachts__gallery-blended .yacht-item-gallery { display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 0.25rem; order: -1; margin-bottom: 1.5618rem; }

@media (min-width: 992px) { .yachts__gallery-blended .yacht-item-gallery { width: auto; max-width: 100%; order: 2; margin: 0; } }

.yachts__gallery-blended .yacht-item-gallery .embed-responsive { background-color: #b9b9b9; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item { padding: 0; margin: 0; width: 100%; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item::before { padding-top: 66%; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item:first-child { grid-row: 1 / span 1; grid-column: 1 / span 3; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item-link:hover img { transform: scale(1.15); }

.yachts__gallery-blended .yacht-item-gallery .gallery-item-img { object-fit: cover; -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000); -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000); -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000); transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000); -webkit-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000); -moz-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000); -ms-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000); -o-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000); transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000); -webkit-transform-origin: 25% 25% 50%; -moz-transform-origin: 25% 25% 50%; -ms-transform-origin: 25% 25% 50%; -o-transform-origin: 25% 25% 50%; transform-origin: 25% 25% 50%; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item-additional { display: block; width: 100%; height: 100%; position: absolute; right: 0; top: 0; background: rgba(0, 0, 0, 0.5); color: #fff; z-index: 1; }

.yachts__gallery-blended .yacht-item-gallery .gallery-item-additional span { display: flex; align-items: center; justify-content: center; font-size: 1.66558526rem; line-height: 1; text-shadow: 1px 1px 0 black; height: inherit; }

.yachts__gallery-blended .yacht-item-gallery #infscr-loading { grid-column: 1 / span 3; }

.yachts__gallery-blended .yacht-item-bottom { background-color: #c78745; color: #fff; }

.yachts__gallery-blended .yacht-item-bottom--title { color: #fff; border-bottom: 1px solid rgba(255, 255, 255, 0.5); padding-bottom: 0.5rem; margin-bottom: 1rem; }

@media (max-width: 1199.98px) { .yachts__gallery-blended .yacht-item-bottom--list { margin-bottom: 1rem; } }

.yachts__gallery-blended .yacht-item-bottom--list ul { list-style: square; }

#FormRequestYacht .textarea-note label, #FormRequestBoat .textarea-note label { display: block; font-size: 0.85em; color: #d5a574; margin-bottom: 0.125rem; text-align: left; }

#FormTourOfItaly fieldset { margin-top: 2rem; }

#FormTourOfItaly fieldset:first-child { margin-top: 0; }

#FormTourOfItaly fieldset#fieldset-group2b { margin-top: 0; }

#FormTourOfItaly legend { line-height: 1.4; margin-top: 0; font-size: 1.35rem; }

@media (min-width: 992px) { #FormTourOfItaly legend { font-size: 1.5rem; } }

#FormTourOfItaly .form__display-group { margin-bottom: 0; }

#FormTourOfItaly .form__display-group.form__display-group--1col, #FormTourOfItaly .form__display-group.form__display-group--1col .form-group { margin-right: 0; }

#FormTourOfItaly #destinations { margin-top: 1.5618rem; margin-right: -15px; margin-left: -15px; }

#FormTourOfItaly #destinations .destinations_item { margin-bottom: 1.5618rem; }

#FormTourOfItaly #destinations .destinations_item figcaption { padding: 5px; }

#FormTourOfItaly #destinations .destinations_item .img-fluid { width: 100%; flex: 0 0 100%; }

#FormTourOfItaly #destinations .destinations_item > label { display: block; cursor: pointer; }

#FormTourOfItaly #destinations .destinations_item strong { color: #535766; }

#FormTourOfItaly #destinations .destinations_item strong:before { content: ""; margin-right: 0.75ch; }

#FormTourOfItaly #destinations .destinations_item input:checked ~ div > figure { box-shadow: 0 0 0 1px #fff, 0 0 0 3px #d5a574; filter: saturate(1.2) brightness(1.15); }

#FormTourOfItaly #destinations .destinations_item input:checked ~ strong { color: #d5a574; }

#FormTourOfItaly #destinations .destinations_item input:checked ~ strong:before { content: ""; }

#FormTourOfItaly #destinations .selection-required, #FormTourOfItaly #hotelcategory .selection-required, #FormTourOfItaly #rooms .selection-required { flex: 1 0 100%; width: 100%; padding: 0 1rem; }

#FormTourOfItaly #destinations .selection-required .error, #FormTourOfItaly #hotelcategory .selection-required .error, #FormTourOfItaly #rooms .selection-required .error { padding: 0.75rem; margin-bottom: 30px; }

#FormTourOfItaly #destinations .selection-required .error:before, #FormTourOfItaly #hotelcategory .selection-required .error:before, #FormTourOfItaly #rooms .selection-required .error:before { content: unset; display: none; }

#FormTourOfItaly #destinations .selection-required input, #FormTourOfItaly #hotelcategory .selection-required input, #FormTourOfItaly #rooms .selection-required input { position: absolute; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0; }

#FormTourOfItaly #hotelcategory .selection-required .error, #FormTourOfItaly #rooms .selection-required .error { margin-top: 15px; margin-bottom: 10px; }

#FormTourOfItaly #hotelcategory .selection-required { padding-left: 0; padding-right: 0; }

#FormTourOfItaly #hotel_category { margin-right: -15px; margin-left: -15px; }

#FormTourOfItaly #hotel_category .hotelcategory_item > label { display: block; cursor: pointer; }

#FormTourOfItaly #hotel_category strong { display: flex; cursor: pointer; }

#FormTourOfItaly #hotel_category strong:before { flex: 0 0 auto; content: ""; margin-right: 1ch; }

#FormTourOfItaly #hotel_category strong span { display: block; line-height: 1.1; font-size: 0.9rem; }

#FormTourOfItaly #hotel_category img { filter: grayscale(100%) invert(100%); opacity: 0.5; transform: all 0.2s ease-in-out; }

#FormTourOfItaly #hotel_category input[type="radio"] { position: absolute; bottom: calc(5px + 1em); left: calc(5px + 1ch); width: 1px; height: 1px; visibility: hidden; pointer-events: none; clip: rect(0, 0, 0, 0); }

#FormTourOfItaly #hotel_category input:checked + div img { filter: grayscale(0%) invert(0%); opacity: 1; }

#FormTourOfItaly #hotel_category input:checked + div strong:before { content: ""; }

#FormTourOfItaly .how-many-rooms { margin-top: 1rem; }

#FormTourOfItaly .how-many-rooms .form-group.flex.column { padding-right: -15px; }

#FormTourOfItaly .how-many-rooms img { filter: grayscale(100%) invert(100%); opacity: 0.5; }

#FormTourOfItaly .how-many-rooms .choosen img { filter: grayscale(0%) invert(0%); opacity: 1; }

#FormTourOfItaly .form-group > label:not(.radio):first-child { font-weight: 500; }

#FormTourOfItaly .form-group.radio-do_you_know_dates { flex: 0 0 100%; max-width: none; }

#FormTourOfItaly .form-group.radio { flex-wrap: wrap; }

#FormTourOfItaly .form-group.radio > label:first-child { flex: 0 0 100%; }

#FormTourOfItaly .form-group.radio label.radio { cursor: pointer; margin-right: 1rem; font-size: 0.95rem; }

#FormTourOfItaly .form-group.radio label.radio input[type="radio"] { position: absolute; top: 100%; left: 0%; width: 1px; height: 1px; visibility: hidden; pointer-events: none; clip: rect(0, 0, 0, 0); }

#FormTourOfItaly .form-group.radio label.radio input[type="radio"] ~ span:before { content: ""; margin-right: 0.5ch; }

#FormTourOfItaly .form-group.radio label.radio input[type="radio"]:checked ~ span:before { content: ""; }

#FormTourOfItaly .form-group.radio label.radio + label.radio { margin-right: 1rem; }

#FormTourOfItaly .form-group.datepicker:after, #FormTourOfItaly .form-group.calendar:after, #FormTourOfItaly .form-group.select:after { margin-top: 0; transform: translateY(-50%); }

#FormTourOfItaly .form-group.roomselect { flex-basis: auto; max-width: none; display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 0.7809rem; }

@media (min-width: 992px) { #FormTourOfItaly .form-group.roomselect { margin-bottom: 0; flex-direction: row; } }

#FormTourOfItaly .form-group.roomselect label { margin-right: 1ch; margin-bottom: 0; }

#FormTourOfItaly .form-group.html5range { padding-bottom: 1rem; }

#FormTourOfItaly .form-group.html5range label .value { display: inline-block; margin-right: 1ch; color: #d5a574; font-size: 1.1em; }

#FormTourOfItaly .form-group.html5range label .value:before { content: ": "; color: #404455; font-size: 1rem; }

#FormTourOfItaly .form-group.html5range .html-range { position: relative; padding: 0.5ex 0; }

#FormTourOfItaly .form-group.html5range .html-range:before, #FormTourOfItaly .form-group.html5range .html-range:after { position: absolute; top: 100%; font-size: 0.8rem; opacity: 0.75; }

#FormTourOfItaly .form-group.html5range .html-range:before { content: "€ " attr(data-min) " per person"; left: 0; text-align: left; }

#FormTourOfItaly .form-group.html5range .html-range:after { content: "€ " attr(data-max) " per person"; right: 0; text-align: right; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"] { appearance: none; padding: 0; margin: 0; border: 0; background: none; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-webkit-slider-runnable-track { margin: 10px 0; width: 100%; margin-top: .5rem; height: 0.25rem; cursor: pointer; animate: 0s; box-shadow: none; background: #f7f7f7; box-shadow: none; border: 1px solid #ced4da; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-webkit-slider-thumb { box-shadow: none; border: 0; height: 1.25rem; width: 1.25rem; border-radius: 50%; background: #d5a574; border: 2px solid #d5a574; cursor: pointer; margin-top: -0.5rem; -webkit-appearance: none; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-moz-range-track { margin: 10px 0; width: 100%; margin-top: .5rem; height: 0.25rem; cursor: pointer; animate: 0s; box-shadow: none; background: #f7f7f7; box-shadow: none; border: 1px solid #ced4da; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-moz-range-thumb { box-shadow: none; border: 0; height: 1.25rem; width: 1.25rem; border-radius: 50%; background: #d5a574; border: 2px solid #d5a574; cursor: pointer; margin-top: -0.5rem; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-ms-track { margin: 10px 0; width: 100%; margin-top: .5rem; height: 0.25rem; cursor: pointer; animate: 0s; box-shadow: none; background: #f7f7f7; box-shadow: none; border: 1px solid #ced4da; width: calc(100% - 2px); margin-top: 0rem; position: relative; z-index: 10; }

#FormTourOfItaly .form-group.html5range .html-range input[type="range"]::-ms-thumb { box-shadow: none; border: 0; height: 1.25rem; width: 1.25rem; border-radius: 50%; background: #d5a574; border: 2px solid #d5a574; cursor: pointer; margin-top: -0.5rem; }

#FormTourOfItaly .age-children .form-group { margin-right: 15px; }

#FormTourOfItaly .age-children .form-group.select:after { content: initial; display: none; }

#FormTourOfItaly .age-children .form-group label { font-size: 0.9rem; }

#FormTourOfItaly .form-group.roomselect select.form-control, #FormTourOfItaly .age-children .form-group select.form-control { flex-basis: auto; width: auto; padding: 0 15px; line-height: 1; height: 2.5em; font-size: 0.8rem; appearance: menulist; }

#FormTourOfItaly .form-actions, #FormTourOfItaly .form-group.captcha { border-top: 1px solid #f0f0f0; padding-top: 2rem; margin-top: 2rem; }

.body-tours-of-italy #Gallery { background: #f7f7f7; padding-top: 3.1236rem; padding-bottom: 3.1236rem; margin-top: 3.1236rem; }

#MyCart .jblcart--details-box-price .remove, .shopping-cart-box-list .cart-item-remove, #Checkout .checkout--side-cart-list .cart-item-remove { cursor: pointer; color: #FF3943 !important; font-weight: bold; transition: color 0.15s ease-in-out; }

#MyCart .jblcart--details-box-price .remove:hover, .shopping-cart-box-list .cart-item-remove:hover, #Checkout .checkout--side-cart-list .cart-item-remove:hover { color: #ff535b !important; }

#MyCart .jblcart--details-box-price .remove:hover .title, .shopping-cart-box-list .cart-item-remove:hover .title, #Checkout .checkout--side-cart-list .cart-item-remove:hover .title { border-color: #ff535b; }

#MyCart .jblcart--details-box-price .remove:hover .icon, .shopping-cart-box-list .cart-item-remove:hover .icon, #Checkout .checkout--side-cart-list .cart-item-remove:hover .icon { color: #ff535b !important; }

#MyCart .jblcart--details-box-price .remove .title, .shopping-cart-box-list .cart-item-remove .title, #Checkout .checkout--side-cart-list .cart-item-remove .title { font-size: 0.9rem; padding-bottom: 0.5px; border-bottom: 1px solid transparent; }

#MyCart .jblcart--details-box-price .remove .icon, .shopping-cart-box-list .cart-item-remove .icon, #Checkout .checkout--side-cart-list .cart-item-remove .icon { margin-left: 0.5rem; color: #FF3943 !important; }

.body-cart #SliderWrapper { min-height: 35.7vh; height: auto; max-height: none; }

.body-cart #SliderWrapper:before { background: rgba(41, 44, 56, 0.4); mix-blend-mode: multiply; z-index: 10; }

.body-cart #SliderWrapper .slider--content .slider--header { flex: 1 0 auto; position: relative; border-right: 1px solid rgba(255, 255, 255, 0.5); }

.body-cart #SliderWrapper .slider--content .slider--header .icon { position: absolute; left: 0px; bottom: 100%; }

.body-cart #SliderWrapper .slider--content p { margin: 1.5rem 0 0 0; line-height: 1.1; position: relative; padding-left: 1.5rem; }

.body-cart #SliderWrapper .slider--content p:before { content: ""; position: absolute; top: 0.3rem; left: 0.2rem; width: 0.6rem; height: 0.6rem; background: #d5a574; border: 1px solid #fff; }

.body-cart #SliderWrapper .slider--header--title { line-height: 1; margin: 0; color: #fff; }

.body-cart .main { background: #404455; margin-bottom: 4px; }

@media (min-width: 992px) { #MyCart .jblcart--form { margin-left: -15px; margin-right: -15px; } }

#MyCart .jblcart--header--title { line-height: 1; margin: 0; color: #fff; }

#MyCart .jblcart--main { position: relative; }

@media (min-width: 992px) { #MyCart .jblcart--main { border-right: 1px solid rgba(255, 255, 255, 0.5); } }

@media (min-width: 992px) { #MyCart .jblcart--main:before { background: rgba(15, 15, 21, 0.5); content: ""; display: block; width: 100%; height: 100%; position: absolute; top: 0; right: 0; z-index: -1; width: 50vw; } }

#MyCart .jblcart--summary { display: flex; flex-direction: column; padding: 0 1vw; }

#MyCart .jblcart--summary.loading .jblcart--field { background: rgba(204, 204, 204, 0.95); overflow: hidden; }

#MyCart .jblcart--summary-heading .icon { font-size: 2.5rem; color: rgba(255, 255, 255, 0.5); }

#MyCart .jblcart--summary-pricing { line-height: 1.3; margin: 2rem 0; padding: 1.5rem 0; border-top: 1px solid rgba(255, 255, 255, 0.3); border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

#MyCart .jblcart--summary-pricing--intro { color: #fff; font-size: 1.6rem; }

#MyCart .jblcart--summary-pricing--finalprice { color: #d5a574; font-size: 1.8rem; text-decoration: none; }

#MyCart .jblcart--summary-pricing--finalprice .big { font-size: 2rem; }

#MyCart .jblcart--summary-pricing--finalprice .final-price { font-weight: 500; text-decoration: none; }

#MyCart .jblcart--summary-pricing--finalprice .old-price { font-weight: normal; font-size: 1.4rem; color: #ea7c7c; }

#MyCart .jblcart--summary-buttons .btn { display: flex; align-items: center; justify-content: center; padding: 0.5rem 0; box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.25); }

#MyCart .jblcart--summary-buttons .btn:hover { box-shadow: 0 0 10px -2.5px rgba(0, 0, 0, 0.5); }

#MyCart .jblcart--summary-buttons .btn--addtocart { border-color: #d9ae82; background: #d5a574; color: #fff; }

#MyCart .jblcart--summary-buttons .btn--addtocart:hover { color: #fff; background: #e7b37d; }

#MyCart .jblcart--summary-buttons .btn--booknow, #MyCart .jblcart--summary-buttons .btn--fastcheckout { border-color: #9eb677; background: #93AE68; color: #fff; }

#MyCart .jblcart--summary-buttons .btn--booknow:hover, #MyCart .jblcart--summary-buttons .btn--fastcheckout:hover { color: #fff; background: #a6c970; }

#MyCart .jblcart--summary-buttons .btn .icon { font-size: 1.35em; margin-right: 0.5ch; }

#MyCart .jblcart--summary-buttons .btn + .btn { margin-top: 0.75rem; }

#MyCart .jblcart--summary--discount-input { text-align: center; color: #fff; margin-top: 1rem; cursor: pointer; }

#MyCart .jblcart--summary--discount-input .discount-amount { display: block; color: #d5a574; }

#MyCart .jblcart--creditcards { margin-top: 2rem; display: flex; justify-content: center; }

#MyCart .jblcart--creditcards img { height: 26px; }

#MyCart .jblcart--creditcards img + img { margin-left: 5px; }

#MyCart .jblcart--creditcards img.securepayments--logos { height: 22px; margin-top: 2px; margin-left: 10px; }

#MyCart .jblcart--securepayments { margin-top: 0.5rem; text-align: center; }

#MyCart .jblcart--securepayments img { max-width: 160px; }

#MyCart .jblcart--promo { margin-top: 1rem; padding: 1rem; background: rgba(0, 0, 0, 0.25); border: 1px solid rgba(204, 204, 204, 0.25); border-radius: 2px; display: flex; flex-wrap: wrap; }

#MyCart .jblcart--promo-title { flex: 0 0 100%; margin: 0; margin-bottom: 0.25rem; font-size: 1.1rem; line-height: 1; color: rgba(255, 255, 255, 0.75); }

#MyCart .jblcart--promo-detail { border-radius: 0.125rem; display: flex; flex-direction: column; align-items: center; background: #d5a574; color: #fff; padding: 0.5rem 1rem 0.55rem 1rem; margin-right: 1rem; margin-top: 0.5rem; line-height: 1.1; }

#MyCart .jblcart--promo-detail .description { font-size: 0.8rem; }

#MyCart .jblcart--promo-detail .amount { font-size: 0.9rem; }

#MyCart .jblcart--details-box { padding: 1.5618rem 0.7809rem; background-color: rgba(41, 44, 56, 0.75); margin-left: calc(-15px - 1.5618rem); margin-right: calc(-15px - 1.5618rem); }

@media (min-width: 992px) { #MyCart .jblcart--details-box { margin-left: 0; margin-right: 0; margin-bottom: 1.5618rem; } }

#MyCart .jblcart--details-box-heading { position: relative; padding-left: 1.5rem; }

#MyCart .jblcart--details-box-heading:before { content: ""; position: absolute; top: 0.7rem; left: 0.2rem; width: 0.6rem; height: 0.6rem; background: #d5a574; border: 1px solid #fff; }

#MyCart .jblcart--details-box-heading .title { color: #fff; font-weight: bold; margin: 0; display: flex; align-items: center; }

#MyCart .jblcart--details-box-heading .title .icon { margin-left: 1rem; }

#MyCart .jblcart--details-box-heading .code { color: #a9a9a9; text-transform: uppercase; font-size: 0.9rem; }

#MyCart .jblcart--details-box-heading .link-list { color: #d5a574; text-transform: uppercase; font-size: 0.9rem; cursor: pointer; transition: color 0.15s ease-in-out; user-select: none; }

#MyCart .jblcart--details-box-heading .link-list:hover { color: #deb993; }

#MyCart .jblcart--details-box-price > .price { flex-direction: column; display: flex; flex: 0 0 auto; color: #d5a574; font-size: 1rem; }

#MyCart .jblcart--details-box-price > .price .final-price { text-decoration: none; }

#MyCart .jblcart--details-box-price > .price .big { font-size: 1.5rem; }

#MyCart .jblcart--details-box-price > .price .old-price { text-align: center; color: #999; font-size: 0.9rem; }

#MyCart .jblcart--details-box-list { transform-style: preserve-3d; transform: scale3d(1, 0, 1); transform-origin: 50% 0; transition-delay: 0s; transition: all 0.26s cubic-bezier(0.165, 0.840, 0.440, 1.000); opacity: 0; visibility: hidden; max-height: 0; width: 100%; }

#MyCart .jblcart--details-box-list.show { display: block; max-height: 1000px; transform: scale3d(1, 1, 1); opacity: 1; visibility: visible; }

#MyCart .jblcart--details-box-list .list { margin: 0; padding: 1rem 1.5rem; list-style: none; font-size: 0.85rem; color: #fff; }

#MyCart .jblcart--details-box-list .list li { display: flex; padding: 2px 5px; }

#MyCart .jblcart--details-box-list .list li.journey-details { padding: 1rem 0; flex-direction: column; }

#MyCart .jblcart--details-box-list .list li.journey-details .list { padding: 0; }

#MyCart .jblcart--details-box-list .list li + li { border-top: 1px solid rgba(255, 255, 255, 0.3); }

#MyCart .jblcart--details-box-list .list-label { flex: 1 1 180px; display: block; }

#MyCart .jblcart--details-box-list .list-value { flex: 2 1 50%; font-weight: 500; letter-spacing: 1px; }

#MyCart .jblcart--details-box-list .list li.extra_services .list-value span:not(:last-child):after { content: ", "; }

#MyCart .jblcart--details-box-list .list li.extra_services .list-value span:last-of-type:after { content: initial; }

#MyCart .jblcart--details-box-list .list li.extra_services .price { float: right; }

.shopping-cart-box { position: absolute; right: -1rem; top: 100%; width: 100vw; padding: 1.5618rem; background: rgba(41, 44, 56, 0.9); border-top: 8px solid #d5a574; z-index: 50; color: #fff; cursor: default; visibility: hidden; transform-origin: 50% 0; transform: rotateX(-90deg); transform-style: preserve-3d; backface-visibility: hidden; transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000); }

@media (min-width: 992px) { .shopping-cart-box { min-width: 28.125rem; width: auto; top: calc(100% + 1rem); box-shadow: 0 0 0px 1px rgba(213, 165, 116, 0.5), 2.5px 2.5px 7.5px 2.5px rgba(0, 0, 0, 0.25), 2.5px 5px 5px rgba(0, 0, 0, 0.5); background: rgba(41, 44, 56, 0.99); } }

.shopping-cart-box.opened { visibility: visible; transform: rotateX(0deg); transition-delay: 0; transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); }

.shopping-cart-box-list .cart-item { display: flex; align-items: flex-start; justify-content: space-between; padding: 0.5rem; }

.shopping-cart-box-list .cart-item-title { text-transform: none; margin: 0; }

.shopping-cart-box-list .cart-item-type { color: #a9a9a9; text-transform: uppercase; font-size: 0.8rem; }

.shopping-cart-box-list .cart-item-price { color: #d5a574; font-size: 0.8rem; }

.shopping-cart-box-list .cart-item-price .big { font-size: 1rem; }

.shopping-cart-box-list .cart-item-remove { text-transform: none; }

.shopping-cart-box-list .cart-item + .cart-item { border-top: 1px solid rgba(255, 255, 255, 0.3); }

.shopping-cart-box-total { border-top: 1px solid rgba(255, 255, 255, 0.3); text-align: center; padding: 1.17135rem 0; line-height: 1; letter-spacing: 0.5px; }

.shopping-cart-box-total .total-price { color: #d5a574; font-size: 1.5rem; font-weight: normal; text-decoration: none; margin-left: 1ch; }

.shopping-cart-box-total .total-price .big { font-size: 1.2em; }

.shopping-cart-box-total .old-price { color: #ea7c7c; }

.shopping-cart-box-total .old-price + .final-price { color: #a3df7d; }

.shopping-cart-box-buttons { display: flex; justify-content: space-around; }

.shopping-cart-box-buttons .btn { display: flex; align-items: center; justify-content: center; width: 48%; font-size: 0.875rem; }

.shopping-cart-box-buttons .btn--addtocart { border-color: #d9ae82; background: #d5a574; color: #fff; }

.shopping-cart-box-buttons .btn--addtocart:hover { color: #fff; background: #e7b37d; }

.shopping-cart-box-buttons .btn--fastcheckout { border-color: #9eb677; background: #93AE68; color: #fff; }

.shopping-cart-box-buttons .btn--fastcheckout:hover { color: #fff; background: #a6c970; }

.shopping-cart-box-buttons .btn .icon { font-size: 1.35em; margin-right: 0.5ch; color: #fff !important; }

.shopping-cart-box-footer { display: flex; flex-direction: column; align-items: center; margin-top: 1.5618rem; padding: 0 5px; }

.shopping-cart-box-footer-creditcards { display: flex; align-items: center; padding-top: 2px; }

.shopping-cart-box-footer-creditcards img { height: 1.4rem; display: block; flex: 0 1 auto; }

.shopping-cart-box-footer-creditcards img + img { margin-left: 0.5rem; }

.shopping-cart-box-footer-creditcards.securepayments--logos { height: 1.2rem; margin-left: 1.5618rem; }

.shopping-cart-box-footer-securepayments { text-align: center; margin-top: 0.5rem; }

.shopping-cart-box-footer-securepayments img { max-width: 160px; }

@media (max-width: 991px) { .shopping-cart__backdrop { position: fixed; top: 0px; left: 0px; width: 100vw; height: 100vh; background: rgba(255, 255, 255, 0.25); backdrop-filter: blur(10px); z-index: 90; animation-duration: 0.3s; animation-name: cartBackdropOpen; animation-play-state: running; animation-iteration-count: 1; } }

@keyframes cartBackdropOpen { from { opacity: 0; }
  to { opacity: 1; } }

#TransferBookingSolution .booking--wrapper, #BookingSolutions .booking--wrapper, #Checkout .checkout--wrapper { display: flex; flex-wrap: wrap; justify-content: space-between; }

@media (min-width: 992px) { #TransferBookingSolution .booking--wrapper, #BookingSolutions .booking--wrapper, #Checkout .checkout--wrapper { flex-wrap: nowrap; } }

#TransferBookingSolution .booking--solutions, #BookingSolutions .booking--solutions, #Checkout .checkout--main { position: relative; flex: 1 1 auto; }

@media (min-width: 1200px) { #TransferBookingSolution .booking--solutions, #BookingSolutions .booking--solutions, #Checkout .checkout--main { flex: 0.1 1 65%; padding-right: calc(1rem + 1vw + 1%); } }

#TransferBookingSolution .booking--side, #TransferBookingSolution .booking--summary, #BookingSolutions .booking--side, #BookingSolutions .booking--summary, #Checkout .checkout--side { flex: 1 0 100%; }

@media (min-width: 992px) { #TransferBookingSolution .booking--side, #TransferBookingSolution .booking--summary, #BookingSolutions .booking--side, #BookingSolutions .booking--summary, #Checkout .checkout--side { flex-basis: 33%; max-width: 400px; min-width: 320px; } }

#TransferBookingSolution .booking--side > .booking--summary, #BookingSolutions .booking--side > .booking--summary, #Checkout .checkout--side { background: #f7f7f7; border-top: 5px solid #404455; border-radius: 1.5rem; padding: 1.5618rem; top: 126px; left: 0px; align-self: flex-start; }

.transfer-summary--title, #Checkout .checkout--side-title { margin-bottom: 0.7809rem; /*@extend .h2; color: $color_bananagold; margin: 0.5rem 0 1rem 0; font-size: calc(1.5rem + 0.25vw); */ }

.payment-page { margin-top: 0; padding-top: 0; }

.payment-page:before { content: ""; display: block; background: #d5a574; position: absolute; top: 0; left: 0; width: 100%; height: 105px; z-index: -1; }

.payment-page-title { font-weight: normal; color: #fff; padding: 0; height: 105px; text-align: center; }

.payment-page-box { text-align: center; border-left: 3px solid; }

.payment-page-box p:last-child { margin-bottom: 2rem; }

.payment-page-icon { margin: 2rem; }

.payment-page-icon .icon { font-size: 8rem; /* &:before{ .payment-fail &{  background: unquote('-webkit-gradient(linear, left top, left bottom, from(#e90000), to(#600))'); -webkit-background-clip: text; -webkit-text-fill-color: transparent; } } &:before{ .payment-success &{ background: unquote('-webkit-gradient(linear, left top, left bottom, from(#00c900), to(#3aae15))'); -webkit-background-clip: text; -webkit-text-fill-color: transparent; } } */ }

.payment-page .alert.payment-page-box { background: none; box-shadow: none; border: 0; }

.payment-page .alert.payment-page-box:before { display: none; }

.payment-page .alert.payment-page-box > p { color: #666; }

.payment-page .alert-success { color: #3aae15; }

.payment-page .alert-error, .payment-page #FormTourOfItaly #destinations .selection-required .error, #FormTourOfItaly #destinations .selection-required .payment-page .error, .payment-page #FormTourOfItaly #hotelcategory .selection-required .error, #FormTourOfItaly #hotelcategory .selection-required .payment-page .error, .payment-page #FormTourOfItaly #rooms .selection-required .error, #FormTourOfItaly #rooms .selection-required .payment-page .error, .payment-page #FormBookNow #fieldset-payment_mode + .errors, #FormBookNow .payment-page #fieldset-payment_mode + .errors { border-top-color: #f8d7da; border-right-color: #f8d7da; border-bottom-color: #f8d7da; background: #feecee; color: #a40e1c; }

.swal2-container.jblswal-discount .jblswal-btn--confirm { background: #50CC55 !important; color: #fff !important; }

.swal2-container.jblswal-discount .jblswal-btn--confirm:hover { background: #48b84d !important; }

.swal2-container.jblswal-discount .jblswal-btn--confirm:focus { box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(60, 153, 64, 0.4); }

.swal2-container.jblswal-discount .jblswal-btn--cancel { border: 0; background: #fff !important; color: #EE5E6B !important; }

.swal2-container.jblswal-discount .jblswal-btn--cancel:hover { background: #f2f2f2 !important; }

.swal2-container.jblswal-discount .jblswal-btn--cancel:focus { box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(179, 71, 80, 0.4); }

#Checkout .checkout--main, #FormBookNow { /** The CSS shown here will not be introduced in the Quickstart guide, but shows how you can use CSS to style your Element's container. */ }

#Checkout .checkout--main .stripe-element, #FormBookNow .stripe-element { position: relative; }

#Checkout .checkout--main .stripe-element .StripeElement.empty ~ label, #FormBookNow .stripe-element .StripeElement.empty ~ label { position: absolute; background-color: #F9F9FB; border-color: #e0e0e2; color: #a9abb3; z-index: 51; top: 50%; left: 0; width: 75%; font-weight: normal; pointer-events: none; transform: translate(0.75rem, -50%); transform-origin: 0 0; transition: 0.15s; transition-timing-function: ease-in-out; transition-property: all; }

#Checkout .checkout--main .stripe-element .StripeElement.focused ~ label, #FormBookNow .stripe-element .StripeElement.focused ~ label, #Checkout .checkout--main .stripe-element .StripeElement:not(.empty) ~ label, #FormBookNow .stripe-element .StripeElement:not(.empty) ~ label { position: absolute; background-color: #fff; color: #a9abb3; z-index: 51; width: auto; font-weight: normal; pointer-events: none; transform-origin: 0 0; transition: 0.1s; transition-timing-function: ease-in; transition-property: all; line-height: 1.6; font-size: 11.5px; font-weight: bold; color: #495057; top: 0%; left: 0%; transform: translate(0.75rem, -50%); padding: 0 1ch; margin-left: -1ch; }

#Checkout .checkout--main #card-errors, #FormBookNow #card-errors { padding: 4px 0; color: #FF3943; background: #ffefef; padding: 0.5rem; margin: 0.5rem 0; color: #FF3943; width: 100%; display: block; order: -1; text-align: center; scroll-margin-top: 160px; }

@media (min-width: 992px) { #Checkout .checkout--main #card-errors, #FormBookNow #card-errors { scroll-margin-top: 200px; width: calc(100% - 1.5618rem); } }

#Checkout .checkout--main #card-errors:empty, #FormBookNow #card-errors:empty { display: none; }

#Checkout .checkout--main #stripe-token-handler, #FormBookNow #stripe-token-handler { position: absolute; top: 0; left: 25%; right: 25%; padding: 20px 30px; border-radius: 0 0 4px 4px; box-sizing: border-box; box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1); transition: all 500ms ease-in-out; transform: translateY(0); opacity: 1; background-color: #fff; }

#Checkout .checkout--main #stripe-token-handler.is-hidden, #FormBookNow #stripe-token-handler.is-hidden { opacity: 0; transform: translateY(-80px); }

#Checkout .checkout--main .StripeElement:not(#stripe-payment-element), #FormBookNow .StripeElement:not(#stripe-payment-element) { height: 48px; padding: 0.7rem 0.75rem; border-radius: 0; color: #495057; border: 1px solid #ced4da; background: #F9F9FB; }

#Checkout .checkout--main .StripeElement:not(#stripe-payment-element):not(.empty), #FormBookNow .StripeElement:not(#stripe-payment-element):not(.empty), #Checkout .checkout--main .StripeElement:not(#stripe-payment-element).focused, #FormBookNow .StripeElement:not(#stripe-payment-element).focused { border: 1px solid #535766; color: #495057; background: #fff !important; }

#Checkout .checkout--main .StripeElement--focus, #FormBookNow .StripeElement--focus { box-shadow: 0 1px 3px 0 #cfd7df; }

#Checkout .checkout--main .StripeElement--invalid, #FormBookNow .StripeElement--invalid { border-color: #FF3943; }

#Checkout .checkout--main .StripeElement--webkit-autofill, #FormBookNow .StripeElement--webkit-autofill { background-color: #FF3943 !important; }

body:not(.stripe-form-ready) #Checkout .checkout--main #stripe-payment-element, #Checkout body:not(.stripe-form-ready) .checkout--main #stripe-payment-element, body:not(.stripe-form-ready) #FormBookNow #stripe-payment-element { align-items: center; }

#Checkout .checkout--main .stripe-form.submitting, #FormBookNow .stripe-form.submitting { pointer-events: none; cursor: progress; }

#Checkout .form-group.textarea .form-control.condition-text, #FormBookNow .form-group.textarea .form-control.condition-text { font-family: courier, monospace; font-size: 12px; line-height: 1.3; color: #111; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05); background: #efefef !important; }

#Checkout .mode-of-payments, #FormBookNow .mode-of-payments { width: 100%; display: flex; flex-direction: column; margin-bottom: 1rem; }

@media (min-width: 992px) { #Checkout .mode-of-payments, #FormBookNow .mode-of-payments { flex-direction: row; flex-wrap: wrap; justify-content: space-between; } }

@media (min-width: 992px) { #Checkout .mode-of-payments .payments-mode, #FormBookNow .mode-of-payments .payments-mode { max-width: calc(50% - 2rem); } }

#Checkout .mode-of-payments .payments-mode:before, #FormBookNow .mode-of-payments .payments-mode:before { content: ""; flex: 0 0 auto; display: block; width: 3rem; height: 3rem; background-repeat: no-repeat; background-size: cover; }

#Checkout .mode-of-payments .payments-mode.disabled, #FormBookNow .mode-of-payments .payments-mode.disabled { opacity: 0.5; pointer-events: none; }

#Checkout .mode-of-payments .payments-mode.immediate:before, #FormBookNow .mode-of-payments .payments-mode.immediate:before { background-image: url(/assets/images/contents/payments/immediate.svg); }

#Checkout .mode-of-payments .payments-mode.cash:before, #FormBookNow .mode-of-payments .payments-mode.cash:before { background-image: url(/assets/images/contents/payments/cash.svg); }

#Checkout .mode-of-payments .payments-mode.bankwire:before, #FormBookNow .mode-of-payments .payments-mode.bankwire:before { background-image: url(/assets/images/contents/payments/bankwire.svg); }

#Checkout .mode-of-payments .payments-mode.delayed_withdrawal:before, #FormBookNow .mode-of-payments .payments-mode.delayed_withdrawal:before { background-image: url(/assets/images/contents/payments/withdrawal_48h_before.svg); }

#Checkout .mode-of-payments .payments-mode.advance_40:before, #FormBookNow .mode-of-payments .payments-mode.advance_40:before { background-image: url(/assets/images/contents/payments/advance_40.svg); }

#Checkout .mode-of-payments .payments-mode--button, #FormBookNow .mode-of-payments .payments-mode--button { cursor: pointer; }

#Checkout .mode-of-payments .payments-mode--title, #FormBookNow .mode-of-payments .payments-mode--title { display: flex; line-height: 1.2; margin: 0.5rem 0 0.25rem 0; }

#Checkout .mode-of-payments .payments-mode--title input, #FormBookNow .mode-of-payments .payments-mode--title input { margin-right: 1ch; }

#Checkout .mode-of-payments .payments-mode--description, #FormBookNow .mode-of-payments .payments-mode--description { display: block; font-size: 0.85em; line-height: 1.4; opacity: 0.9; margin-left: calc(0.8ch + 1.25rem); }

#Checkout .checkout--side-cart-list { margin: 0 -1.5618rem; padding: 0; }

#Checkout .checkout--side-cart-list .cart-item { display: flex; align-items: flex-start; justify-content: space-between; font-size: 0.975rem; padding: 1rem; background: #F9F9F9; border-top: 1px solid #E8E8E8; border-bottom: 1px solid #E8E8E8; }

#Checkout .checkout--side-cart-list .cart-item-wrapper { flex: 0 0 100%; display: flex; padding: 0; margin: 0; border-radius: 0.75rem; justify-content: space-between; }

#Checkout .checkout--side-cart-list .cart-item-body { flex: 0 1 100%; }

#Checkout .checkout--side-cart-list .cart-item-title { text-transform: none; margin: 0; font-size: 1em; line-height: 1.2; color: #404455; }

#Checkout .checkout--side-cart-list .cart-item-type { color: #a9a9a9; text-transform: uppercase; font-weight: 400; font-size: 0.9em; }

#Checkout .checkout--side-cart-list .cart-item-price { color: #d5a574; }

#Checkout .checkout--side-cart-list .cart-item-remove { margin-left: 0.5rem; flex: 0 0 auto; }

#Checkout .checkout--side-cart-list .cart-item + .cart-item { margin-top: 1rem; }

#Checkout .checkout--side-cart-total { text-align: center; padding: 1rem; font-size: 1.4rem; line-height: 1.2; }

#Checkout .checkout--side-cart-total .final-price { color: #d5a574; font-weight: bold; text-decoration: none; font-size: 1.6rem; }

#Checkout .checkout--side-cart-total .old-price { font-weight: normal; font-size: 1.2rem; color: #ea7c7c; }

#Checkout .checkout--side--discount-input { text-align: center; color: #667; font-size: 0.9rem; cursor: pointer; }

#Checkout .checkout--side--discount-input--wrapper { display: flex; justify-content: center; }

#Checkout .checkout--side--discount-input .discount-amount { color: #d5a574; }

#Checkout .checkout--side--discount-input .discount-amount:before { content: "("; margin-right: 1px; }

#Checkout .checkout--side--discount-input .discount-amount:after { content: ")"; margin-left: 1px; }

#Checkout .checkout--payment-logos { margin-top: -0.5rem; }

#Checkout .checkout--payment-logos .credit-cards { display: flex; justify-content: center; }

#Checkout .checkout--payment-logos .credit-cards img { height: 26px; box-shadow: 0 0 1px rgba(0, 0, 0, 0.25); border-radius: 2px; }

#Checkout .checkout--payment-logos .credit-cards img + img { margin-left: 5px; }

#Checkout .checkout--payment-logos .secure-payments { margin-top: 0.5rem; text-align: center; }

#Checkout .checkout--payment-logos .secure-payments img { max-width: 160px; }

#Checkout .form-group.checkbox input ~ label { margin-left: 1ch; display: block; }

#Checkout .form-group.checkbox label a { text-decoration: underline; margin-left: 0; }

#Checkout .form-group.checkbox label a:hover { color: #d5a574; }

.body-faq .section-default { background: #404455; color: #fff; padding-top: calc(3rem + 50px); padding-bottom: 1.5rem; margin: 0; }

.body-faq .section-header { text-align: center; color: #fff; }

.faqs--title { margin: 0 0 0.39045rem 0; font-size: 1rem; font-weight: normal; font-family: inherit; font-weight: 500; color: currentColor; }

.faqs--content { font-size: 0.9rem; }

.faqs--content p { font-size: 1em; margin-bottom: 0.25rem; }

.faqs--content p:last-child { margin-bottom: 0; }

.faqs--item { margin-bottom: 1.5rem; padding-bottom: 1.5rem; border-bottom: 1px solid #D6D6D6; }

.faqs--item:last-child { border-bottom: 0; margin-bottom: 3rem; }

.faqs--categories { margin-bottom: 3rem; background: #404455; color: #fff; }

.faqs--categories--list { list-style: none; margin: 0; padding: 0; display: flex; font-size: 1.5rem; }

.faqs--categories--list-item { flex: 1 0 auto; padding-right: 0.5rem; display: inline-flex; }

.faqs--categories--list-item a { color: #fff; position: relative; flex-grow: 1; display: inline-flex; justify-content: center; align-items: center; text-align: center; margin: 0; background: #d5a574; color: #fff; border-radius: 12px 12px 0 0; line-height: 1; padding: 1rem 0.75rem 0.95rem 0.75rem; transition: 0.25s; transition-property: all; transition-property: color, background; }

.faqs--categories--list-item a:hover, .faqs--categories--list-item a.active { background: #fff !important; color: #d5a574 !important; box-shadow: none; border-bottom: none; }

.fragment-faq { display: flex; align-items: center; }

.fragment-faq--icon { max-width: 100px; max-height: 100px; object-fit: cover; margin-right: 3rem; }

.fragment-faq--wrapper { font-size: 1.1rem; }

.fragment-faq--wrapper .h4 { color: #d5a574; margin: 0.5rem 0 0.25rem 0; }

.fragment-faq--wrapper p { font-size: 1em; line-height: 1.2; }

@media (min-width: 992px) { body.body-transfers .fragment.transfer-faq { border-top: 1px solid #D6D6D6; } }

#modal-faq .faqs--item:last-child { margin-bottom: 0; padding-bottom: 0; }

#FormBookNow .form__display-group--2col .form-group.textarea { flex-basis: 100%; max-width: calc(100% - 1.5618rem); }

#FormBookNow .form-group.button-send { margin-top: 2rem; text-align: center; }

#FormBookNow div.form-control:empty { height: 100%; }

#FormBookNow #fieldset-legals { padding-top: 1rem; }

#FormBookNow #fieldset-payment_mode + .errors { list-style: none; padding: 1rem; }

#FormBookNow #fieldset-payment_mode + .errors:before { content: initial; display: none; }

#FormBookNow .remove { font-size: 0.8rem; display: block; float: right; line-height: 1; padding: 0.3rem 0.5rem; cursor: pointer; background: #e6333c; color: #fff; text-transform: uppercase; letter-spacing: 1px; margin-top: 5px; transition: background 0.15s ease-in-out; }

#FormBookNow .remove:hover { background: #FF3943; }

.body-meet-the-team .section-header, .body-meet-the-team .section-subheader { text-align: center; }

.team-members--item { position: relative; text-align: center; cursor: pointer; margin-bottom: 30px; background: #404455; }

.team-members--item .embed-responsive { transition: filter 0.35s; filter: sepia(0%) grayscale(0%); }

.team-members--details { backface-visibility: hidden; background: rgba(64, 68, 85, 0.75); padding: 1rem; position: absolute; top: 0; left: 0; width: 100%; height: 100%; color: #fff; text-shadow: none; opacity: 0; transition: opacity 0.35s, transform 0.35s; display: flex; flex-direction: column; justify-content: center; align-items: center; text-shadow: 0 0 15px rgba(0, 0, 0, 0.5), 1px 1px 0px rgba(0, 0, 0, 0.25); }

.team-members--details--name { font-size: 1.5rem; font-weight: normal; margin: 0; transition: opacity 0.35s, transform 0.35s; transform: translate3d(0, -1.5rem, 0); opacity: 0; }

.team-members--details--position { transition: opacity 0.35s, transform 0.35s; transform: translate3d(0, 1.5rem, 0); opacity: 0; }

.team-members--details--contacts { transition: opacity 0.85s; opacity: 0; }

.team-members--details ul { list-style: none; margin: 0; padding: 0; }

.team-members--details a { color: inherit; }

.team-members--details:after, .team-members--details:before { content: ""; pointer-events: none; position: absolute; top: 1rem; right: 1rem; bottom: 1rem; left: 1rem; transition: opacity 0.4s, transform 0.4s; opacity: 0; }

.team-members--details:before { border-top: 2px solid #d5a574; border-bottom: 2px solid #d5a574; transform: scale(0, 1); }

.team-members--details:after { border-right: 2px solid #d5a574; border-left: 2px solid #d5a574; transform: scale(1, 0); }

.team-members:hover .team-members--item .embed-responsive { filter: sepia(100%) grayscale(100%); }

.team-members:hover .team-members--details { opacity: 1; }

.team-members:hover .team-members--details--name, .team-members:hover .team-members--details--position { transform: translate3d(0, 0rem, 0); opacity: 1; }

.team-members:hover .team-members--details--contacts { opacity: 1; }

.team-members:hover .team-members--details:after, .team-members:hover .team-members--details:before { opacity: 1; transform: scale(1, 1); }

#PRIVACYPOLICY { font-size: 14px; }

#PRIVACYPOLICY p, #PRIVACYPOLICY ul, #PRIVACYPOLICY ol { font-size: 1em; }

#PRIVACYPOLICY p + ul { margin-top: calc(0.7809rem / -2); }

#PRIVACYPOLICY h2, #PRIVACYPOLICY h3, #PRIVACYPOLICY h4, #PRIVACYPOLICY .h2, #PRIVACYPOLICY .h3, #PRIVACYPOLICY .h4 { font-size: 1.35em; font-weight: normal; margin-bottom: calc(0.7809rem / 2); line-height: inherit; }

/*# sourceMappingURL=default.css.map */